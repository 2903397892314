import { Component, ViewChild } from "@angular/core";
import {
  ITreeOptions,
  TreeComponent,
  TreeNode,
} from "@circlon/angular-tree-component";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  styleUrls: ["../grid-table.component.scss"],
  template: `
    <span>
      <div
        ngbDropdown
        [placement]="placement"
        #dropdown="ngbDropdown"
        container="body"
      >
        <button
          type="button"
          ngbDropdownToggle
          (click)="onClick(dropdown)"
          class="btn align-items-center justify-content-center d-flex"
          style="width: 100%; height: 35px; background-color: transparent; border: 1px solid #dedede; border-radius: 5px"
        >
          {{ (selectedNode?.data?.title|translate) ?? defaultTitle|translate }}
          <i class="bx bxs-down-arrow custom-arrow"> </i>
        </button>
        <div ngbDropdownMenu class="px-2">
          <input
            [id]="params.data.id"
            #filter
            (keyup)="tree.treeModel.filterNodes(filter.value)"
            placeholder="{{'SERACH_BY_TITLE'|translate}}"
          />
          <tree-root
            #tree
            [focused]="true"
            [nodes]="params.value"
            [options]="options"
            (activate)="onActivate($event.node)"
            (deactivate)="onDeactivate()"
          ></tree-root>
        </div>
      </div>
    </span>
  `,
})
export class DropdownTreeRenderer implements ICellRendererAngularComp {
  @ViewChild("tree", { static: true }) tree: TreeComponent;
  public params!: ICellRendererParams;
  button: any;
  placement = "bottom-right";
  defaultTitle = "SELECT_ACCOUNT";
  dropdownItems: string[] = [];
  selectedNode: TreeNode;
  dropdown: NgbDropdown;
  options: ITreeOptions = {
    // useCheckbox: true,
    useTriState: false,
    rtl: true, // set RTL
    displayField: "title",
  };

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.data.selectedNode) {
      this.selectedNode = this.params.data.selectedNode;
    }
    if (this.params.data.selectedNodeId) {
      setTimeout(() => {
        this.selectedNode = this.tree.treeModel.getNodeById(
          this.params.data.selectedNodeId
        );
        this.params.data.selectedNode = this.selectedNode;
      }, 0);
    }
  }

  public invock(action: string): void {
    this.params.context.componentParent.actionsGrid({
      row: this.params.data,
      action: action,
      node: this.selectedNode,
    });
  }

  refresh(): boolean {
    return false;
  }

  onActivate(node: TreeNode) {
    if (node.isLeaf) {
      this.selectedNode = node;
      this.params.data.selectedNode = this.selectedNode;
      this.dropdown.close();
    } else {
      this.selectedNode = null;
      this.params.data.selectedNode = null;
      node.toggleActivated();
      node.blur();
    }
    this.invock("nodeChange");
  }

  onDeactivate() {
    this.selectedNode = null;
    this.params.data.selectedNode = null;
    this.invock("nodeChange");
  }

  onClick(dropdown) {
    this.dropdown = dropdown;
  }
}
