import { Component, OnInit, AfterViewInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { MenuItem } from './menu.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

  menu: any;
  isApp = environment.isApp;
  accountingSiteUrl = environment.accountingSiteUrl;
  @Input() menuItems = [];
  @Input() isCondensed = false;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @ViewChild('sideMenu') sideMenu: ElementRef;
  @ViewChild('componentRef') scrollRef: any;

  constructor(private router: Router) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    }).then(() => {});
  }

  ngOnInit(): void {
    this._scrollElement();
  }

  ngAfterViewInit(): void {
    this.menu = new MetisMenu(this.sideMenu.nativeElement, { toggle: false });
    this._activateMenuDropdown();
  }

  ngOnChanges(): void {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => this.menu = new MetisMenu(this.sideMenu.nativeElement, { toggle: false }));
    }
    else if (this.menu) {
      this.menu.dispose();
    }
  }

  toggleMobileMenu(event: any): void {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  _scrollElement(): void {
    setTimeout(() => {
      if (document.getElementsByClassName('mm-active').length > 0) {
        const currentPosition = document.getElementsByClassName('mm-active')[0]['offsetTop'];
        if (currentPosition > 500) {
          if (this.scrollRef.SimpleBar !== null) {
            this.scrollRef.SimpleBar.getScrollElement().scrollTop = currentPosition + 300;
          }
        }
      }
    }, 300);
  }

  _activateMenuDropdown(): void {
    this._removeAllClass('mm-active');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    let itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex !== -1) {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  }

  _removeAllClass(className: string): void {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  hasItems(item: MenuItem): boolean {
    return item?.subItems !== undefined ? item?.subItems.length > 0 : false;
  }

}
