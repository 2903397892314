<header id="page-topbar">
  <div class="navbar-header px-0">
    <div class="align-items-center d-flex">
      <div class="navbar-brand-box px-2 px-sm-4">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm"> <img src="assets/images/logo.svg" width="" height="22" alt="" class="img-fluid" /> </span>
          <span class="logo-lg"> <img src="assets/images/logo-dark.png" width="" height="18" alt="" class="img-fluid" /> </span>
        </a>
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm"> <img src="assets/images/logo-alyatim-2.svg" width="70" height="95" alt="" class="img-fluid" /> </span>
          <span class="logo-lg"> <img src="assets/images/logo-alyatim.svg" width="145" height="45" alt="" class="img-fluid" /> </span>
        </a>
      </div>
      <button (click)="toggleMobileMenu($event)" type="button" id="vertical-menu-btn" class="btn btn-sm header-item font-size-16 px-3">
        <i class="fa fa-fw fa-bars"> </i>
      </button>
    </div>
    <div class="align-items-center d-flex">
      <app-dropdown (selectItem)="tools.setSelectedBranch($event); reload()" [items]="branches" [value]="selectedBranch?.id" bindLabel0="code" bindLabel="title" placeholder="BRANCH"
                    [isClearable]="false" class="branch d-none d-sm-block" [required]="true"> </app-dropdown> <!-- [disabled]="tools.branchIsDisabled()" -->
      <div ngbDropdown class="dropdown d-inline-block">
        <button ngbDropdownToggle type="button" class="btn header-item">
          <span class="current-lang mx-1"> {{ selectedLang.text }} </span>
          <img src="{{ selectedLang.flag }}" width="20" height="16" alt="" />
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-start">
          <a *ngFor="let item of langList" (click)="setLanguage(item)" href="javascript: void(0)" [class.active]="selectedLang.id === item.id" class="dropdown-item notify-item">
            <img src="{{ item.flag }}" width="16" height="12" alt="" class="me-1" />
            <span class="align-middle"> {{ item.text }} </span>
          </a>
        </div>
      </div>
      <div *ngIf="user" ngbDropdown class="dropdown d-inline-block">
        <button ngbDropdownToggle type="button" class="btn header-item">
          <i class="mdi mdi-chevron-down position-relative d-none d-xl-inline-block" style="top: 0.1rem"> </i>
          <span class="user-logged d-none d-xl-inline-block mx-1"> {{ user.fullName ?? 'Not set' }} </span>
          <img src="{{ user.imageUrl ? env + '/blob/' + user.imageUrl : 'assets/images/user.png' }}" width="256" height="256" alt="" class="header-profile-user rounded-circle" />
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-start">
          <div class="d-xl-none px-3"> {{ user.fullName }} </div>
          <div class="ltr px-3"> {{ user.phone }} </div>
          <a (click)="tools.logout()" href="javascript: void(0)" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle text-danger me-1"> </i> {{ 'LOGOUT' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-dropdown (selectItem)="tools.setSelectedBranch($event); reload()" [items]="branches" [value]="selectedBranch?.id" bindLabel="title" placeholder="BRANCH"
                [isClearable]="false" class="d-block d-sm-none p-2" [required]="true"> </app-dropdown> <!-- [disabled]="tools.branchIsDisabled()" -->
</header>
