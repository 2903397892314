<main *ngIf="!loading && salaryDetails && salaryDetails.length; else loadingTemplate" class="gap-3 flex-column d-flex">
  <table *ngFor="let data of salaryDetails" class="page-break">
    <thead>
      <tr>
        <th>
          <header id="main-header" class="gap-3 d-flex">
            <div class="gap-3 flex-column flex-fill d-flex">
              <h2> موسسه الیتیم الخیریه <span> ({{ translatePipe.transform('PAY_SLIP') }}) </span> </h2>
              <div class="gap-3 d-flex">
                <div class="row align-items-center flex-fill gy-2">
                  <div class="col-6"> <label> {{ translatePipe.transform('PERSONNEL_CODE') }} : </label> <span> {{ data.personnelCode }} </span> </div>
                  <div class="col-6"> <label> {{ translatePipe.transform('PERSONNEL') }} : </label> <span> {{ data.employee }} </span> </div>
                  <div class="col-6"> <label> {{ translatePipe.transform('ORGANIZATIONAL_UNIT') }} : </label> <span> {{ data.department }} </span> </div>
                  <div class="col-6"> <label> {{ translatePipe.transform('ORGANIZATIONAL_POSITION') }} : </label> <span> {{ data.organizationalPosition }} </span> </div>
                  <div class="col-6"> <label> {{ translatePipe.transform('JOB_GROUP') }} : </label> <span> {{ data.jobGroup }} </span> </div>
                  <div class="col-6"> <label> {{ translatePipe.transform('JOB') }} : </label> <span> {{ data.jobTitle }} </span> </div>
                </div>
              </div>
            </div>
            <img src="assets/images/logo-alyatim-2.svg" height="120" alt="" />
          </header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="py-2">
            <div class="row">
              <div class="col-4"> <label class="fw-bold"> {{ translatePipe.transform('BRANCH') }} : </label> <span> {{ header.branch }} </span> </div>
              <div class="col-4"> <label class="fw-bold"> {{ translatePipe.transform('FISCAL_YEAR') }} : </label> <span> {{ header.fiscalYear }} </span> </div>
              <div class="col-4"> <label class="fw-bold"> {{ translatePipe.transform('SALARY_PERIODS') }} : </label> <span> {{ header.salaryPeriod }} </span> </div>
            </div>
            <div class="row gx-0 mt-3">
              <div class="col-4 border" style="padding-bottom: 4rem">
                <h3 class="justify-content-center border-bottom d-flex py-3"> {{ translatePipe.transform('FUNCTIONAL_FACTORS') }} </h3>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('DAILY_OPERATION') }} : </label>
                  <span> {{ data.dailyFunction.totalHours }} </span>
                </div>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('ABSENCE') }} : </label>
                  <span> {{ data.absence.totalHours }} </span>
                </div>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('UNPAID_LEAVE') }} : </label>
                  <span> {{ data.vacationWithoutSalary.totalHours }} </span>
                </div>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('MISSION') }} : </label>
                  <span> {{ data.mission.totalHours }} </span>
                </div>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('OVERTIME') }} : </label>
                  <span> {{ data.overtime.totalHours }} </span>
                </div>
                <div class="justify-content-between d-flex px-2 mt-2">
                  <label class="fw-bold"> {{ translatePipe.transform('PAID_LEAVE') }} : </label>
                  <span> {{ data.vacationWithSalary.totalHours }} </span>
                </div>
                <div class="row gx-0 mt-2" style="height: 14%">
                  <div class="justify-content-between border d-flex px-2 pt-2" style="border-top-width: 2px !important; border-left-width: 0 !important">
                    <label class="fw-bold"> {{ translatePipe.transform('WORK_AMOUNT_TOTAL') }} : </label>
                    <span> {{ data.operatingAmount | number }} </span>
                  </div>
                </div>
                <h3 class="justify-content-center border-top border-bottom d-flex py-3"> {{ translatePipe.transform('WARRANT_FACTORS') }} </h3>
                <ng-container *ngIf="data.salaryFactories && data.salaryFactories.length; else other">
                  <div *ngFor="let item of data.salaryFactories" class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ item.title }} : </label>
                    <span> {{ item.amount.toLocaleString() }} </span>
                  </div>
                </ng-container>
                <ng-template #other>
                  <div class="align-items-center justify-content-center d-flex py-3"> --- </div>
                </ng-template>
              </div>
              <div class="row col-8 gx-0">
                <div class="col-6 border" style="height: 54%">
                  <h3 class="justify-content-center border-bottom d-flex py-3"> {{ translatePipe.transform('BENEFITS') }} </h3>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('REWARD') }} : </label>
                    <span> {{ data.totalReward.toLocaleString() }} </span>
                  </div>
                </div>
                <div class="col-6 border" style="height: 54%">
                  <h3 class="justify-content-center border-bottom d-flex py-3"> {{ translatePipe.transform('DEDUCTIONS') }} </h3>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('PENALTY') }} : </label>
                    <span> {{ data.totalFines.toLocaleString() }} </span>
                  </div>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('TOTAL_LOANS') }} : </label>
                    <span> {{ data.loanDebt?.totalAmount.toLocaleString() }} </span>
                  </div>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('TOTAL_IMPREST') }} : </label>
                    <span> {{ data.imprestDebt?.totalAmount.toLocaleString() }} </span>
                  </div>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('INSURANCE_AMOUNT') }} : </label>
                    <span> {{ data.employeeInsuranceShare.toLocaleString() }} </span>
                  </div>
                  <div class="justify-content-between d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('TAX') }} : </label>
                    <span> {{ data.taxAmount.toLocaleString() }} </span>
                  </div>
                </div>
                <div class="row gx-0" style="height: 12%">
                  <div class="col-6 justify-content-between border d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('TOTAL_BENEFITS') }} : </label>
                    <span> {{ data.totalBenefits.toLocaleString() }} </span>
                  </div>
                  <div class="col-6 justify-content-between border d-flex pt-2 px-2">
                    <label class="fw-bold"> {{ translatePipe.transform('TOTAL_DEDUCTIONS') }} : </label>
                    <span> {{ data.totalDeduction.toLocaleString() }} </span>
                  </div>
                </div>
                <div class="col-12 justify-content-between border d-flex pt-2 px-2" style="height: 34%">
                  <label class="fw-bold"> {{ translatePipe.transform('PAID') }} : </label>
                  <span> {{ data.salary.toLocaleString() }} </span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<ng-template #loadingTemplate>
  <app-loader class="d-block my-4"> </app-loader>
</ng-template>
