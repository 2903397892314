import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { environment } from "../environments/environment";

import {
  NgbAccordionModule,
  NgbActiveModal,
  NgbModule,
  NgbNavModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { LayoutsModule } from "./layouts/layouts.module";

import { DatePipe, DecimalPipe, KeyValuePipe } from "@angular/common";
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { CellRendererPipe } from "./core/pipes/cell-renderer.pipe";
import { FullNamePipe } from "./core/pipes/full-name.pipe";
import { PipesModule } from "./core/pipes/pipes.module";
import { PricePipe } from "./core/pipes/price.pipe";
import { TranslatePipe } from "./core/pipes/translate.pipe";
import { SumPipe } from "./core/pipes/sum.pipe";
import { GroupByPipe } from "./core/pipes/group-by.pipe";
import { StatusPipe } from "./core/pipes/status.pipe";
import { PrettyJsonPipe } from "./core/pipes/pretty-json.pipe";
import { PrintPageComponent } from "./print-page/print-page.component";
import { FamilyMemberPrintComponent } from "./pages/family/family-member-print/family-member-print.component";
import { FamilyPrintComponent } from "./pages/family/family-print/family-print.component";
import { PersonnelPrintComponent } from "./pages/hr/personnel/personnel-print/personnel-print.component";
import { HrAllSalaryPrintComponent } from "./pages/hr/salary/all-salary-print/all-salary-print.component";
import { LoaderModule } from "./shared/ui/loader/loader.module";
// import { UIModule } from "./shared/ui/ui.module";

if (environment.defaultAuth === "firebase") {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, PrintPageComponent, FamilyMemberPrintComponent, FamilyPrintComponent, PersonnelPrintComponent, HrAllSalaryPrintComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    // UIModule,
    PipesModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    LoaderModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    PricePipe,
    DecimalPipe,
    DatePipe,
    TranslatePipe,
    CellRendererPipe,
    KeyValuePipe,
    NgbActiveModal,
    FullNamePipe,
    SumPipe,
    GroupByPipe,
    StatusPipe,
    PrettyJsonPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
})
export class AppModule {}
