import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <span *ngIf="params && params.data && params.data.dropdownItems && params.data.dropdownItems.length">
      <ng-select
        style="min-width:120px;display:flex"
        (change)="invock()"
        [(ngModel)]="params.data.selectedItem"
        [searchable]="searchable"
        [clearable]="clearable"
        appendTo="body"
      >
        <ng-option
          *ngFor="let item of params.data.dropdownItems"
          [value]="item[bindValue]"
        >
          {{ item[bindLabel]|translate }}
        </ng-option>
      </ng-select>
    </span>
  `,
})
export class DropdownRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  button: any;
  placement = "bottom-right";
  dropdownItems: string[] = [];
  selectedItem: any;
  bindValue = "id";
  bindLabel = "name";
  searchable = false;
  clearable = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.data.searchable) {
      this.searchable = true;
    }
    if (this.params.data.clearable) {
      this.clearable = true;
    }
    this.selectedItem = this.params.data.selectedItem ?? this.selectedItem;
    this.bindLabel = this.params.data?.bindLabel ?? this.bindLabel;
    // this.bindValue = params.data?.bindValue;
  }

  public invock(): void {
    this.params.context.componentParent.actionsGrid({
      field: this.params.colDef.field,
      itemId: this.params.data.selectedItem,
      action: "select",
      row: this.params.data,
    });
  }

  refresh(): boolean {
    return false;
  }
}
