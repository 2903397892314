import { AfterViewInit, Component, OnInit } from "@angular/core";
import { of } from "rxjs";
import { catchError, map, concatMap } from "rxjs/operators";

import { ApiService } from "../core/services/api.service";
import { EventService } from "../core/services/event.service";
import { TranslateService } from "../core/services/translate.service";

import { BranchModel } from "../core/models/donation-financial-model";
import { ToolsService } from "../core/services/tools.service";
import {
  LAYOUT_HORIZONTAL,
  LAYOUT_VERTICAL,
  LAYOUT_WIDTH,
  TOPBAR,
} from "./layouts.model";
import { PermissionModel } from "../core/models/auth.models";
import { UserModel } from "../core/models/public-model";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  loading = false;
  userLoading = false;
  formatted = {};

  constructor(
    private eventService: EventService,
    private api: ApiService,
    private tools: ToolsService,
    private langs: TranslateService
  ) {}

  ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;

    // this.getTranslations();

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe("changeLayout", (layout) => {
      this.layoutType = layout;
    });

    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe("changeWidth", (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    this.getBranches().pipe(concatMap(() => this.getUserById().pipe(concatMap(() => this.getPermissions())))).subscribe();
  }

  ngAfterViewInit() {}

  LayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.classList.add("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "scrollable":
        document.body.removeAttribute("data-layout-size");
        document.body.setAttribute("data-layout-scrollable", "true");
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove(
          "right-bar-enabled",
          "vertical-collpsed"
        );
      default:
        document.body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  // getTranslations() {
  //   let langId = +localStorage.getItem("langId");
  //   langId = langId ? langId : 2;
  //   this.loading = true;
  //   this.api
  //     .get<LanguageModel[]>(
  //       { Language: langId },
  //       "/Donation/Translation/GetAll"
  //     )
  //     .pipe(
  //       map((res) => {
  //         this.loading = false;
  //         return res.data;
  //       }),
  //       mergeMap((res) => res),
  //       mergeMap((res) => {
  //         this.formatted[res.key] = res.value;
  //         return of(res);
  //       }),
  //       toArray(),
  //       map(() => {
  //         this.setTranslations();
  //       }),
  //       catchError((err) => {
  //         this.loading = false;
  //         this.langs.keywords = {};
  //         return of(err);
  //       })
  //     )
  //     .subscribe();
  // }

  // setTranslations() {
  //   if (
  //     JSON.stringify(this.langs.keywords) === JSON.stringify(this.formatted)
  //   ) {
  //     return;
  //   }
  //   this.langs.keywords = this.formatted;
  //   localStorage.setItem("translations", JSON.stringify(this.formatted));
  // }

  getBranches() {
    this.loading = true;
    return this.api.post<BranchModel[]>({}, '/v3/Branch/FindBranch', 'accounting').pipe(map((res) => {
      res.data = res.data.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
      return res.data;
    }), concatMap((data) => {
      if (this.tools.getUserRoles().indexOf('Admin') !== -1) {
        this.tools.setBranches(data);
        return of(data);
      }
      return this.api.post<any[]>({ userIdentifier: this.tools.getUserID() }, '/User/GetUserBranches', 'account').pipe(map((res) => {
        if (res.data && res.data.length) {
          const branches: number[] = [];
          res.data.map((item) => branches.push(item.id));
          data = data.filter((item) => branches.includes(item.id));
          this.tools.setBranches(data);
        }
        return data;
      }));
    }), catchError(() => {
      this.tools.setBranches([]);
      this.tools.setSelectedBranch({}, 'user');
      this.loading = false;
      return of([]);
    }));
  }

  getPermissions() {
    return this.api.get<PermissionModel[]>({}, `/User/GetAllPermission?UserIdentifier=${ this.tools.getUserID() }&BranchId=${ this.tools.getSelectedBranch()?.id }`, 'account').pipe(map((res) => {
      this.tools.setPermissions(res.data);
    }), catchError(() => {
      this.tools.setPermissions([]);
      return of([]);
    }));
  }

  getUserById() {
    this.userLoading = true;
    const userIdentifier = this.tools.getUserID();
    return this.api.get<UserModel>({}, '/User/GetByIdentifier/' + userIdentifier, 'account').pipe(map((res) => {
      return res.data;
    }), concatMap((data) => {
      return this.api.post({ userIdentifier }, '/HR/Employee/GetOrganizationalPosition').pipe(map((res: any) => {
        this.tools.setUser({ ...data, departmentId: res.data && res.data.length ? res.data[0].departmentId : null });
        const branches = this.tools.getBranches();
        if (res.data && res.data.length) {
          const isCentral = branches.find((item) => item.id === res.data[0].branchId).isCentral;
          const temp = branches.find((item) => item.id === (+localStorage.getItem('branchId') || res.data[0].branchId));
          temp.isCentral = isCentral;
          this.tools.setSelectedBranch(temp, 'user');
        }
        this.loading = this.userLoading = false;
      }), catchError(() => {
        const user: UserModel = {};
        this.loading = this.userLoading = false;
        return of(user);
      }));
    }), catchError(() => {
      const user: UserModel = {};
      this.userLoading = false;
      return of(user);
    }));
  }
}
