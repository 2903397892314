import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { of, forkJoin } from 'rxjs';
import { map, mergeMap, shareReplay, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { PricePipe } from 'src/app/core/pipes/price.pipe';
import { GroupByPipe } from 'src/app/core/pipes/group-by.pipe';
import { ApiService } from 'src/app/core/services/api.service';
import { FamilyService } from 'src/app/core/api/family.service';
import { PrintService } from 'src/app/core/services/print.service';
import { SETTING_IDS } from 'src/app/core/constants';
import { FamilyTypes, EconomicStatuses, FoodIntakeStatuses, QualityStatuses, QualityStatuses2, Gender, MaritalStatus, DonorTypes, AidTypes, Payers, AttachmentGroups } from 'src/app/core/models/enums';
import { PublicModel } from 'src/app/core/models/public-model';
import { CampaignModel } from 'src/app/core/models/settings-model';
import { FamilyModel, FamilyCostAmountModel } from 'src/app/core/models/donation-financial-model';
import { FamilyMemberModel, FamilyCostModel, FamilyCashNeedModel, FamilyGoodNeedModel, FamilyServiceNeedModel } from 'src/app/core/models/family-model';

interface FamilyAllDataModel {
  info?: FamilyModel;
  addressInfo?: any;
  addressData?: any[];
  memberData?: FamilyMemberModel[];
  assetData?: FamilyAsset[];
  incomeData?: FamilyIncome[];
  costData?: FamilyCostModel[];
  cashNeedData?: FamilyCashNeedModel[];
  goodNeedData?: FamilyGoodNeedModel[];
  serviceNeedData?: FamilyServiceNeedModel[];
  attachData?: any;
}
interface FamilyAsset {
  id?: number;
  familyId?: number;
  qualityStatus?: number;
  assetTypeId?: number;
  assetType?: string;
  unitId?: number;
  unitName?: string;
  quantity?: number | string;
  incomeAmount?: number;
  description?: string;
  createDate?: string;
}
interface FamilyIncome {
  id?: number;
  familyId?: number;
  supervisorName?: string;
  incomeDate?: string;
  donorType?: number;
  relativeId?: number;
  relative?: string;
  instituteId?: number;
  institute?: string;
  aidType?: number;
  incomeAmount?: number;
  description?: string;
  createDate?: string;
  totalAmounts?: any; // for print
}

@Component({
  selector: 'family-print',
  templateUrl: 'family-print.component.html',
  styleUrls: ['family-print.component.scss']
})

export class FamilyPrintComponent implements OnInit {

  selected: FamilyModel;
  family: FamilyAllDataModel;
  hasData: { LOCATION_RECORDS?: boolean, FAMILY_MEMBER?: boolean, FAMILY_ASSETS?: boolean, OTHER_INCOME?: boolean, COSTS?: boolean, NEEDS?: boolean, ATTACH?: boolean };
  printDate: string;
  loading: boolean;
  toastRef: any;

  constructor(private datePipe: DatePipe, private toastrService: ToastrService, public translatePipe: TranslatePipe, private pricePipe: PricePipe, private groupByPipe: GroupByPipe,
              private apiService: ApiService, private familyService: FamilyService, private printService: PrintService) {}

  ngOnInit(): void {
    this.selected = this.printService.mainData;
    this.hasData = { LOCATION_RECORDS: false, FAMILY_MEMBER: false, FAMILY_ASSETS: false, OTHER_INCOME: false, COSTS: false, NEEDS: false, ATTACH: false };
    this.printDate = this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    this.loading = true;
    this.toastRef = this.toastrService.info(this.translatePipe.transform('PRINTING'), null, { timeOut: 3000, positionClass: 'toast-bottom-center' });
    const id = this.selected.id;
    const familyId = this.selected.id;
    const pageSize = 500;
    const pagination = { skip: 0, take: pageSize };
    forkJoin({
      familyTag: this.filterSettings<PublicModel[]>(SETTING_IDS.familyTags),
      reasonNotIncluded: this.familyService.reasonNotIncludedFilter<PublicModel[]>({}).pipe(map((res) => res.data)),
      unit: this.filterSettings<PublicModel[]>(SETTING_IDS.units),
      incomeAmount: this.familyService.familyGetIncomeAmount({ id }).pipe(map((res) => res.data)),
      costAmount: this.familyService.familyGetCostAmount<FamilyCostAmountModel>({ id }).pipe(map((res) => res.data)),
      donationTree: this.filterSettings<CampaignModel[]>(SETTING_IDS.donationTree),
      good: this.filterSettings<PublicModel[]>(SETTING_IDS.products),
      service: this.filterSettings<PublicModel[]>(SETTING_IDS.service),
      memberData: this.familyService.familyMemberGetByFamily<FamilyMemberModel[]>({ familyId }).pipe(map((res) => res.data && res.data.length ? this.memberDataMapper({}, res.data) : [{}]))
    }).pipe(mergeMap((pre) => {
      return forkJoin({
        info: this.familyService.familyGet<FamilyModel>({ id }).pipe(map((res) => this.infoMapper(pre, res.data))),
        addressInfo: this.familyService.familyResidenceInfoGet({ familyId }).pipe(map((res) => res.data ? this.addressInfoMapper(pre, res.data) : null)),
        addressData: this.familyService.familyResidenceInfoFilter<any[]>({ familyId, pagination: { ...pagination, sortProperties: { createDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.addressDataMapper(pre, res.data) : [{}];
        })),
        memberData: this.familyService.familyMemberGetByFamily<FamilyMemberModel[]>({ familyId }).pipe(map((res) => res.data && res.data.length ? this.memberDataMapper(pre, res.data) : [{}])),
        assetData: this.familyService.familyAssetFilter<FamilyAsset[]>({ familyId, pagination: { ...pagination, sortProperties: { createDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.assetDataMapper(pre, res.data) : [{}];
        })),
        incomeData: this.familyService.familyOtherIncomeFilter<FamilyIncome[]>({ familyId, pagination: { ...pagination, sortProperties: { incomeDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.incomeDataMapper(pre, res.data) : [{}];
        })),
        costData: this.familyService.familyCostFilter<FamilyCostModel[]>({ familyId }).pipe(map((res) => res.data && res.data.length ? this.costDataMapper(pre, res.data) : [{}])),
        cashNeedData: this.familyService.familyCashAidFilter<FamilyCashNeedModel[]>({ familyId, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.cashNeedDataMapper(pre, res.data) : [{}];
        })),
        goodNeedData: this.familyService.familyObjectiveAidFilter<FamilyGoodNeedModel[]>({ familyId, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.goodNeedDataMapper(pre, res.data) : [{}];
        })),
        serviceNeedData: this.familyService.familyServiceAidFilter<FamilyServiceNeedModel[]>({ familyId, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.serviceNeedDataMapper(pre, res.data) : [{}];
        })),
        attachData: this.familyService.familyAttachmentFilter<any>({ familyId }).pipe(map((res) => res.data && res.data.length ? this.attachDataMapper(pre, res.data) : [{}]))
      }).pipe(map((data) => {
        this.family = { ...data };
        this.hasData = {
          LOCATION_RECORDS: data.addressData && data.addressData.length,
          FAMILY_MEMBER: data.memberData && data.memberData.length,
          FAMILY_ASSETS: data.assetData && data.assetData.length,
          OTHER_INCOME: data.incomeData && data.incomeData.length,
          COSTS: data.costData && data.costData.length,
          NEEDS: (data.cashNeedData && data.cashNeedData.length) || (data.goodNeedData && data.goodNeedData.length) || (data.serviceNeedData && data.serviceNeedData.length),
          ATTACH: data.attachData && data.attachData.all && data.attachData.all.length && data.attachData.info && data.attachData.info.length && data.attachData.address && data.attachData.address.length && data.attachData.asset && data.attachData.asset.length
        };
        console.log(this.family);
        this.loading = false;
        this.toastrService.clear(this.toastRef.ToastId);
        setTimeout(() => window.print(), 1000);
      }));
    })).subscribe();
  }

  filterSettings<T>(identifier: string) {
    return this.apiService.filterSettings<T>({ identifier }).pipe(map((res) => res.data), shareReplay(), catchError((err) => of(err)));
  }

  infoMapper(pre: any, data: any) {
    data.firstFinalConfirm = this.datePipe.transform(data.firstFinalConfirm, 'YYYY-MM-dd');
    data.lastFinalConfirm = this.datePipe.transform(data.lastFinalConfirm, 'YYYY-MM-dd');
    data.entitlementAmount = this.pricePipe.transform(data.entitlementAmount);
    let tagsAll = '';
    if (data.tags && data.tags.length) {
      data.tags.map((tag: number) => {
        const title = pre.familyTag.find((item: PublicModel) => item.Id === tag)?.Title;
        tagsAll += this.translatePipe.transform(title) + ', ';
      });
    }
    data.tags = tagsAll;
    data.familyType = this.translatePipe.transform(FamilyTypes[data.familyType]);
    data.economicStatus = this.translatePipe.transform(EconomicStatuses[data.economicStatus]);
    data.foodIntakeStatus = this.translatePipe.transform(FoodIntakeStatuses[data.foodIntakeStatus]);
    data.reasonNotIncluded = pre.reasonNotIncluded.find((t: PublicModel) => t.id === data.reasonNotIncludedId)?.title;
    return data;
  }

  addressInfoMapper(pre: any, data: any) {
    data.houseRentAmount = this.pricePipe.transform(data.houseRentAmount);
    data.familyRentAmount = this.pricePipe.transform(data.familyRentAmount);
    data.houseQualityStatus = this.translatePipe.transform(QualityStatuses[data.houseQualityStatus]);
    data.residenceStartDate = this.datePipe.transform(data.residenceStartDate, 'YYYY-MM-dd');
    data.residenceEndDate = this.datePipe.transform(data.residenceEndDate, 'YYYY-MM-dd');
    let familyLivesWithAll = '';
    if (data.familyLivesWith && data.familyLivesWith.length) {
      data.familyLivesWith.map((item: PublicModel) => {
        familyLivesWithAll += this.translatePipe.transform(item.title) + ', ';
      });
    }
    data.familyLivesWith = familyLivesWithAll;
    data.familyMemberFullName = data.familyMemberIdentifier ? pre.memberData.find((item: FamilyMemberModel) => item.identifier === data.familyMemberIdentifier) : '';
    data.familyMemberFullName = data.familyMemberFullName ? (data.familyMemberFullName.firstName + ' ' + data.familyMemberFullName.lastName) : '';
    data.ownerRelationType = data.familyMemberIdentifier ? pre.memberData.find((item: FamilyMemberModel) => item.identifier === data.familyMemberIdentifier)?.familyRelationType : '';
    return data;
  }

  addressDataMapper(pre: any, data: any) {
    data.map((item: any) => {
      item.houseArea = this.pricePipe.transform(item.houseArea);
      item.houseRentAmount = this.pricePipe.transform(item.houseRentAmount);
      item.familyRentAmount = this.pricePipe.transform(item.familyRentAmount);
      item.residenceStartDate = this.datePipe.transform(item.residenceStartDate, 'YYYY-MM-dd');
      item.residenceEndDate = this.datePipe.transform(item.residenceEndDate, 'YYYY-MM-dd');
    });
    return data;
  }

  memberDataMapper(pre: any, data: any) {
    data.map((item: FamilyMemberModel) => {
      item.birthDate = this.datePipe.transform(item.birthDate, 'YYYY-MM-dd');
      item.gender = this.translatePipe.transform(Gender[item.gender]);
      item.maritalStatus = this.translatePipe.transform(MaritalStatus[item.maritalStatus]);
      // item.incomeAmountUsed = this.pricePipe.transform(item.incomeAmountUsed);
    });
    return data;
  }

  assetDataMapper(pre: any, data: any) {
    data.map((item: FamilyAsset) => {
      let unitTitle = pre.unit.find((t: PublicModel) => t.Id === item.unitId)?.Title;
      item.quantity = item.quantity + (unitTitle ? ' (' + unitTitle + ')' : '');
      item.incomeAmount = this.pricePipe.transform(item.incomeAmount);
      item.qualityStatus = this.translatePipe.transform(QualityStatuses2[item.qualityStatus]);
    });
    return data;
  }

  incomeDataMapper(pre: any, data: any) {
    data.map((item: FamilyIncome) => {
      item.incomeDate = this.datePipe.transform(item.incomeDate, 'YYYY-MM-dd');
      item.donorType = this.translatePipe.transform(DonorTypes[item.donorType]);
      item.aidType = this.translatePipe.transform(AidTypes[item.aidType]);
      item.incomeAmount = this.pricePipe.transform(item.incomeAmount);
      item.totalAmounts = {};
    });
    if (pre.incomeAmount) {
      data[0].totalAmounts.assetAmount = this.pricePipe.transform(pre.incomeAmount.assetAmount);
      data[0].totalAmounts.familyMemberAmount = this.pricePipe.transform(pre.incomeAmount.familyMemberAmount);
      data[0].totalAmounts.otherIncomeAmount = this.pricePipe.transform(pre.incomeAmount.otherIncomeAmount);
      data[0].totalAmounts.totalAmount = this.pricePipe.transform(pre.incomeAmount.totalAmount);
    }
    return data;
  }

  costDataMapper(pre: any, data: any) {
    data.map((item: FamilyCostModel) => {
      item.payer = item.payer === Payers.ALL_MEMBER ? this.translatePipe.transform(Payers[item.payer]) : item.payer_FamilyMember;
      item.incomeAmount = this.pricePipe.transform(item.incomeAmount);
      item.totalAmounts = {};
    });
    if (pre.costAmount) {
      data[0].totalAmounts.familyCost = this.pricePipe.transform(pre.costAmount.familyCost);
      data[0].totalAmounts.illnessCost = this.pricePipe.transform(pre.costAmount.illnessCost);
      data[0].totalAmounts.totalCost = this.pricePipe.transform(pre.costAmount.totalCost);
    }
    return data;
  }

  cashNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyCashNeedModel) => {
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.amount = this.pricePipe.transform(item.amount);
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  goodNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyGoodNeedModel) => {
      item.good = pre.good.find((t: PublicModel) => t.Id === item.productId)?.Title;
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  serviceNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyServiceNeedModel) => {
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.service = pre.service.find((t: PublicModel) => t.Id === item.serviceId)?.Title;
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  attachDataMapper(pre: any, data: any) {
    let dataAlternative: any = [];
    const dataGroupByAttachmentGroup = this.groupByPipe.transform(data, 'attachmentGroup');
    dataGroupByAttachmentGroup.map((item, index) => {
      const temp = {};
      item.attachmentGroup = +item.key;
      item.attachmentGroupTitle = this.translatePipe.transform(AttachmentGroups[+item.key]);
      item.count = item.value.length;
      Object.keys(item).forEach((key) => {
        if (key !== 'key' && key !== 'value') {
          temp[key] = item[key];
        }
      });
      dataAlternative[index] = temp;
    });
    dataAlternative = dataAlternative.filter((item: any) => item.count);
    dataAlternative = {
      all: dataAlternative && dataAlternative.length ? dataAlternative : [{}],
      info: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.FAMILY) || null,
      address: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.FAMILY_RESIDENCE_INFO) || null,
      asset: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.NOT_SET) || null
    };
    return dataAlternative;
  }

}
