<div [class.disabled]="disabled" class="group-field">
  <label *ngIf="label" class="form-label"> {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span> </label>
  <div [formGroup]="formG" class="field-group">
    <input type="text" (blur)="onBlur('primaryCtrl')" [formControlName]="primaryCtrl" [placeholder]="primaryLbl | translate"
           autocomplete="off" class="form-control" [attr.disabled]="disabled ? true : null" />
    <input *ngIf="primaryCtrl_1" type="text" (blur)="onBlur('primaryCtrl_1')" [formControlName]="primaryCtrl_1" [placeholder]="primaryLbl_1 | translate"
           autocomplete="off" class="form-control" [attr.disabled]="disabled || primaryCtrlDisabled_1 ? true : null" />
    <input *ngIf="primaryCtrl_2" type="text" (blur)="onBlur('primaryCtrl_2')" [formControlName]="primaryCtrl_2" [placeholder]="primaryLbl_2 | translate"
           autocomplete="off" class="form-control" [attr.disabled]="disabled || primaryCtrlDisabled_2 ? true : null" />
    <input type="text" (blur)="onBlur('secondaryCtrl')" [formControlName]="secondaryCtrl" [placeholder]="secondaryLbl | translate"
           autocomplete="off" class="form-control" [attr.disabled]="disabled ? true : null" />
  </div>
  <div *ngIf="
    (form.submitted && (formG.controls[primaryCtrl].invalid || formG.controls[secondaryCtrl].invalid)) ||
    (formG.controls[primaryCtrl].invalid || formG.controls[secondaryCtrl].invalid) &&
    (formG.controls[primaryCtrl].dirty || formG.controls[primaryCtrl].touched || formG.controls[secondaryCtrl].dirty || formG.controls[secondaryCtrl].touched)
  " class="invalid-feedback d-block">
    <div *ngIf="formG.controls[primaryCtrl].invalid && formG.controls[primaryCtrl].errors?.required && formG.controls[secondaryCtrl].invalid && formG.controls[secondaryCtrl].errors?.required">
      <span> {{ primaryLbl | translate }} - {{ secondaryLbl | translate }} {{ getErrorText() | translate }} </span>
    </div>
    <div *ngIf="formG.controls[primaryCtrl].invalid && formG.controls[primaryCtrl].errors?.required && !formG.controls[secondaryCtrl].invalid">
      <span> {{ primaryLbl | translate }} {{ getErrorText() | translate }} </span>
    </div>
    <div *ngIf="!formG.controls[primaryCtrl].invalid && formG.controls[secondaryCtrl].invalid && formG.controls[secondaryCtrl].errors?.required">
      <span> {{ secondaryLbl | translate }} {{ getErrorText() | translate }} </span>
    </div>
  </div>
</div>
