import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent implements OnInit, AfterContentInit {

  formGroup: FormGroup;
  @Input() form: FormGroupDirective;
  @Input() label: string;
  @Input() name: string;
  @Input() ids;
  @Input() classList: string;
  @Input() disabled: boolean;
  @Output() change = new EventEmitter();
  @Output() changeOne = new EventEmitter();

  ngOnInit(): void { }

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
    }
  }

  onChange(event): void {
    this.change.emit(event);
    this.changeOne.emit(event);
  }

}
