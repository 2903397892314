export enum ReceiptAndPaymentTypes {
  CASH = 1,
  DEPOSIT_TO_THE_ACCOUNT = 2,
  CARD_BY_CARD = 3,
}

export enum ApprovalStatuses {
  PENDING_APPROVAL = 0,
  ACCEPTED = 1,
  REJECTED = 2,
}

export enum ApprovalStatuses2 {
  PENDING_APPROVAL = 1,
  ACCEPTED = 2,
}

export enum DeliveryTypes {
  // NOT_SET = 0,
  DELIVERY_IN_PERSON = 1,
  DELIVERY_NOT_IN_PERSON = 2,
}

export enum DeliveryMethods {
  // NOT_DELIVERED = 0,
  BARCODE = 1,
  FINGER_PRINT = 2,
}

export enum AidTypes {
  // NOT_SET = 0,
  CASH = 1,
  OBJECTIVE = 2,
  SERVICE = 3,
}

export enum DeliveryToCashierStatus {
  AWAITING_AUDIT = 0,
  AUDITED = 1,
  APPROVED = 2,
}

export enum EconomicStatuses {
  // NOT_SET = 0,
  VERY_POOR = 1,
  POOR = 2,
  NORMAL = 3,
  WELL = 4,
}

export enum FoodIntakeStatuses {
  // NOT_SET = 0,
  ON_FAMILY = 1,
  SHARED_MEAL = 2,
}

export enum DonorTypes {
  // NOT_SET = 0,
  INSTITUTE = 1,
  RELATIVES = 2,
}

export enum FamilyTypes {
  // NOT_SET = 0,
  POOR = 1,
  ORPHAN = 2,
}

export enum FamilyRegisterRequestTypes {
  // NOT_SET = 0,
  REVIEW_AGAIN = 1,
  DISCOVERY_REQUEST = 2,
}

export enum FamilyRegisterRequestSteps {
  REQUEST_EDIT = 0,
  PENDING_REVIEW_MANAGER_DISCLOSURE = 1,
  PENDING_DISCLOSURE = 2,
  PENDING_AUDIT_DISCLOSURE = 3,
  PENDING_FINAL_APPROVAL = 4,
  FINAL_APPROVAL_3 = 5,
}

export enum QualityStatuses {
  // NOT_SET = 0,
  VERY_BAD = 1,
  BAD = 2,
  MEDIUM = 3,
  WELL = 4,
  EXCELLENT = 5,
}

export enum QualityStatuses2 {
  // NOT_SET = 0,
  VERY_BAD = 1,
  BAD = 2,
  MEDIUM = 3,
  WELL = 4,
  EXCELLENT = 5,
  NEW = 6,
  BROKEN = 7,
}

export enum HealthStatuses {
  NOT_REPORTED = 0,
  WELL = 1,
  BAD = 2,
}

export enum DeathTypes {
  // NOT_SET = 0,
  NATIONAL = 1,
  MARTYR = 2,
}

export enum Ancestry {
  NOT_FAMOUS = 0,
  SAYYED = 1,
  NON_SAYYED = 2,
}

export enum FamilyRequestTabs {
  FAMILY_INFO = 1,
  LOCATION_INFO = 2,
  ATTACH_DATA = 3,
}

export enum FamilyInfoTabs {
  FAMILY_CV = 1,
  LOCATION_INFO = 2,
  FAMILY_MEMBER = 3,
  FAMILY_ASSETS = 4,
  OTHER_INCOME = 5,
  COSTS = 6,
  FAMILY_AGREEMENTS = 7,
  ATTACH = 8,
}

export enum FamilyDetailTabs {
  FAMILY_CV = 1,
  LOCATION_INFO = 2,
  FAMILY_MEMBER = 3,
  FAMILY_ASSETS = 4,
  OTHER_INCOME = 5,
  COSTS = 6,
  NEEDS = 7,
  DONATIONS = 8,
  FAMILY_AGREEMENTS = 9,
  ATTACH = 10,
}

export enum FamilyMemberInfo {
  MEMBER_INFO_2 = 1,
  CONTACT_INFO = 2,
  HEALTH_INFO = 3,
  EDUCATIONAL_INFO = 4,
  JOB_INFO = 5,
  SKILLS_INFO = 6,
  ENTERTAINMENT_AND_WISHES_INFO = 7,
  COSTS = 8,
  NEEDS = 9,
  DONATIONS = 10,
  ATTACH = 11,
}

export enum FamilyMemberRegisterTabs {
  PERSON_INFO = 1,
  PERSON_INFO_IN_FAMILY = 2,
}

export enum DonorTabs {
  DONOR_INFO = 1,
  CONTACT_INFO = 2,
  REFERRALS = 3,
}

export enum SponsorTabs {
  SPONSOR_INFO = 1,
  CONTACT_INFO = 2,
  // REFERRALS = 3,
}

export enum Gender {
  // NOT_SET = 0,
  MALE = 1,
  FEMAIL = 2,
}

export enum MaritalStatus {
  // NOT_SET = 0,
  SINGLE = 1,
  MARRIED = 2,
  DIVORCED = 3,
  WIDOW = 4,
}

export enum InputTypes {
  Text = 0,
  Number = 1,
  Money = 2,
  Select = 3,
  Tree = 4,
  Date = 5,
  Check = 6,
  Phone = 7,
  MultiSelect = 9,
}

export enum InputTypesInDefault {
  Text = 0,
  Number = 1,
  Money = 2,
  Select = 3,
  Tree = 4,
  Date = 5,
  MultiSelect = 6,
  OrganizationalPosition = 7,
  SponsorshipCashDonationTree = 8,
}

export enum EducationStatuses {
  STUDYING = 1,
  DROPOUT = 2,
  COMPLETION = 3,
}

export enum CureStatuses {
  CURED = 0,
  NOT_CURED = 1,
}

export enum ServiceDonationRequestStatuses {
  // NOT_SET = 0,
  PRESENTATION_AND_EVALUATION = 1,
  COMPLETE_EVALUATION = 2,
  COMPLETE_CONFIRMATION = 3,
}

export enum ServiceDonationStatuses {
  // NOT_SET = 0,
  AWAITING_DONATION_BENEFICIARIES = 1,
  AWAITING_EVALUATION = 2,
  EVALUATED = 3,
  APPROVED = 4,
  RETURNED = 5,
}

export enum Culture {
  "en-US" = 1,
  "ar-IQ" = 2,
  "fa-IR" = 3,
}

export enum AidRequestStatuses {
  AID_REQUEST = 0,
  AUDITING = 1,
  AUDITED = 2,
  REVIEWING = 3,
  REVIEWED = 4,
  CANCELED = 5,
}

export enum AidRequest2Statuses {
  AID_REQUEST = 0,
  PENDING_APPROVAL = 1,
  APPROVAL = 2,
  REVIEWING = 3,
  REVIEWED = 4,
  CANCELED = 5,
}

export enum BoxStatuses {
  AT_THE_DISPOSAL_OF_THE_CENTER = 0,
  AT_THE_DISPOSAL_OF_THE_AGENT = 1,
  AT_THE_DISPOSAL_OF_THE_LOCATION = 2,
  DEAD = 3,
  LOST = 4,
}

export enum PaymentReceiptToTheAgentStatuses {
  DELIVERING_TO_AGENT = 1,
  DELIVERED_TO_AGENT = 2,
}

export enum ActiveStatuses {
  DE_ACTIVE = 0,
  ACTIVE = 1,
}

export enum LocationTabs {
  LOCATION_INFO = 1,
  CONTACT_INFO = 2,
  BOXES = 3,
}

export enum ReligiousCommitmentType {
  HAS_IT = 1,
  DOES_NOT_HAVE = 2,
}

export const ReligiousCommitment = [
  { name: 'HAS_IT', value: true,},
  { name: 'DOES_NOT_HAVE', value: false,},
]

export enum CollectionPeriodsStatuses {
  AWAITING_COLLECTION = 0,
  COLLECTING = 1,
  COLLECTED = 2,
  IN_COMPLETE_DELIVERY_TO_CENTER = 3,
  DELIVERY_TO_CENTER = 4,
}

export enum CollectionPeriods2Statuses {
  NOT_SET = 0,
  Monthly = 1,
}

export enum CollectionLocationsStatuses {
  AWAITING_COLLECTION = 0,
  COLLECTING = 1,
  COLLECTED = 2,
  RETURNED = 3,
}

export enum CollectionLocations2Statuses {
  NOT_SET = 0,
  Home = 1,
  WorkPlace = 2,
  Institut = 3,
}

export enum CashDeliveryStatuses {
  REVIEWED = 1,
  RETURNED = 2,
  DELIVERED = 3,
}

export enum CashReceiptStatus {
  DELIVERED = 1,
  RETURNED = 2,
}

export enum DeliveryReportTypes {
  DELIVERY = 1,
  RETURN = 2,
}

export enum GoodDonationRequestStatuses {
  // NOT_SET = 0,
  AWAITING_ENTER_WAREHOUSE = 1,
  ENTERING_WAREHOUSE = 2,
  AWAITING_EVALUATION = 3,
  PENDING_APPROVAL = 4,
  FINAL_APPROVAL = 5,
}

export enum ObjectiveAidStatuses {
  // NOT_SET = 0,
  AWAITING_REVIEW = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum CashAidStatus {
  AWAITING_AUDIT = 0,
  AUDITED = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  AWAITING_ACCOUNTANT_APPROVAL = 4,
  AWAITING_DELIVERY_TO_AGENT = 6,
  DELIVERING_TO_AGENT = 7,
  AWAITING_DELIVERY_TO_FAMILY = 8,
  DELIVERED = 9,
  RETURNED = 10,
}

export enum ExitGoodOrderStatus {
  WAREHOUSE_EXIT_ORDER = 1,
  DELIVERED = 2,
}

export enum TransferStatus {
  TRANSFER = 1,
  DELIVERED_IN_DESTINATION = 2,
}

export enum NecessityTypes {
  VERY_URGENT = 1,
  NORMAL = 2,
}

export enum GoodsBuyingRequestStatus {
  PENDING_PURCHASE = 1,
  SHOPPING = 2,
  CANCEL_PURCHASE = 3,
  AWAITING_ARRIVAL_IN_WAREHOUSE = 4,
  AVAILABLE_IN_WAREHOUSE = 5,
}

export enum UserTabs {
  PERSON_INFO = 1,
  // ORGANIZATIONAL_INFO = 2,
  USER_INFO = 3,
  SINGLE_PERMISSIONS = 4,
  GROUP_PERMISSIONS = 5,
}

export enum Roles {
  // GUEST = 0,
  // USER = 1,
  ADMIN = 2,
  EMPLOYEE = 3,
  // DONOR = 4,
  // FAMILY_MEMBER = 5,
  // REFFER = 6,
  AGENT = 7,
  PERSONNEL = 8,
  SPONSORSHIP = 9,
}

export enum DonationTreeTypes {
  // NOT_SET = 0,
  CASH = 1,
  OBJECTIVE = 2,
  SERVICE = 3,
  PROJECT = 4,
}

export enum ReceiveStatuses {
  NOT_DELIVERED = 0,
  DELIVERED = 1,
  RETURNED = 2,
}

export enum ReturnFromCashierStatuses {
  AWAITING_AUDIT = 1,
  AUDITED = 2,
  APPROVED = 3,
}

export enum ConditionTypes {
  // NOT_SET = 0,
  FAMILIES = 1,
  FAMILY_MEMBERS = 2,
}

export enum AttachmentGroups {
  NOT_SET = 0,
  FAMILY = 1,
  FAMILY_MEMBER = 2,
  FAMILY_RESIDENCE_INFO = 3,
  EDUCATION = 4,
  HEALTH = 5,
}

export enum TransferCashStatuses {
  NOT_SET = 0,
  SUBMIT_REQUEST = 1,
  AUDITING = 2,
  AUDITED = 3,
  REVIEWING = 4,
  APPROVED = 5,
  PAYING = 6,
  PAID = 7,
  CANCELLED = 8,
}

export enum CashTransferDeliveryStatus {
  NOT_SET = 0,
  DELIVERING = 1,
  DELIVERED = 2,
  RECEIVED = 3,
  ACCEPTED = 4,
}

export enum TransferPayStatus {
  THE_DEBTOR = 1,
  CLEARED = 2,
}

export enum TransferGoodStatuses {
  NOT_SET = 0,
  SUBMIT_REQUEST = 1,
  WAREHOUSE_CHECKING = 2,
  EXIT_WAREHOUSE_REQUEST = 3,
  REVIEWING = 4,
  ACCEPTED = 5,
  DELIVERING = 6,
  DELIVERED = 7,
  CANCELED = 8,
}

export enum TransferGoodDeliveryStatuses {
  NOT_SET = 0,
  DELIVERING = 1,
  DELIVERED = 2,
  RECEIVED = 3,
  ACCEPTED = 4,
}

export enum ActionStatuses {
  NOT_SET = 0,
  SUBMIT = 1,
  EDIT = 2,
  DELETE = 3,
}

export enum EmploymentRequestStatuses {
  NOT_SET = 0,
  AWAITING_REVIEW = 1,
  AWAITING_INTERVIEW = 2,
  AWAITING_INTERVIEW_REVIEW = 3,
  START_JOB = 4,
  CANCELED = 5,
}

export enum PersonalProfileTabs {
  EMPLOYEE_INFO = 1,
  CONTACT_INFO = 2,
  // ORGANIZATIONAL_INFO = 3,
  OTHER_INFO = 3,
  DEPENDENTS = 4,
  EDUCATION_LICENSE_RECORDS = 5,
  JOB_RECORDS = 6,
  SKILLS = 7,
  ATTACH = 8,
  CONTRACT_LIST = 9,
  RULE_LIST = 10,
  VIEW_ALL_SALARY = 11,
}

export enum ContactInfoForms {
  RESIDENCE_PLACE = 1,
  WORK_PLACE = 2,
  CONTACT_INFO = 3,
  SOCIAL_NETWORK = 4,
}

export enum RelationTypes {
  DAUGHTER = 1,
  SON = 2,
  SPOUSE = 3,
  MOTHER = 4,
  FATHER = 5,
}

export enum HrAttachmentGroups {
  NOT_SET = 0,
  EMPLOYMENT_REQUEST = 1,
  EMPLOYMENT = 2,
}

export enum AddressTypes {
  RESIDENCE = 0,
  WORK = 1,
}

export enum CooperationStatuses {
  // NOT_SET = 0,
  COLLABORATING = 1,
  COOPERATION_LACK = 2,
}

export enum EmployeeContractStatuses {
  NOT_SET = 0,
  REGISTERED = 1,
  CANCELED = 2,
}

export enum ImprestStatus {
  SUBMIT_REQUEST = 1,
  PAYED = 2,
  CANCELED = 3,
  CLEARED = 4,
}

export enum EmployeeLoanStatuses {
  // NOT_SET = 0,
  SUBMIT_REQUEST = 1,
  APPROVED = 2,
  PAYED = 3,
  CLEARED = 4,
  CANCELED = 5,
}

export enum EmployeeEndOfCooperationRequestStatuses {
  // NOT_SET = 0,
  AWAITING_REVIEW = 1,
  DISCHARGE = 2,
  AWAITING_FINAL_REVIEW = 3,
  FINAL_APPROVAL = 4,
  CANCELED = 5,
}

export enum FineOrRewardType {
  FINE = 1,
  REWARD = 2,
}

export enum SalaryListStatuses {
  SUBMITTING_INFO = 1,
  SALARY_CALCULATION = 2,
  CONFIRM_AND_PAYED = 3,
}

export enum SponsorshipStatuses {
  SUBMIT_REQUEST = 1,
  AWAITING_REVIEW_VIA_ADMIN_BRANCH = 2,
  AWAITING_REVIEW_VIA_ADMIN_BASE = 3,
  APPROVED = 4,
  CANCELED = 5,
}

export enum HrSettingKeys {
  DISCHARGE_ORGANIZATIONAL_POSITION = "discharge_organizational_position",
  BASE_SALARY = "base_salary",
  WORKING_HOURS_PER_DAY = "working_hours_per_day",
}

export enum SponsorshipPaySalaryRequestStatuses {
  SUBMIT_REQUEST = 1,
  APPROVED = 2,
  CANCELED = 3,
}

export enum SponsorshipPaySalaryRequestStatuses2 {
  SUBMIT_REQUEST = 1,
  APPROVED = 2,
  CANCELED = 4,
}

export enum SettingsTreeTabs {
  LIST_VIEW = 1,
  TREE_VIEW = 2,
}

export enum TransactionReceiptTypes {
  DEPOSIT = 1,
  WITHDRAW = 2,
}

export enum IncomeTypes {
  // NOT_SET = 0,
  CASH_DONATION = 1,
  CASH_DONATION_TATVIRI = 2,
  CASH_DONATION_SPONSORSHIP = 3,
  OBJECTIVE_DONATION = 4,
  SERVICE_DONATION = 5,
  RECEIPT_SPONSORSHIP = 6,
}

export enum GeoNameGroups {
  FAMILY = 1,
  SCHOOL = 2,
}

export enum ProgramRequestStatuses {
  SUBMITTING = 1,
  COMPLETE_INFORMATION = 2,
  CONFIRM_VIA_RESPONSIBLE = 3,
  CONFIRM_VIA_ADMIN_BRANCH = 4,
}

export enum ProgramRequestTabs {
  INFORMATION_BASE = 1,
  INFORMATION_PERSONS = 2,
  ADVERTISING = 3,
  WAREHOUSING = 4,
  FISCAL_INFO = 5,
  APPENDICES = 6,
}

export enum ProgramTypes {
  OFFICIAL = 1,
  BRANCH_2 = 2,
  Institutes = 3,
  RELIGIOUS = 4,
  OTHER = 5,
}

export enum TeamTypes {
  // NOT_SET = 0,
  DISCOVERY = 1,
  EVALUATION = 2,
}

export enum Apps {
  // NOT_SET = 0,
  IDENTITY = 1,
  DONATION = 2,
  FAMILY = 3,
  SPONSORSHIP = 4,
  HR = 5,
  LOG = 6,
  SETTINGS = 7,
}

export enum FixAssetStatuses {
  // NOT_SET = 0,
  SOLD_OUT = 1,
  EXISTING = 2,
  ABORTION = 3,
}

export enum Payers {
  // NOT_SET = 0,
  ALL_MEMBER = 1,
  ONE_OF_MEMBER = 2,
}

export enum BookValueHistoryFixAssetStatuses {
  // NOT_SET = 0,
  INITIAL_VALUE = 1,
  DEPRECIATION = 2,
  RE_PRICING = 3,
}
