import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { App as CapacitorApp } from '@capacitor/app';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AccountingService } from './core/api/accounting.service';
import { DonationService } from './core/api/donation.service';
import { PrintService } from './core/services/print.service';
import { TranslateService } from './core/services/translate.service';
import { LanguageModel, FiscalYearModel } from './core/models/public-model';
import { ColDefsClass } from './core/models/column-defs-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  langID: number;
  formatted: {};
  loading: boolean;

  constructor(private ngbModal: NgbModal, private accountingService: AccountingService, private donationService: DonationService, public printService: PrintService,
              private translateService: TranslateService) {
    this.langID = +localStorage.getItem('langId') || 2;
    this.formatted = {};
    this.loading = true;
  }

  ngOnInit() {
    // CapacitorApp.addListener('backButton', ({ canGoBack }) => !canGoBack ? CapacitorApp.exitApp() : window.history.back()).then(() => {});
    this.getTranslations();
    this.getFiscalYear();
  }

  @HostListener('window:popstate', ['$event']) dismissAllModal(): void {
    this.ngbModal.dismissAll();
  }

  getTranslations(): void {
    this.loading = true;
    const colDef = new ColDefsClass();
    this.donationService.translationGetAll<LanguageModel[]>({ Language: this.langID }).pipe(map((res) => {
      res.data.map((item) => this.formatted[item.key] = item.value);
      this.setTranslations();
      colDef.setTranslatePipe();
      this.loading = false;
    }), catchError(() => {
      colDef.setTranslatePipe();
      this.loading = false;
      return of();
    })).subscribe();
  }

  setTranslations(): void {
    if (JSON.stringify(this.translateService.keywords) === JSON.stringify(this.formatted)) {
      return;
    }
    this.translateService.keywords = this.formatted;
    localStorage.setItem('translations', JSON.stringify(this.formatted));
  }

  getFiscalYear(): void {
    this.accountingService.fiscalYearFindFiscalYear<FiscalYearModel[]>({}).pipe(map((res) => {
      const yearId = res.data.find((item) => item.isCurrent === true)?.id;
      yearId ? localStorage.setItem('fiscalYearId', yearId.toString()) : null;
    })).subscribe();
  }

}
