import {
  Component,
  OnInit,
  AfterContentInit,
  Input,
  Output,
  EventEmitter, SimpleChanges,
  Injectable,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
  NgbDateStruct,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";

const MONTHS = ['1-Jan', '2-Feb', '3-Mar', '4-Apr', '5-May', '6-Jun', '7-Jul', '8-Aug', '9-Sep', '10-Oct', '11-Nov', '12-Dec'];
const WEEKDAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getMonthShortName = (month: number): string => MONTHS[month - 1];
  getMonthFullName = (month: number): string => MONTHS[month - 1];
  getWeekdayLabel = (weekday: number): string => WEEKDAYS[weekday - 1];
  getDayAriaLabel = (date: NgbDateStruct): string => `${ date.year }-${ date.month }-${ date.day }`;
}

@Component({
  selector: "app-datepicker",
  templateUrl: "date-picker.component.html",
  styleUrls: ["date-picker.component.scss"],
  providers: [{ provide: NgbDatepickerI18n, useClass: IslamicI18n }],
})
export class DatePickerComponent implements OnInit, AfterContentInit {
  formGroup: FormGroup;
  value: NgbDateStruct;
  @Input() form: FormGroupDirective;
  @Input() isTime = false;
  @Input() date: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  @Input() label = "";
  @Input() name = "";
  @Input() placeholder = "";
  @Input() required = false;
  @Input() disabled = false;
  @Input() classList = "";
  @Input() ids: any = "";
  @Input() isClearable = false;
  @Input() minDate = { year: 1920, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct;
  @Output() dateChange = new EventEmitter();

  constructor(
    private datePipe: DatePipe,
    private formatter: NgbDateParserFormatter
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.form) {
      if (this.name === "birthDate") {
        this.maxDate = {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate(),
        };
      }
      else {
        this.maxDate = {
          year: new Date().getFullYear() + 100,
          month: new Date().getMonth() + 1,
          day: new Date().getDate(),
        };
      }
      this.formGroup = this.form.form;
      const value = this.formGroup.value[this.name]
        ? this.formGroup.value[this.name]
        : null;
      this.formGroup.controls[this.name].setValue(
        this.datePipe.transform(value, "YYYY-MM-dd")
      );
      if (this.disabled) {
        // this.formGroup.controls[this.name].disable();
      }
      if (!this.formGroup.controls[this.name].validator) {
        this.isClearable = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && !changes.disabled?.firstChange) {
      this.changeInputState();
    }
  }

  selectedDate(): void {
    this.dateChange.emit(this.formatter.format(this.date));
  }

  onDateSelect(date: NgbDateStruct): void {
    this.formGroup.controls[this.name].setValue(this.formatter.format(date));
    this.dateChange.emit();
  }

  getErrorText(): string {
    const control = this.formGroup.controls[this.name];
    if (control.errors.required || control.errors.whitespace) {
      return "IS_REQUIRED";
    } else if (control.errors.custom) {
      return control.errors.custom;
    }
  }

  clearDate() {
    this.formGroup.controls[this.name].reset();
    this.dateChange.emit();
  }

  changeInputState() {
    if (this.disabled) {
    } else if (!this.disabled && !this.form?.disabled) {
      this.formGroup?.controls[this.name].enable();
    }
  }
}
