<table class="w-100">
  <thead>
    <tr>
      <td>
        <header class="justify-content-between d-flex">
          <div class="flex-fill">
            <h1 class="mb-3"> موسسه الیتیم الخیریه <span class="page-title"> ({{ translatePipe.transform(printService?.mainTitle) }}) </span> </h1>
            <div class="row">
              <ng-container *ngIf="printService?.headerData?.length; else mainData">
                <div *ngFor="let item of printService.headerData" class="{{ item.class || 'col-6' }}">
                  <b>
                    {{ translatePipe.transform(item.key) }}
                    <ng-container *ngIf="!item.key.toString().toLowerCase().includes('_ar') && !item.key.toString().toLowerCase().includes(translatePipe.transform('IQD')) && (item.value && !item.value.toString().toLowerCase().includes(translatePipe.transform('IQD')))">
                      {{ item.key.toString().toLowerCase().includes('amount') || (item.key.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
                    </ng-container> :
                  </b>
                  <span class="d-inline-block"> {{ translatePipe.transform(item.value) }} </span>
                </div>
              </ng-container>
            </div>
          </div>
          <img src="assets/images/logo-alyatim-2.svg" height="120" alt="" class="rounded-circle" />
        </header>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="pt-3">
        <table *ngIf="printService.view === 'table'; else notTable" class="table-data table-bordered table-striped w-100 mb-2">
          <thead>
            <tr>
              <th *ngFor="let col of printService.columnDefs" [ngStyle]="{ 'min-width': col.minWidth + 'px' }" [style]="col?.style">
                <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'">
                  <span>
                    {{ translatePipe.transform(col.headerName) }}
                    <ng-container *ngIf="col.IQD !== false && !col.headerName.toString().toLowerCase().includes('_ar') && !col.headerName.toString().toLowerCase().includes(translatePipe.transform('IQD'))">
                      {{ col.field.toString().toLowerCase().includes('amount') || col.headerName.toString().toLowerCase().includes('amount') || (col.field.toString().toLowerCase().includes('total') && col.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
                    </ng-container>
                  </span>
                </div>
              </th>
              <th *ngIf="printService.isFinger"> {{ translatePipe.transform('FINGER_PRINT') }} </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of printService.tableData; let index = index">
              <td *ngFor="let col of printService.columnDefs">
                <div *ngIf="col.headerName === '#'"> {{ index + 1 }} </div>
                <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'">
                  <div *ngIf="col.template === 'checkbox'; else otherTemplates" class="form-check align-items-center gap-2 d-flex ps-4">
                    <input type="checkbox" [attr.checked]="data[col.field] ? '' : null" class="form-check-input" [disabled]="true" />
                    <span> {{ col.type === 'number' ? (data[col.label] | number) : data[col.label] }} </span>
                  </div>
                  <ng-template #otherTemplates>
                    <span> {{ col.type !== 'link' && col.cellRenderer ? translatePipe.transform(col | renderer:{ data: data }: col.field + 'Name') : translatePipe.transform(data[col.field]) }} </span>
                  </ng-template>
                </div>
              </td>
              <td *ngIf="printService.isFinger"> </td>
            </tr>
          </tbody>
        </table>
        <ng-template #notTable>
          <ng-container *ngFor="let data of printService.tableData">
            <div class="row gx-0 gy-2">
              <ng-container *ngIf="printService.isFinger; else notFinger">
                <div class="col-10">
                  <div class="row gx-0 gy-2">
                    <ng-container *ngFor="let col of printService.columnDefs">
                      <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'"
                           class="{{ col.field === 'description' || col.type === 'long' ? 'col-12' : (col.class || 'col-6') }}">
                        <b> {{ translatePipe.transform(col.headerName) }} : </b>
                        <span class="d-inline-block"> {{ col.type !== 'link' && col.cellRenderer ? translatePipe.transform(col | renderer:{ data: data }: col.field + 'Name') : translatePipe.transform(data[col.field]) }} </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-2">
                  <div class="finger-square"> {{ translatePipe.transform('FINGER_PRINT') }} </div>
                </div>
              </ng-container>
              <ng-template #notFinger>
                <ng-container *ngFor="let col of printService.columnDefs">
                  <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'"
                       class="{{ col.field === 'description' || col.type === 'long' ? 'col-12' : (col.class || 'col-6') }}">
                    <b> {{ translatePipe.transform(col.headerName) }} : </b>
                    <span class="d-inline-block"> {{ col.type !== 'link' && col.cellRenderer ? translatePipe.transform(col | renderer:{ data: data }: col.field + 'Name') : translatePipe.transform(data[col.field]) }} </span>
                    <ng-container *ngIf="col.IQD !== false && !col.headerName.toString().toLowerCase().includes('_ar') && !col.headerName.toString().toLowerCase().includes(translatePipe.transform('IQD'))">
                      {{ col.field.toString().toLowerCase().includes('amount') || col.headerName.toString().toLowerCase().includes('amount') || (col.field.toString().toLowerCase().includes('total') && col.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
                    </ng-container>
                  </div>
                </ng-container>
                <table *ngIf="printService.subView === 'table'; else notSubTable" class="table-data table-bordered table-striped w-100 mb-2">
                  <thead>
                    <tr>
                      <th *ngFor="let col of printService.subColumnDefs" [ngStyle]="{ 'min-width': col.minWidth + 'px' }" [style]="col?.style">
                        <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'">
                        <span>
                          {{ translatePipe.transform(col.headerName) }}
                          <ng-container *ngIf="col.IQD !== false && !col.headerName.toString().toLowerCase().includes('_ar') && !col.headerName.toString().toLowerCase().includes(translatePipe.transform('IQD'))">
                            {{ col.field.toString().toLowerCase().includes('amount') || col.headerName.toString().toLowerCase().includes('amount') || (col.field.toString().toLowerCase().includes('total') && col.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
                          </ng-container>
                        </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dataItem of data.subData; let index = index">
                      <td *ngFor="let col of printService.subColumnDefs">
                        <div *ngIf="col.headerName === '#'"> {{ index + 1 }} </div>
                        <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'">
                          <div *ngIf="col.template === 'checkbox'; else otherTemplates" class="form-check align-items-center gap-2 d-flex ps-4">
                            <input type="checkbox" [attr.checked]="dataItem[col.field] ? '' : null" class="form-check-input" [disabled]="true" />
                            <span> {{ col.type === 'number' ? (dataItem[col.label] | number) : dataItem[col.label] }} </span>
                          </div>
                          <ng-template #otherTemplates>
                            <span> {{ col.type !== 'link' && col.cellRenderer ? translatePipe.transform(col | renderer:{ data: dataItem }: col.field + 'Name') : translatePipe.transform(dataItem[col.field]) }} </span>
                          </ng-template>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #notSubTable>
                  <ng-container *ngFor="let dataItem of data.subData">
                    <div class="col-7 divider mt-n1 mb-2"> <span class="divider-text pe-2"> {{ translatePipe.transform(printService.subTitle) }} </span> </div>
                    <ng-container *ngFor="let col of printService.subColumnDefs">
                      <div *ngIf="col.headerName !== '#' && col.field !== 'check' && col.type !== 'status' && col.field !== 'finger' && col.field !== 'action'"
                           class="{{ col.field === 'description' || col.type === 'long' ? 'col-12' : (col.class || 'col-6') }}">
                        <b> {{ translatePipe.transform(col.headerName) }} : </b>
                        <span class="d-inline-block"> {{ col.type !== 'link' && col.cellRenderer ? translatePipe.transform(col | renderer:{ data: dataItem }: col.field + 'Name') : translatePipe.transform(dataItem[col.field]) }} </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-template>
              <div *ngIf="printService.subView !== 'table'" class="divider align-items-center d-flex my-3"> </div>
            </div>
          </ng-container>
        </ng-template>
      </td>
    </tr>
    <tr>
      <td> <!-- class="px-3" -->
        <div *ngIf="printService.description">
          <b> {{ translatePipe.transform('DESCRIPTION') }} : </b>
          <span> {{ printService.description }} </span>
        </div>
        <div class="row gx-0 gy-2">
          <ng-container *ngIf="printService?.footerData || printService?.footerData?.length; else footerCustomData">
            <div *ngFor="let item of printService.footerData | keyvalue: returnZero" class="col-4">
              <b>
                {{ translatePipe.transform(item.key) }}
                <ng-container *ngIf="!item.key.toString().toLowerCase().includes('_ar') && !item.key.toString().toLowerCase().includes(translatePipe.transform('IQD')) && (item.value && !item.value.toString().toLowerCase().includes(translatePipe.transform('IQD')))">
                  {{ item.key.toString().toLowerCase().includes('amount') || (item.key.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
                </ng-container> :
              </b>
              <span> {{ typeOf(item.value) === 'string' ? item.value : item.value | number }} </span>
            </div>
          </ng-container>
          <div *ngIf="printService.isSignature" class="col-12 my-3">
            <b> {{ translatePipe.transform('SIGNATURE') }} : </b>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #mainData>
  <div *ngFor="let item of printService.mainData | keyvalue: returnZero" class="col-6">
    <b>
      {{ translatePipe.transform(item.key) }}
      <ng-container *ngIf="!item.key.toString().toLowerCase().includes('_ar') && !item.key.toString().toLowerCase().includes(translatePipe.transform('IQD')) && (item.value && !item.value.toString().toLowerCase().includes(translatePipe.transform('IQD')))">
        {{ item.key.toString().toLowerCase().includes('amount') || (item.key.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
      </ng-container> :
    </b>
    <span class="d-inline-block"> {{ translatePipe.transform(item.value) }} </span>
  </div>
</ng-template>
<ng-template #footerCustomData>
  <div *ngFor="let item of printService.footerCustomData" class="{{ item.class || 'col-4' }}">
    <b>
      {{ translatePipe.transform(item.key) }}
      <ng-container *ngIf="!item.key.toString().toLowerCase().includes('_ar') && !item.key.toString().toLowerCase().includes(translatePipe.transform('IQD')) && (item.value && !item.value.toString().toLowerCase().includes(translatePipe.transform('IQD')))">
        {{ item.key.toString().toLowerCase().includes('amount') || (item.key.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + translatePipe.transform('IQD') + ')') : '' }}
      </ng-container> :
    </b>
    <span> {{ typeOf(item.value) === 'string' ? item.value : item.value | number }} </span>
  </div>
</ng-template>
