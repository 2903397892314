import { Component, Input, Output, EventEmitter } from "@angular/core";
import { PagingModel } from "src/app/core/models/paging.model";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
  @Input() paging: PagingModel;
  @Output() pageChange = new EventEmitter();

  change(e): void {
    this.paging.skip = (e - 1) * this.paging.take;
    this.pageChange.emit(this.paging);
  }
}
