import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Culture } from "../models/enums";
import { environment } from "../../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  culture = Culture;
  envName: string;
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (environment.defaultAuth === 'firebase') {
    //     const currentUser = this.authenticationService.currentUser();
    //     if (currentUser && currentUser.token) {
    //         request = request.clone({
    //             setHeaders: {
    //                 Authorization: `Bearer ${currentUser.token}`
    //             }
    //         });
    //     }
    // } else {
    //     const currentUser = this.authfackservice.currentUserValue;
    //     if (currentUser && currentUser.token) {
    //         request = request.clone({
    //             setHeaders: {
    //                 Authorization: `Bearer ${currentUser.token}`
    //             }
    //         });
    //     }
    // }
    let req: HttpRequest<any>;
    let token = localStorage.getItem("token") || "";
    let langId = +localStorage.getItem("langId") || 2;
    // let req = request.clone({
    //   headers: request.headers.set("Accept-Language", this.culture[langId]),
    // });
    this.envName = environment.accounting;
    if (!request.url.includes(this.envName) && (request.url.includes("tasaheel") || request.url.includes("alyatim"))) {
      req = request.clone({
        headers: request.headers
          .set("Authorization", `Bearer ${token}`)
          .set("Accept-Language", this.culture[langId]),
      });
    } else if (request.url.includes("blob")) {
      req = request;
    } else {
      const yearId = localStorage.getItem("fiscalYearId") ?? "";
      req = request.clone({
        headers: request.headers
          .set("Token", "6A85C4FC-87F5-4C8D-B0EA-54D614992D93")
          .set("Accept-Language", this.culture[langId])
          .set("FiscalYearID", yearId),
      });
    }
    // const token = this.tools.getToken();

    // if (!token) {
    //   return next.handle(req);
    // }

    // const req1 = req.clone({
    //   headers: token
    //     ? req.headers.set("Authorization", `Bearer ${token}`)
    //     : req.headers.set("", ``),
    // });
    return next.handle(req);
  }
}
