import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})

export class PaymentStatusComponent implements OnInit {

  @Input() status: { title: string; color: string };
  @Input() statusName: string;

  constructor() {}

  ngOnInit(): void {}

}
