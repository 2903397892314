import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
  ITreeOptions,
  ITreeState,
  TreeComponent,
  TreeNode,
} from "@circlon/angular-tree-component";
import { Utils } from "src/app/core/helpers/utils";

@Component({
  selector: "app-dropdown-tree",
  templateUrl: "./dropdown-tree.component.html",
  styleUrls: ["./dropdown-tree.component.scss"],
})
export class DropdownTreeComponent
  implements OnInit, OnChanges, AfterViewInit, AfterContentInit
{
  tree: TreeComponent;
  state: ITreeState = {};
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
    rtl: true,
    allowDrag: false,
    allowDrop: false,
  };
  nodeId: number;
  path: any;
  selectedChildrenIds: number[] = [];
  clearTitle = false;
  formGroup: FormGroup;

  @Input() form: FormGroupDirective;
  @ViewChild("tree2", { static: true }) tree2: TreeComponent;
  @ViewChild("acc") acc;
  @ViewChild("container") container: ElementRef;
  @Input() idField: string = "id";
  @Input() nodes = [];
  @Input() selectedNode: TreeNode;
  @Input() prefixDisplayField = null;
  @Input() displayId = "id";
  @Input() defaultTitle = "";
  @Input() displayField = "name";
  @Input() disabledParents = false;
  @Input() disabledParentsPro = false;
  @Input() hasSearch = true;
  @Input() hasCheckbox = true;
  @Input() hasDropdown = true;
  @Input() isEditting: boolean;
  @Input() reset: string;
  @Input() label = "";
  @Input() ids = "";
  @Input() classList: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() name: string;
  @Input() selectedNodeId;
  @Input() isClearable = true;
  @Output() selectNode = new EventEmitter();
  @Output() resetChange = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() treeChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Input() iconLevel = null;
  @Input() selectableLevel = null;
  @Input() selectableType = null;
  @Input() showPath: boolean = false;
  timer: any;
  selectedNodes: any = [];
  @Output() selectedNodesChange = new EventEmitter();

  constructor(private cd: ChangeDetectorRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.options.idField = this.idField;
    this.options.useCheckbox = this.hasCheckbox;
    this.options.displayField = this.displayField;
    if (this.nodes) {
      this.nodes = Utils.convertAccountToTree(
        this.nodes,
        null,
        "parentID",
        "Id"
      );
    }
    if (this.selectedNode) {
      this.setSelectedNode();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.disabled?.firstChange) {
      if (this.disabled || this.form?.disabled) {
        setTimeout(() => {
          this.onDeactivate();
          this.resetChange.emit("");
        }, 0);
      }
    }
    if (this.selectedNode && changes && !changes.selectedNode?.firstChange) {
      this.setSelectedNode("inChange");
    }
    if (this.reset === "reset") {
      setTimeout(() => {
        this.onDeactivate();
        this.resetChange.emit("");
      }, 0);
    }
  }

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
    }
  }

  ngAfterViewInit(): void {
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        !(this.container.nativeElement as HTMLElement).contains(
          e.target as HTMLElement
        ) &&
        this?.acc?.activeIds[0]
      ) {
        this.acc.activeIds[0] = null;
      }
    });
    if (this.formGroup?.value[this.name]) {
      this.selectedNode = this.tree2.treeModel.getNodeById(
        this.formGroup.value[this.name]
      );
      this.setSelectedNode();
      this.cd.detectChanges();
    }
  }

  setSelectedNode(status?: string) {
    this.state.focusedNodeId = this.selectedNode?.data?.[this.displayId];
    this.setTitle();
    this.onActivate(this.selectedNode, status);
  }

  onToggle(e) {
    if (this.clearTitle) {
      e.preventDefault();
      return;
    }
    this.acc.toggle("toggle-1");
  }

  onStateChange(tree?: TreeComponent): void {
    if (tree) {
      this.tree = tree;
      this.path = this.tree.treeModel;
      if (this.nodes) {
        const temp = this.nodes.find(
          (item) => item[this.displayId] === this.state.focusedNodeId
        );
        temp
          ? (temp.checked =
              this.state.selectedLeafNodeIds[this.state.focusedNodeId])
          : null;
      }
      this.treeChange.emit(tree);
    }
  }

  onClear(): void {
    this.selectedNode = null;
    this.defaultTitle = "";
    if (this.form) {
      this.formGroup.controls[this.name].setValue(null);
    }
    this.clear.emit();
  }

  onActivate(node: TreeNode, statusSelectedNode?: string): void {
    if (
      (this.disabledParents && !node?.isLeaf) ||
      (this.disabledParentsPro && node?.isLeaf && !node.data.parentId) ||
      (this.selectableLevel && node?.level !== this.selectableLevel) ||
      (this.selectableType && !node?.data[this.selectableType])
    ) {
      this.deactivateNodes();
      return;
    }
    this.nodeId = node?.data?.[this.displayId];
    this.selectedNode = node;
    this.defaultTitle = this.selectedNode?.data?.[this.displayField];
    if (this.showPath && this.selectedNode && this.selectedNode.path && this.selectedNode.path.length) {
      let path = '';
      this.selectedNode.path.map((id, index) => path += this.tree2.treeModel.getNodeById(id)?.data?.[this.displayField] + (index !== this.selectedNode.path.length - 1 ? ' / ' : ''));
      this.defaultTitle = path;
    }
    if (this.acc && this.acc.activeIds) {
      this.acc.activeIds[0] = null;
    }
    if (this.formGroup) {
      this.formGroup.controls[this.name].setValue(
        this.selectedNode?.data[this.displayId]
      );
    }
    !statusSelectedNode ? this.selectNode.emit(this.selectedNode) : null;
  }

  onDeactivate(): void {
    this.deactivateNodes();
    this.selectNode.emit(this.selectedNode);
  }

  deactivateNodes(): void {
    this.nodeId = this.selectedNode = null;
    this.defaultTitle = "";
    if (this.tree) {
      const temp = this.tree.treeModel.getActiveNode();
      const temp2 = this.tree.treeModel.getFocusedNode();
      if (temp && temp.isActive) {
        temp.toggleActivated();
      }
      if (temp2) {
        temp2.blur();
      }
    }
    // if (this.tree2) {
    //   const temp = this.tree2.treeModel.getActiveNode();
    //   const temp2 = this.tree2.treeModel.getFocusedNode();
    //   if (temp && temp.isActive) {
    //     temp.toggleActivated();
    //   }
    //   if (temp2) {
    //     temp2.blur();
    //   }
    // }
  }

  setTitle(): void {
    if (this.isEditting) {
      this.defaultTitle =
        this.selectedNode.parent.data[this.displayField] || "";
      this.state.focusedNodeId = this.selectedNode.parent.data[this.displayId];
    } else {
      this.defaultTitle = this.selectedNode?.data?.[this.displayField];
    }
    if (this.showPath && this.selectedNode && this.selectedNode.path && this.selectedNode.path.length) {
      let path = '';
      this.selectedNode.path.map((id, index) => path += this.tree2.treeModel.getNodeById(id)?.data?.name + (index !== this.selectedNode.path.length - 1 ? ' / ' : ''));
      this.defaultTitle = path;
    }
  }

  onEvent(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (event.eventName === "activate") {
        this.selectionChange.emit(event.node);
      } else if (event.eventName === "deactivate") {
        this.selectionChange.emit(null);
      }
    }, 10);
  }

  onSelectNode(event: any): void {
    if (event && event.node && event.node.data && event.node.data.id) {
      const id = event.node.data.id;
      if (!this.selectedNodes.includes(id)) {
        this.selectedNodes.push(id);
      }
      else {
        const index = this.selectedNodes.findIndex((item: any) => item === id);
        this.selectedNodes.splice(index, 1);
      }
    }
    const selectedNodes = this.selectedNodes && this.selectedNodes.length ? this.selectedNodes : null;
    this.selectedNodesChange.emit(selectedNodes);
  }
}
