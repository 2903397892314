import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class FamilyService {

  constructor(private api: ApiService) { }

  advanceSearchCheckConditions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/AdvanceSearch/CheckConditions');
  }

  advanceSearchGetConditions<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/AdvanceSearch/GetConditions/${ query }`);
  }

  advanceSearchSaveConditions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/AdvanceSearch/SaveConditions');
  }

  advanceSearchUpdateConditions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/AdvanceSearch/UpdateConditions');
  }

  advanceSearchDeleteConditions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/AdvanceSearch/DeleteConditions');
  }

  advanceSearchFilterSavedConditions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/AdvanceSearch/FilterSavedConditions');
  }

  attachmentTypeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/AttachmentType/Filter');
  }

  buildingTypeCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/BuildingType/Create');
  }

  buildingTypeUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/BuildingType/Update');
  }

  buildingTypeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/BuildingType/Delete');
  }

  buildingTypeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/BuildingType/Filter');
  }

  deathReasonCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/DeathReason/Create');
  }

  deathReasonUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/DeathReason/Update');
  }

  deathReasonDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/DeathReason/Delete');
  }

  deathReasonFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/DeathReason/Filter');
  }

  donorCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Donor/Create');
  }

  donorUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Donor/Update');
  }

  donorDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/Donor/Delete');
  }

  donorFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Donor/Filter');
  }

  educationGradeFilter<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Education/GradeFilter?${ query }`);
  }

  educationStudyFieldFilter<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Education/StudyFieldFilter?${ query }`);
  }

  familyCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Family/CreateUpdate');
  }

  familyDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/Family/Delete');
  }

  familyFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Family/Filter');
  }

  familyGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Family/Get/${ query }`);
  }

  familyGetIncomeAmount<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Family/GetIncomeAmount/${ query }`);
  }

  familyGetCostAmount<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Family/GetCostAmount?${ query }`);
  }

  familyGetByIdentifiers<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Family/GetByIdentifiers');
  }

  familyUpdateIdentifierNumbers<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Family/UpdateIdentifierNumbers');
  }

  familyUpdateSupervisorStatusAndTags<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Family/UpdateSupervisorStatusAndTags');
  }

  familyUpdatefoodIntakeStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Family/UpdatefoodIntakeStatus');
  }

  familyUpdateFamilyAgreements<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Family/UpdateFamilyAgreements');
  }

  familyGetFamilyCVQuery<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Family/GetFamilyCVQuery/${ query }`);
  }

  familyAssetCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyAsset/CreateUpdate');
  }

  familyAssetDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyAsset/Delete');
  }

  familyAssetFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyAsset/Filter');
  }

  familyAttachmentCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyAttachment/Create');
  }

  familyAttachmentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyAttachment/Delete');
  }

  familyAttachmentFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyAttachment/Filter');
  }

  familyCashAidCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyCashAid/CreateUpdate');
  }

  familyCashAidDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyCashAid/Delete');
  }

  familyCashAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyCashAid/Filter');
  }

  familyCostCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyCost/CreateUpdate');
  }

  familyCostDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyCost/Delete');
  }

  familyCostFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyCost/Filter');
  }

  familyMemberCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyMember/Create');
  }

  familyMemberUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/FamilyMember/Update');
  }

  familyMemberDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyMember/Delete');
  }

  familyMemberSetSupervisor<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/FamilyMember/SetSupervisor');
  }

  familyMemberGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyMember/Get/${ query }`);
  }

  familyMemberGetByFamily<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyMember/GetByFamily/${ query }`);
  }

  familyMemberGetByIdentifiers<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyMember/GetByIdentifiers');
  }

  familyMemberFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyMember/Filter');
  }

  familyMemberGetReceiveSponsorship<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyMember/GetReceiveSponsorship/${ query }`);
  }

  familyMemberAttachmentCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyMemberAttachment/Create');
  }

  familyMemberAttachmentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyMemberAttachment/Delete');
  }

  familyMemberAttachmentGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyMemberAttachment/Get/${ query }`);
  }

  familyObjectiveAidCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyObjectiveAid/CreateUpdate');
  }

  familyObjectiveAidDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyObjectiveAid/Delete');
  }

  familyObjectiveAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyObjectiveAid/Filter');
  }

  familyOtherIncomeCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyOtherIncome/CreateUpdate');
  }

  familyOtherIncomeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyOtherIncome/Delete');
  }

  familyOtherIncomeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyOtherIncome/Filter');
  }

  familyRegisterRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyRegisterRequest/Create');
  }

  familyRegisterRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/FamilyRegisterRequest/Update');
  }

  familyRegisterRequestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/FamilyRegisterRequest/SetStatus');
  }

  familyRegisterRequestSetStep<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/FamilyRegisterRequest/SetStep');
  }

  familyRegisterRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyRegisterRequest/Delete');
  }

  familyRegisterRequestGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyRegisterRequest/Get/${ query }`);
  }

  familyRegisterRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyRegisterRequest/Filter');
  }

  familyRelationTypeCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyRelationType/Create');
  }

  familyRelationTypeUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyRelationType/Update');
  }

  familyRelationTypeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyRelationType/Delete');
  }

  familyRelationTypeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyRelationType/Filter');
  }

  familyResidenceInfoCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyResidenceInfo/CreateUpdate');
  }

  familyResidenceInfoGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/FamilyResidenceInfo/Get/${ query }`);
  }

  familyResidenceInfoFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyResidenceInfo/Filter');
  }

  familyServiceAidCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyServiceAid/CreateUpdate');
  }

  familyServiceAidDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/FamilyServiceAid/Delete');
  }

  familyServiceAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/FamilyServiceAid/Filter');
  }

  geoNameCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/GeoName/Create');
  }

  geoNameUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/GeoName/Update');
  }

  geoNameDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/GeoName/Delete');
  }

  geoNameFilter<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/GeoName/Filter/${ query }`);
  }

  logGetLogCategories<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/Log/GetLogCategories/${ query }`);
  }

  logFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Log/Filter');
  }

  mukhtarCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Mukhtar/Create');
  }

  mukhtarUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/Mukhtar/Update');
  }

  mukhtarDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/Mukhtar/Delete');
  }

  mukhtarFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Mukhtar/Filter');
  }

  ownershipTypeCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/OwnershipType/Create');
  }

  ownershipTypeUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/OwnershipType/Update');
  }

  ownershipTypeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/OwnershipType/Delete');
  }

  ownershipTypeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/OwnershipType/Filter');
  }

  reasonNotIncludedCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/ReasonNotIncluded/Create');
  }

  reasonNotIncludedUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/ReasonNotIncluded/Update');
  }

  reasonNotIncludedDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/ReasonNotIncluded/Delete');
  }

  reasonNotIncludedFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/ReasonNotIncluded/Filter');
  }

  settingCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/Create');
  }

  settingFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/Filter');
  }

  settingGetSettingFormData<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/GetSettingFormData');
  }

  settingCreateRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/CreateRecord');
  }

  settingUpdateRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/UpdateRecord');
  }

  settingDeleteRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/DeleteRecord');
  }

  settingFilterSettingData<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/FilterSettingData');
  }

  settingFilterSettingDataWithSearch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/Setting/FilterSettingDataWithSearch');
  }

  userEducationCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducation/CreateUpdate');
  }

  userEducationDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserEducation/Delete');
  }

  userEducationGetByFamilyMember<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserEducation/GetByFamilyMember/${ query }`);
  }

  userEducationHistoryCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducationHistory/CreateUpdate');
  }

  userEducationHistoryDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserEducationHistory/Delete');
  }

  userEducationHistoryGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserEducationHistory/GetById/${ query }`);
  }

  userEducationHistoryFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducationHistory/Filter');
  }

  userEducationHistoryCreateAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducationHistory/CreateAttachment');
  }

  userEducationHistoryCreateUpdateLesson<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducationHistory/CreateUpdateLesson');
  }

  userEducationHistoryDeleteLesson<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserEducationHistory/DeleteLesson');
  }

  userEducationHistoryCreateUpdateFinancial<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserEducationHistory/CreateUpdateFinancial');
  }

  userEducationHistoryDeleteFinancial<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserEducationHistory/DeleteFinancial');
  }

  userHealthCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserHealth/CreateUpdate');
  }

  userHealthDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserHealth/Delete');
  }

  userHealthGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserHealth/Get/${ query }`);
  }

  userHospitalizationCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserHospitalization/CreateUpdate');
  }

  userHospitalizationDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserHospitalization/Delete');
  }

  userHospitalizationGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserHospitalization/Get/${ query }`);
  }

  userIllnessCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserIllness/CreateUpdate');
  }

  userIllnessDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserIllness/Delete');
  }

  userIllnessGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserIllness/Get/${ query }`);
  }

  userJobHistoryCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserJobHistory/CreateUpdate');
  }

  userJobHistoryDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserJobHistory/Delete');
  }

  userJobHistoryGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserJobHistory/Get/${ query }`);
  }

  userOtherInfoCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserOtherInfo/CreateUpdate');
  }

  userOtherInfoGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserOtherInfo/Get/${ query }`);
  }

  userOtherInfoCreateUpdateUserEntertainment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserOtherInfo/CreateUpdateUserEntertainment');
  }

  userOtherInfoCreateUpdateUserDeath<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserOtherInfo/CreateUpdateUserDeath');
  }

  userOtherInfoGetUserDeath<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserOtherInfo/GetUserDeath/${ query }`);
  }

  userOtherInfoDeleteUserDeath<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserOtherInfo/DeleteUserDeath');
  }

  userOtherInfoSetReligiousCommitment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Family/UserOtherInfo/SetReligiousCommitment');
  }

  userOtherInfoSetIdentificationDocumentInfo<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserOtherInfo/SetIdentificationDocumentInfo');
  }

  userSkillCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Family/UserSkill/CreateUpdate');
  }

  userSkillDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Family/UserSkill/Delete');
  }

  userSkillGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Family/UserSkill/Get/${ query }`);
  }

}
