import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'renderer'
})

export class CellRendererPipe implements PipeTransform {

  transform(value: any, args: any, field: string = '') {
    if (value.cellRendererParams) {
      if (typeof value.cellRendererParams === 'function') {
        return !field.includes('Name') ? value.cellRendererParams(args) : value.cellRendererParams(args).data[field];
      }
      return value.cellRendererParams[field];
    }
    return value.cellRenderer(args);
  }

}
