import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})

export class ProfileImageComponent implements OnInit {

  env = environment.api;
  file: any;
  url: string;
  @Input() src: string;
  @Input() disabled: boolean;
  @Output() fileSelect = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      if (['jpg', 'jpeg', 'png', 'jfif', 'gif'].includes(event.target.files[0].name.split('.')[1])) {
        const formData = new FormData();
        const fileReader = new FileReader();
        const file = (event.target as HTMLInputElement).files[0];
        const rand = Math.floor(Math.random() * 1000) + 1;
        formData.append('file', file, rand + file.name);
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.onload = (event) => { this.url = event.target.result as string };
        this.fileSelect.emit(formData);
      }
    }
  }

}
