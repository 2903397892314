<div #container class="{{(disabled||form?.disabled)?'disabled':''}}">

  <div *ngIf="!hasDropdown" [class.multi-select-leaf]="hasCheckbox" class="tree-panel">
    <div style="height: 500px">
      <tree-root #tree [(state)]="state" (select)="onSelectNode($event)" (deselect)="onSelectNode($event)" (stateChange)="onStateChange(tree)"
        (activate)="onActivate($event.node); onEvent($event)" (deactivate)="onDeactivate(); onEvent($event)" [nodes]="nodes" [options]="options" [focused]="true">
        <ng-template #treeNodeTemplate let-node let-index="index">
          <img *ngIf="iconLevel && node.level === iconLevel && node.data.icon" [src]="node.data.icon" width="24" height="24" alt="" class="" />
          <span> {{ (node.data[prefixDisplayField] ? node.data[prefixDisplayField] + ' - ' : '') + node.data[displayField] }} </span>
        </ng-template>
      </tree-root>
    </div>
  </div>

  <ng-container *ngIf="hasDropdown">
    <label *ngIf="label" class="form-label">
      {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span>
    </label>
    <div class="row tree-dropdown align-items-center gx-0 {{ classList }}">
      <button *ngIf="defaultTitle && !disabled && isClearable" (click)="clearTitle = true; onClear()" type="button"
        id="custom" class="btn btn-custom btn-close ms-auto">
      </button>
      <button (click)="clearTitle = false; onToggle($event)" type="button"
        class="accordion-button default text-start w-100 {{ acc.activeIds[0] === 'toggle-1' ? '' : 'collapsed' }}">
        <span class="btn-text"> {{ defaultTitle }} </span>
        <i class="bx bxs-down-arrow custom-arrow"> </i>
      </button>
      <ngb-accordion #acc="ngbAccordion" [class.border]="acc.activeIds[0] === 'toggle-1'" class="acc">
        <ngb-panel id="toggle-1" [disabled]="disabled||form?.disabled">
          <ng-template ngbPanelContent>

            <search-field *ngIf="hasSearch" (onSearch)="tree.treeModel.filterNodes($event)"
              placeholder="SERACH_BY_TITLE" class="flex-fill">
            </search-field>

            <tree-root #tree [(state)]="state" (stateChange)="onStateChange(tree)" (activate)="onActivate($event.node)"
                       (deactivate)="onDeactivate()" [nodes]="nodes" [options]="options" [focused]="true">
              <ng-template #treeNodeTemplate let-node let-index="index">
                <img *ngIf="iconLevel && node.level === iconLevel && node.data.icon" [src]="node.data.icon" width="24" height="24" alt="" class="" />
                <span> {{ (node.data[prefixDisplayField] ? node.data[prefixDisplayField] + ' - ' : '') + node.data[displayField] }} </span>
              </ng-template>
            </tree-root>

          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </ng-container>
  <div
    *ngIf="form&&((form.submitted && formGroup.controls[name].invalid) ||formGroup.controls[name].invalid && (formGroup.controls[name].dirty || formGroup.controls[name].touched))"
    class="invalid-feedback d-block">
    <div *ngIf="formGroup.controls[name].errors?.required"> {{label|translate}} {{ 'IS_REQUIRED'|translate }}
    </div>
  </div>

  <tree-root #tree2 [(state)]="state" [nodes]="nodes" class="d-none">
  </tree-root>
</div>
