import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { of, forkJoin } from 'rxjs';
import { map, concatMap, mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { PricePipe } from 'src/app/core/pipes/price.pipe';
import { GroupByPipe } from 'src/app/core/pipes/group-by.pipe';
import { AccountingService } from 'src/app/core/api/accounting.service';
import { AccountService } from 'src/app/core/api/account.service';
import { HrService } from 'src/app/core/api/hr.service';
import { PrintService } from 'src/app/core/services/print.service';
import { environment } from 'src/environments/environment';
import { RelationTypes, MaritalStatus, QualityStatuses } from 'src/app/core/models/enums';
import { User, UserCallNumberModel, UserSocialNetworkModel } from 'src/app/core/models/auth.models';
import { AddressModel } from 'src/app/core/models/public-model';
import { EmployeeFilterModel, EmployeeDependentModel } from 'src/app/core/models/hr/employee-model';
import { FamilyUserSkillInfo } from 'src/app/core/models/family-model';
import { BranchModel } from 'src/app/core/models/donation-financial-model';

interface PersonnelAllDataModel {
  info?: User;
  addressInfo?: { home?: AddressModel, work?: AddressModel };
  callNumbersData?: UserCallNumberModel[];
  socialNetworksData?: UserSocialNetworkModel[];
  // organizationInfo?: any;
  otherInfo?: any;
  dependantsData?: EmployeeDependentModel[];
  educationsData?: any[];
  certificatesData?: any[];
  jobTagInfo?: string;
  otherIncomeInfo?: number;
  jobsData?: any[];
  skillTagInfo?: string;
  skillsData?: any[];
  attachData?: any;
}

@Component({
  selector: 'personnel-print',
  templateUrl: 'personnel-print.component.html',
  styleUrls: ['personnel-print.component.scss']
})

export class PersonnelPrintComponent implements OnInit {

  env: string;
  selected: EmployeeFilterModel;
  personnel: PersonnelAllDataModel;
  // hasData: { CONTACT_INFO?: boolean, ORGANIZATIONAL_INFO?: boolean, DEPENDENTS?: boolean, EDUCATION_LICENSE_RECORDS?: boolean, JOB_RECORDS?: boolean, SKILLS?: boolean, ATTACH?: boolean };
  hasData: { CONTACT_INFO?: boolean, OTHER_INFO?: boolean, DEPENDENTS?: boolean, EDUCATION_LICENSE_RECORDS?: boolean, JOB_RECORDS?: boolean, SKILLS?: boolean, ATTACH?: boolean };
  printDate: string;
  loading: boolean;
  toastRef: any;

  constructor(private datePipe: DatePipe, private toastrService: ToastrService, public translatePipe: TranslatePipe, private pricePipe: PricePipe, private groupByPipe: GroupByPipe,
              private accountingService: AccountingService, private accountService: AccountService, private hrService: HrService, private printService: PrintService) {}

  ngOnInit(): void {
    this.env = environment.api;
    this.selected = this.printService.mainData;
    // this.hasData = { CONTACT_INFO: false, ORGANIZATIONAL_INFO: false, DEPENDENTS: false, EDUCATION_LICENSE_RECORDS: false, JOB_RECORDS: false, SKILLS: false, ATTACH: false };
    this.hasData = { CONTACT_INFO: false, OTHER_INFO: false, DEPENDENTS: false, EDUCATION_LICENSE_RECORDS: false, JOB_RECORDS: false, SKILLS: false, ATTACH: false };
    this.printDate = this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    this.loading = true;
    this.toastRef = this.toastrService.info(this.translatePipe.transform('PRINTING'), null, { timeOut: 3000, positionClass: 'toast-bottom-center' });
    const userIdentifier = this.selected.userIdentifier;
    forkJoin({
      branch: this.accountingService.branchFindBranch<BranchModel[]>({}).pipe(map((res) => res.data)),
      employee: this.hrService.employeeGetByUserIdentifier({}, userIdentifier).pipe(map((res) => res.data))
    }).pipe(mergeMap((pre) => {
      return forkJoin({
        info: this.accountService.userGetByIdentifier<User>({}, userIdentifier).pipe(map((res) => this.infoMapper(pre, res.data))),
        addressInfo: this.accountService.userGetAddresses<AddressModel[]>({ userIdentifier }).pipe(map((res) => res.data && res.data.length ? this.addressInfoMapper(pre, res.data) : null)),
        callNumbersData: this.accountService.userCallNumberGetByUserIdentifier<UserCallNumberModel[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? res.data : [{}])),
        socialNetworksData: this.accountService.userSocialNetworkGetByUserIdentifier<UserSocialNetworkModel[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? res.data : [{}])),
        otherInfo: this.hrService.employeeGetById<any[]>({}, pre.employee['id']).pipe(map((res) => res.data ? this.otherInfoMapper(pre, res.data) : [{}])),
        dependantsData: this.hrService.employeeDependantGetByEmployee<EmployeeDependentModel[]>({}, pre.employee['id']).pipe(map((res) => {
          return res.data && res.data.length ? this.dependantsDataMapper(pre, res.data) : [{}];
        })),
        educationsData: this.hrService.userEducationFilter<any[]>({ userIdentifier }).pipe(map((res) => res.data && res.data.length ? this.educationsDataMapper(pre, res.data) : [{}])),
        certificatesData: this.hrService.userCertificateFilter<any[]>({ userIdentifier }).pipe(map((res) => res.data && res.data.length ? res.data : [{}])),
        jobTagInfo: this.hrService.userJobGetUserJobTags<any[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? this.jobTagInfoMapper(pre, res.data) : null)),
        otherIncomeInfo: this.hrService.userGet<any>({}, userIdentifier).pipe(map((res) => res.data ? this.otherIncomeInfoMapper(pre, res.data) : null)),
        jobsData: this.hrService.userJobFilter<any[]>({ userIdentifier }).pipe(map((res) => {
          return res.data && res.data.length ? this.jobsDataMapper(pre, res.data) : [{}];
        }), concatMap((data) => {
          if (data.length && data[0].jobIds && data[0].jobIds.length) {
            return this.accountService.jobGetByIds({ ids: data[0].jobIds }).pipe(map((res: any) => {
              data.map((item: any) => item.job = res.data.find((job: any) => job.id === item.jobId)?.title);
              return data;
            }));
          }
          else {
            return of(data);
          }
        })),
        skillTagInfo: this.hrService.userSkillGetUserSkillTags<any[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? this.skillTagInfoMapper(pre, res.data) : null)),
        skillsData: this.hrService.userSkillFilter<any[]>({ userIdentifier }).pipe(map((res) => res.data && res.data.length ? this.skillsDataMapper(pre, res.data) : [{}])),
        attachData: this.hrService.employeeGetAttachments<any>({}, this.selected.employeeId).pipe(map((res) => res.data && res.data.length ? this.attachDataMapper(pre, res.data) : [{}]))
      }).pipe(map((data) => {
        /* const organizationInfo = pre.employee && pre.employee['id'] ? this.organizationInfoMapper(pre, {}) : null;
        this.personnel = { ...data, organizationInfo }; */
        this.personnel = { ...data };
        this.hasData = {
          CONTACT_INFO: data.addressInfo || !!(data.callNumbersData && data.callNumbersData.length) || !!(data.socialNetworksData && data.socialNetworksData.length),
          // ORGANIZATIONAL_INFO: this.personnel.organizationInfo && this.personnel.organizationInfo.fromDate,
          OTHER_INFO: this.personnel.otherInfo && this.personnel.otherInfo.mother,
          DEPENDENTS: !!(data.dependantsData && data.dependantsData.length),
          EDUCATION_LICENSE_RECORDS: !!(data.educationsData && data.educationsData.length) || !!(data.certificatesData && data.certificatesData.length),
          JOB_RECORDS: !!(data.jobTagInfo && data.jobTagInfo.length) || data.otherIncomeInfo || !!(data.jobsData && data.jobsData.length),
          SKILLS: !!(data.skillTagInfo && data.skillTagInfo.length) || !!(data.skillsData && data.skillsData.length),
          ATTACH: data.attachData && data.attachData.length
        };
        console.log(this.personnel);
        this.loading = false;
        this.toastrService.clear(this.toastRef.ToastId);
        setTimeout(() => window.print(), 1000);
      }));
    })).subscribe();
  }

  infoMapper(pre: any, data: any) {
    data.birthDate = this.datePipe.transform(data.birthDate, 'YYYY-MM-dd');
    data.idCardNumber = pre.employee.idCardNumber;
    data.entertainment = pre.employee.entertainment;
    data.exporter = pre.employee.exporter;
    return data;
  }

  addressInfoMapper(pre: any, data: any) {
    let addresses: any = {};
    addresses.home = data.find((item: AddressModel) => item.addressType === 0) || null;
    addresses.work = data.find((item: AddressModel) => item.addressType === 1) || null;
    return addresses;
  }

  /* organizationInfoMapper(pre: any, data: any) {
    data.department = pre.employee.department;
    data.organizationalPosition = pre.employee.organizationalPosition;
    data.degree = pre.employee.degree;
    data.branch = pre.branch.find((item: BranchModel) => item.id === pre.employee.branchId)?.title;
    data.fromDate = this.datePipe.transform(pre.employee.startDateOfWork, 'YYYY-MM-dd');
    data.toDate = this.datePipe.transform(pre.employee.endDateOfWork, 'YYYY-MM-dd');
    return data;
  } */

  otherInfoMapper(pre: any, data: any) {
    data.passportIssueDate = this.datePipe.transform(data.passportIssueDate, 'YYYY-MM-dd');
    data.passportExpiryDate = this.datePipe.transform(data.passportExpiryDate, 'YYYY-MM-dd');
    const currentDate: any = new Date();
    const passportExpiryDate: any = data.passportExpiryDate ? new Date(data.passportExpiryDate) : new Date();
    const differentDate = passportExpiryDate - currentDate;
    data.passportValidity = Math.ceil(differentDate / (1000 * 60 * 60 * 24));
    if (data.passportValidity && data.passportValidity > 0) {
      data.passportValidity += ' ' + this.translatePipe.transform('DAY');
    }
    else {
      data.passportValidity = this.translatePipe.transform('NOT_VALIDITY');
    }
    return data;
  }

  dependantsDataMapper(pre: any, data: any) {
    data.map((item: EmployeeDependentModel) => {
      item.relationTypeTitle = this.translatePipe.transform(RelationTypes[item.relationType]);
      item.birthDate = this.datePipe.transform(item.birthDate, 'YYYY-MM-dd');
      item.maritalStatusTitle = this.translatePipe.transform(MaritalStatus[item.maritalStatus]);
    });
    return data;
  }

  educationsDataMapper(pre: any, data: any) {
    data.map((item: any) => {
      item.startDate = this.datePipe.transform(item.startDate, 'YYYY-MM-dd');
      item.endDate = this.datePipe.transform(item.endDate, 'YYYY-MM-dd');
    });
    return data;
  }

  jobTagInfoMapper(pre: any, data: any) {
    let tagsAll = '';
    data.map((item: any) => tagsAll += item.tag + ', ');
    return tagsAll;
  }

  otherIncomeInfoMapper(pre: any, data: any) {
    data.otherIncome = this.pricePipe.transform(data.otherIncome);
    return data.otherIncome;
  }

  jobsDataMapper(pre: any, data: any) {
    const jobIds = [];
    data.map((item: any) => {
      if (item.jobId && !jobIds.includes(item.jobId)) {
        jobIds.push(item.jobId);
      }
    });
    data[0].jobIds = jobIds;
    return data;
  }

  skillTagInfoMapper(pre: any, data: any) {
    let tagsAll = '';
    data.map((item: any) => tagsAll += item.tag + ', ');
    return tagsAll;
  }

  skillsDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserSkillInfo) => {
      item.levelTitle = this.translatePipe.transform(QualityStatuses[item.level]);
    });
    return data;
  }

  attachDataMapper(pre: any, data: any) {
    let dataAlternative: any = [];
    const dataGroupByAttachmentType = this.groupByPipe.transform(data, 'attachmentType');
    dataGroupByAttachmentType.map((item, index) => {
      const temp = {};
      item.attachmentTypeTitle = item.key;
      item.count = item.value.length;
      Object.keys(item).forEach((key) => {
        if (key !== 'key' && key !== 'value') {
          temp[key] = item[key];
        }
      });
      dataAlternative[index] = temp;
    });
    dataAlternative = dataAlternative.filter((item: any) => item.count);
    return dataAlternative;
  }

}
