<div class="align-items-center gap-3 flex-wrap d-flex">
  <div *ngFor="let file of files; let i = index" class="img-preview">
    <img [src]="file?.domain ? (file.domain + file.url) : file.url" width="50%" height="50%" alt="" />
    <div class="icon-overlay">
      <div (click)="fileSelected = file; openModal(modal)" class="view"> <i class="fa fa-eye"> </i> </div>
      <div *ngIf="!disabled" (click)="onRemoveImage(file)" class="close"> <i class="bx bx-x"> </i> </div>
    </div>
  </div>
  <div (click)="!disabled ? profileImage.click() : ''" [class.disabled]="disabled" class="img-container">
    <i class="bx bx-plus"> </i>
    <input #profileImage (change)="onSelectFile($event)" type="file" name="profileImage" accept="image/jpg, image/jpeg, image/png, image/jfif, image/gif" id="profileImage" class="d-none" />
  </div>
</div>

<ng-template #modal let-modal>
  <div class="modal-header">
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </div>
  <div class="modal-body text-center">
    <figure class="figure my-4">
      <img [src]="fileSelected?.domain ? (fileSelected.domain + fileSelected.url) : fileSelected.url" width="400" height="400" alt="" class="img-fluid figure-img mb-0" />
    </figure>
  </div>
</ng-template>
<ng-template #confirmModal let-modal>
  <app-confirm-modal (confirm)="modal.dismiss('confirm')" [modalRef]="modal" color="danger" secondColor="c-3"> </app-confirm-modal>
</ng-template>
