import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from '@circlon/angular-tree-component';
import { Observable, of, forkJoin } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { AccountService } from 'src/app/core/api/account.service';
import { DonationService } from 'src/app/core/api/donation.service';
import { FamilyService } from 'src/app/core/api/family.service';
import { ToolsService } from 'src/app/core/services/tools.service';
import { FullNamePipe } from 'src/app/core/pipes/full-name.pipe';
import { Utils } from 'src/app/core/helpers/utils';
import { Ancestry, Gender, DonorTabs, GeoNameGroups } from 'src/app/core/models/enums';
import { User, UserCallNumberModel, UserSocialNetworkModel } from 'src/app/core/models/auth.models';
import { GeonameModel } from 'src/app/core/models/settings-model';
import { PublicModel, DonorModel, AddressModel } from 'src/app/core/models/public-model';
import { SETTING_IDS } from 'src/app/core/constants';
import { CallNumbersCols, SocialNetworksCols, ReferralsCols } from 'src/app/core/models/column-defs-model';

@Component({
  selector: 'app-donor-modal',
  templateUrl: 'donor-modal.component.html',
  styleUrls: ['donor-modal.component.scss']
})

export class DonorModalComponent implements OnInit {

  instituteForm: FormGroup;
  donorForm: FormGroup;
  donorAddressForm: FormGroup;
  donorWorkAddressForm: FormGroup;
  donorCallNumberForm: FormGroup;
  donorSocialNetworkForm: FormGroup;
  referralsForm: FormGroup;
  modalRef: NgbModalRef;
  settingIds = SETTING_IDS;
  institutes: PublicModel[];
  institutes$: Observable<PublicModel[]>;
  userPrefixes: PublicModel[];
  userPrefixes$: Observable<PublicModel[]>;
  callNumberTypes$: Observable<PublicModel[]>;
  socialNetworkTypes$: Observable<PublicModel[]>;
  jobs: PublicModel[];
  jobs$: Observable<PublicModel[]>;
  geoname$: Observable<GeonameModel[]>;
  ancestryItems = Ancestry;
  genderItems = Gender;
  tabs = DonorTabs;
  activeTab = DonorTabs.DONOR_INFO;
  callNumbersCols = CallNumbersCols;
  callNumbersData: UserCallNumberModel[] = [];
  socialNetworksCols = SocialNetworksCols;
  socialNetworksData: UserSocialNetworkModel[] = [];
  referralsCols = ReferralsCols;
  referralsData = [];
  donorDisabledTabs: number[];
  donorInfoCreated: { id: number; code: string };
  treeTitle: string = null;
  selectedNode: TreeNode;
  settingsParam: { identifier?: string, assign?: { id?: number, name?: string }};
  settingsDataSelected: any;
  isSettingsModal: boolean;
  settingsModalIsLoading: boolean;
  isDuplicatePhone: boolean;
  @Input() formData = {};
  @Input() branchId: number;
  @Input() departmentSelected: string = '';
  @Input() donorSelected: DonorModel = {};
  @Input() donationLength: number;
  @Output() dismissed = new EventEmitter;
  @ViewChild('departments') departments: ElementRef;
  @ViewChild('institute') institute: ElementRef;
  @ViewChild('donor') donor: ElementRef;
  @ViewChild('confirmModal') confirmModal: ElementRef;
  @ViewChild('deleteModal') deleteModal: ElementRef;

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private modal: NgbModal, private api: ApiService, private accountService: AccountService,
    private donationService: DonationService, private familyService: FamilyService, private toolsService: ToolsService, private fullNamePipe: FullNamePipe) {
    this.instituteForm = this.formBuilder.group({
      instituteId: [null, Validators.required],
      lastName: '',
      agentUserIdentifier: null
    });
    this.donorForm = this.formBuilder.group({
      userIdentifier: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: null,
      email: null,
      jobId: [null, Validators.required],
      userPrefixId: null,
      ancestry: [null, Validators.required],
      gender: [null, Validators.required],
      maritalStatus: null,
      birthDate: new Date(),
      agentUserIdentifier: null,
      description: null
    });
    this.donorAddressForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      name: null,
      addressType: [null, Validators.required],
      address: null,
      latitude: null,
      longitude: null,
      geoNameId: [null, Validators.required],
      geoName: null
    });
    this.donorWorkAddressForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      name: null,
      addressType: [null, Validators.required],
      address: null,
      latitude: null,
      longitude: null,
      geoNameId: [null, Validators.required],
      geoName: null
    });
    this.donorCallNumberForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      callNumber: [null, Validators.required],
      callNumberTypeId: [null, Validators.required]
    });
    this.donorSocialNetworkForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      networkId: [null, Validators.required],
      socialNetworkTypeId: [null, Validators.required]
    });
    this.referralsForm = this.formBuilder.group({
      userIdentifier: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: null,
      jobId: null,
      userPrefixId: null,
      ancestry: null,
      gender: null,
      maritalStatus: null,
      birthDate: new Date()
    });
    this.donorDisabledTabs = [DonorTabs.CONTACT_INFO, DonorTabs.REFERRALS];
    this.donorInfoCreated = { id: 0, code: null };
    this.settingsParam = { identifier: '', assign: { id: null, name: '' }};
    this.isSettingsModal = this.settingsModalIsLoading = this.isDuplicatePhone = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const agentUserIdentifier = this.formData['agentUserIdentifier'] || this.toolsService.getUser()?.identifier;
    this.instituteForm.controls['agentUserIdentifier'].setValue(agentUserIdentifier);
    this.donorForm.controls['agentUserIdentifier'].setValue(agentUserIdentifier);
    forkJoin({
      institute: this.institutes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.institutesTree).pipe(map((data) => {
        data.map((items: any) => !items.InstituteGroupId ? items.Id *= 10000 : items.InstituteGroupId *= 10000);
        this.institutes = JSON.parse(JSON.stringify(data));
        data = Utils.convertAccountToTree(data, null, 'InstituteGroupId', 'Id');
        data = data.filter((item: any) => item.children.length);
        return data;
      }), shareReplay()),
      userPrefix: this.userPrefixes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.userPrefixes).pipe(map((data) => this.userPrefixes = data)),
      callNumberType: this.callNumberTypes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.callNumberTypes),
      socialNetworkType: this.socialNetworkTypes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.socialNetworkTypes),
      // job: this.getJobs(),
      geoName: this.getGeoName()
    }).pipe(map(() => {
      if (this.donorSelected && !this.donorSelected.isLegal) {
        this.openModal(this.donor);
        this.donorDisabledTabs = [];
        this.donorInfoCreated = { id: this.donorSelected.id, code: this.donorSelected.donorCode };
        this.departmentSelected = 'donor';
        this.accountService.userGetByIdentifier<User>({}, this.donorSelected.userId).pipe(map((res) => {
          this.donorForm.patchValue({
            ...res.data,
            userIdentifier: res.data.identifier,
            ancestry: res.data.ancestry,
            gender: res.data.gender || null,
            birthDate: this.datePipe.transform(res.data.birthDate, 'YYYY-MM-dd'),
            phone: res.data.phone && res.data.phone.indexOf('d_') === -1 ? res.data.phone : null,
            description: this.donorSelected.description
          });
        }), concatMap(() => this.getJobs()), shareReplay()).subscribe();
        this.getAddress();
        this.getCallNumbers();
        this.getSocialNetworks();
        if (this.donorSelected.referers && this.donorSelected.referers.length) {
          let refererIdentifiers = [];
          this.donorSelected.referers.map((item) => refererIdentifiers.push(item['userIdentifier']));
          this.accountService.userGetByIdentifiers<any[]>({ identifiers: refererIdentifiers }).pipe(map((res) => {
            res.data.map((item) => {
              item.userIdentifier = item.identifier;
              item.fullName = (item?.userPrefix || '') + ' ' + this.fullNamePipe.transform(item);
            });
            this.referralsData = res.data;
          }), shareReplay()).subscribe();
        }
      }
      else if (this.donorSelected && this.donorSelected.isLegal) {
        this.donorInfoCreated = { id: this.donorSelected.id, code: this.donorSelected.donorCode };
        this.settingsDataSelected = { Id: this.donorSelected.instituteId, Name: this.donorSelected.fullName, Phone: this.donorSelected.phone };
        this.departmentSelected = 'institute';
        this.OpenSettingsModal(SETTING_IDS.institutes);
      }
      else {
        this.getJobs().subscribe();
        this.openModal(this.departmentSelected ? (this.departmentSelected === 'donor' ? this.donor : this.OpenSettingsModal(this.settingIds.institutes)) : this.departments);
      }
    })).subscribe();
  }

  openModal(content: any, size = 'lg', name?: string): void {
    this.modalRef = this.modal.open(content, { size: size, centered: true });
    this.modalRef.result.then(() => this.resetAll(name), () => this.resetAll(name));
  }

  OpenSettingsModal(identifier?: string, assignId?: number, assignName?: string): void {
    this.settingsParam = { identifier , assign: { id: assignId, name: assignName }};
    this.isSettingsModal = true;
  }

  settingsModalDismissed(recordId: number): void {
    this.donorInfoCreated.id = recordId;
    this.isSettingsModal = false;
    !this.donorSelected ? this.modalRef.dismiss() : this.dismissed.emit({ [this.departmentSelected + 'Id']: this.donorInfoCreated.id });
  }

  resetAll(name?: string): void {
    if (name !== 'referrals') {
      this.dismissed.emit({ [this.departmentSelected + 'Id']: this.donorInfoCreated.id });
      this.modal.dismissAll();
    }
    else {
      Utils.resetForm(this.referralsForm);
      this.isDuplicatePhone = false;
    }
  }

  onCallNumberActionClick(event: any): void {
    switch (event.action) {
      case 'edit': this.donorCallNumberForm.patchValue(event.row); break;
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            this.accountService.userCallNumberDelete({ id: event.row.id }).pipe(map(() => this.getCallNumbers())).subscribe();
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onSocialNetworkActionClick(event: any): void {
    switch (event.action) {
      case 'edit': this.donorSocialNetworkForm.patchValue(event.row); break;
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            this.accountService.userSocialNetworkDelete({ id: event.row.id }).pipe(map(() => this.getSocialNetworks())).subscribe();
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onReferralActionClick(event: any): void {
    switch (event.action) {
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            return this.deleteReferral(event.row.userIdentifier).subscribe(() => {
              const data = [...this.referralsData];
              data.splice(event.rowIndex, 1);
              this.referralsData = data;
            });
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onLinkButton(name: string): void {
    switch (name) {
      case 'userPrefixes': window.open('/settings/dynamic/' + SETTING_IDS.userPrefixes + '/grid', '_blank'); break;
      case 'callNumberTypes': window.open('/settings/dynamic/' + SETTING_IDS.callNumberTypes + '/grid', '_blank'); break;
      case 'socialNetworkTypes': window.open('/settings/dynamic/' + SETTING_IDS.socialNetworkTypes + '/grid', '_blank'); break;
      case 'jobs': window.open('/settings/dynamic/' + SETTING_IDS.jobs + '/tree', '_blank'); break;
      case 'geoName': window.open('/settings/family-geoname', '_blank'); break;
      default: break;
    }
  }

  onChangeInstituteId(event: any): void {
    if (event && event.data) {
      this.treeTitle = event.data.Name;
      this.settingsDataSelected = { ...event.data, InstituteGroupId: event.data.InstituteGroupId / 10000 };
    }
    else {
      this.instituteForm.controls['instituteId'].setValue(null);
      this.settingsDataSelected = {};
    }
  }

  filterSettings<T>(identifier?: string) {
    return this.api.filterSettings<T>({ identifier }).pipe(map((res) => res.data), shareReplay(), catchError((err) => of(err)));
  }

  getJobs() {
    return this.jobs$ = this.filterSettings<PublicModel[]>(SETTING_IDS.jobsTree).pipe(map((data) => {
      this.jobs = data;
      data = Utils.convertAccountToTree(data, null, 'ParentId', 'Id');
      return data;
    }), shareReplay());
  }

  getAddress(): void {
    this.accountService.userGetAddresses<AddressModel[]>({ userIdentifier: this.donorSelected.userId }).pipe(map((res) => {
      if (res.data.length) {
        this.donorAddressForm.patchValue(res.data.filter((item) => item.addressType === 0)[0]);
        this.donorWorkAddressForm.patchValue(res.data.filter((item) => item.addressType === 1)[0]);
      }
    }), shareReplay()).subscribe();
  }

  getGeoName(name?: string) {
    const param = name && name.length ? { name } : {};
    return this.geoname$ = this.familyService.geoNameFilter<GeonameModel[]>({ ...param, geoNameGroup: GeoNameGroups.FAMILY }).pipe(map((res) => res.data), shareReplay());
  }

  getCallNumbers() {
    this.accountService.userCallNumberGetByUserIdentifier<UserCallNumberModel[]>({}, this.donorSelected.userId).pipe(map((res) => {
      this.callNumbersData = res.data;
      return res.data;
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  getSocialNetworks() {
    this.accountService.userSocialNetworkGetByUserIdentifier<UserSocialNetworkModel[]>({}, this.donorSelected.userId).pipe(map((res) => {
      this.socialNetworksData = res.data;
      return res.data;
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  instituteSubmit() {
    if (this.instituteForm.invalid) {
      return false;
    }
    const instituteSelected: any = this.institutes.find((item) => item.Id === this.instituteForm.value.instituteId);
    const param = {
      ...this.instituteForm.value,
      firstName: instituteSelected.Name,
      phone: instituteSelected.Phone,
      accountParty: instituteSelected.AccountParty,
      ancestry: instituteSelected.Ancestry,
      gender: instituteSelected.Gender,
      jobId: instituteSelected.JobId,
      email: instituteSelected.Email,
      branchId: this.branchId
    };
    this.donationService.donorCreate(param).pipe(map((res) => {
      this.donorInfoCreated.id = res.data['id'];
      this.modalRef.dismiss();
    })).subscribe();
  }

  donorInfoSubmit() {
    if (this.donorForm.invalid) {
      return false;
    }
    const phone = this.donorForm.value.phone || 'd_' + this.toolsService.createGuid();
    if (!this.donorInfoCreated.code) {
      const userExistsParam = { phone, firstName: this.donorForm.value.firstName, lastName: this.donorForm.value.lastName, role: 4 };
      this.accountService.userUserExists(userExistsParam).pipe(map((resUserExists) => resUserExists), concatMap((resUserExists) => {
        if (!resUserExists.data['identifier']) {
          return this.accountService.accountApiCreateDonor({ ...this.donorForm.value, phone }).pipe(map((resCreateDonor) => {
            this.donorForm.controls['userIdentifier'].setValue(resCreateDonor.data['userIdentifier']);
          }), concatMap(() => {
            return this.donationService.donorCreate({ ...this.donorForm.value, branchId: this.branchId, phone }).pipe(map((resDonorCreate) => this.donorInfoComplete(resDonorCreate.data)));
          }));
        }
        else {
          return this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((resConfirm) => resConfirm), concatMap((resConfirm) => {
            if (resConfirm === 'confirm') {
              this.donorForm.controls['userIdentifier'].setValue(resUserExists.data['identifier']);
              return this.accountService.userUpdateProfile({ ...this.donorForm.value, phone }).pipe(map(() => {}), concatMap(() => {
                if (!resUserExists.data['roleExists']) {
                  return this.accountService.userRoleCreate({ userIdentifier: resUserExists.data['identifier'], role: 4 }).pipe(map(() => {}), concatMap(() => {
                    return this.donationService.donorCreate({ ...this.donorForm.value, branchId: this.branchId, phone }).pipe(map((resDonorCreate) => this.donorInfoComplete(resDonorCreate.data)));
                  }));
                }
                else {
                  return this.donationService.donorFilter({ userId: resUserExists.data['identifier'] }).pipe(map((resDonorFilter) => {
                    this.donorInfoComplete(resDonorFilter.data[0]);
                    this.getAddress();
                    if (this.donorSelected.referers && this.donorSelected.referers.length) {
                      let refererIdentifiers = [];
                      this.donorSelected.referers.map((item) => refererIdentifiers.push(item['userIdentifier']));
                      this.accountService.userGetByIdentifiers<any[]>({ identifiers: refererIdentifiers }).pipe(map((res) => {
                        res.data.map((item) => {
                          item.userIdentifier = item.identifier;
                          item.fullName = (item?.userPrefix || '') + ' ' + this.fullNamePipe.transform(item);
                        });
                        this.referralsData = res.data;
                      }), shareReplay()).subscribe();
                    }
                  }));
                }
              }));
            }
            else {
              this.donorForm.controls['phone'].setValue(null);
              return of([]);
            }
          }));
        }
      })).subscribe();
    }
    else {
      const param = { ...this.donorForm.value, payload: { description: this.donorForm.value.description }, phone };
      this.accountService.userUpdateProfile(param).pipe(map(() => this.activeTab = DonorTabs.CONTACT_INFO)).subscribe();
    }
  }

  donorInfoComplete(donor: DonorModel): void {
    this.activeTab = DonorTabs.CONTACT_INFO;
    this.donorDisabledTabs = [];
    this.donorInfoCreated = { id: donor.id, code: donor.donorCode };
    this.donorSelected = donor;
  }

  donorAddressInfoSubmit(panelId: any) {
    this.donorAddressForm.controls['userIdentifier'].setValue(this.donorForm.value.userIdentifier);
    this.donorAddressForm.controls['addressType'].setValue(0);
    if (this.donorAddressForm.invalid) {
      return false;
    }
    if (!this.donorAddressForm.value.id) {
      return this.accountService.userAddAddress(this.donorAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-1');
      })).subscribe();
    }
    else {
      return this.accountService.userUpdateAddress(this.donorAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-1');
      })).subscribe();
    }
  }

  donorWorkAddressInfoSubmit(panelId: any) {
    this.donorWorkAddressForm.controls['userIdentifier'].setValue(this.donorForm.value.userIdentifier);
    this.donorWorkAddressForm.controls['addressType'].setValue(1);
    if (this.donorWorkAddressForm.invalid) {
      return false;
    }
    if (!this.donorWorkAddressForm.value.id) {
      return this.accountService.userAddAddress(this.donorWorkAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-2');
      })).subscribe();
    }
    else {
      return this.accountService.userUpdateAddress(this.donorWorkAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-2');
      })).subscribe();
    }
  }

  donorCallNumberInfoSubmit(form: any) {
    this.donorCallNumberForm.controls['userIdentifier'].setValue(this.donorForm.value.userIdentifier);
    if (this.donorCallNumberForm.invalid) {
      return false;
    }
    if (!this.donorCallNumberForm.value.id) {
      return this.accountService.userCallNumberCreate(this.donorCallNumberForm.value).pipe(map(() => {
        this.getCallNumbers();
        form.resetForm();
      })).subscribe();
    }
    else {
      return this.accountService.userCallNumberUpdate(this.donorCallNumberForm.value).pipe(map(() => {
        this.getCallNumbers();
        form.resetForm();
      })).subscribe();
    }
  }

  donorSocialNetworkInfoSubmit(form: any) {
    this.donorSocialNetworkForm.controls['userIdentifier'].setValue(this.donorForm.value.userIdentifier);
    if (this.donorSocialNetworkForm.invalid) {
      return false;
    }
    if (!this.donorSocialNetworkForm.value.id) {
      return this.accountService.userSocialNetworkCreate(this.donorSocialNetworkForm.value).pipe(map(() => {
        this.getSocialNetworks();
        form.resetForm();
      })).subscribe();
    }
    else {
      return this.accountService.userSocialNetworkUpdate(this.donorSocialNetworkForm.value).pipe(map(() => {
        this.getSocialNetworks();
        form.resetForm();
      })).subscribe();
    }
  }

  referralInfoSubmit() {
    this.isDuplicatePhone = false;
    if (this.referralsData && this.referralsData.length && this.referralsForm.value.phone) {
      this.referralsData.find((item) => {
        if (!this.isDuplicatePhone) {
          this.isDuplicatePhone = item.phone === this.referralsForm.value.phone;
        }
      });
    }
    if (this.referralsForm.invalid || this.isDuplicatePhone) {
      return false;
    }
    const userExistsParam = { phone: this.referralsForm.value.phone, firstName: this.referralsForm.value.firstName, lastName: this.referralsForm.value.lastName, role: 6 };
    this.accountService.userUserExists(userExistsParam).pipe(map((resUserExists) => resUserExists), concatMap((resUserExists) => {
      if (!resUserExists.data['identifier']) {
        return this.accountService.accountApiCreateReffer(this.referralsForm.value).pipe(map((resCreateReffer) => {
          this.referralsForm.controls['userIdentifier'].setValue(resCreateReffer.data['userIdentifier']);
        }), concatMap(() => {
          const param = { donorId: this.donorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
          return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
        }));
      }
      else {
        return this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((resConfirm) => resConfirm), concatMap((resConfirm) => {
          if (resConfirm === 'confirm') {
            this.referralsForm.controls['userIdentifier'].setValue(resUserExists.data['identifier']);
            return this.accountService.userUpdateProfile(this.referralsForm.value).pipe(map(() => {}), concatMap(() => {
              if (!resUserExists.data['roleExists']) {
                return this.accountService.userRoleCreate({ userIdentifier: resUserExists.data['identifier'], role: 6 }).pipe(map(() => {}), concatMap(() => {
                  const param = { donorId: this.donorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
                  return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
                }));
              }
              else {
                const param = { donorId: this.donorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
                return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
              }
            }));
          }
          else {
            this.referralsForm.controls['phone'].setValue(null);
            return of([]);
          }
        }));
      }
    })).subscribe();
  }

  referralInfoComplete(): void {
    const userPrefix = this.userPrefixes.find((item) => item['Id'] === this.referralsForm.value.userPrefixId)?.['Title'];
    this.referralsData.push(this.referralsForm.value);
    this.referralsData[this.referralsData.length - 1].fullName = (userPrefix || '') + ' ' + this.fullNamePipe.transform(this.referralsForm.value);
    this.referralsData[this.referralsData.length - 1].job = this.jobs.find((item) => item.Id === this.referralsForm.value.jobId)?.Title;
    this.referralsData = this.referralsData.slice();
    Utils.resetForm(this.referralsForm);
    this.modalRef.dismiss();
  }

  deleteReferral(userIdentifier: string) {
    const param = { donorId: this.donorInfoCreated.id, refererUserIdentifier: userIdentifier };
    return this.donationService.donorDeleteReferer(param).pipe(map(() => {}));
  }

}
