import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'prettyJson' })

export class PrettyJsonPipe implements PipeTransform {

  constructor() { }

  transform(json: Object) {
    return JSON.stringify(json, undefined, 4).replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
  }

}
