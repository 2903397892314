import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <span>
      <div
        *ngIf="params['dropdownItems'] && params['dropdownItems'].length"
        ngbDropdown
        [placement]="placement"
        container="body"
      >
        <button
          type="button"
          ngbDropdownToggle
          class="btn align-items-center justify-content-center d-flex"
          style="width: 35px; height: 35px; background-color: #E1E1E1"
        >
          <i class="bx bx-dots-vertical color-blue"> </i>
        </button>
        <div ngbDropdownMenu>
          <button
            *ngFor="let item of params['dropdownItems']"
            ngbDropdownItem
            (click)="invock(item)"
            class="align-items-center d-flex"
            [style.max-height.px]="35"
          >
            {{ item|translate }}
          </button>
        </div>
      </div>
    </span>
    <!-- <div ngbDropdown container="body" placement="bottom-right">
        <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle id="navbarDropdown2">test</button>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown2" >
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Action</a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Another action</a>
          <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Something else here</a>
        </div>
      </div> -->
  `,
})
export class DropdownButtonRenderer implements ICellRendererAngularComp {
  rtl = true; // set RTL
  public params!: ICellRendererParams;
  button: any;
  placement = this.rtl ? "bottom-left" : "bottom-right";
  dropdownItems: string[] = [];

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public invock(action: string): void {
    this.params.context.componentParent.actionsGrid({
      row: this.params.data,
      action: action.toLowerCase(),
      rowIndex: this.params.rowIndex
    });
  }

  refresh(): boolean {
    return false;
  }
}
