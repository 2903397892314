import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ToolsService } from 'src/app/core/services/tools.service';

@Component({
  selector: 'app-simple-input',
  templateUrl: 'simple-input.component.html',
  styleUrls: ['simple-input.component.scss']
})

export class SimpleInputComponent implements OnInit, AfterContentInit {

  formGroup: FormGroup;
  phoneFocused: boolean;
  @Input() form: FormGroupDirective;
  @Input() type: string;
  @Input() inputType: string;
  @Input() ids: string;
  @Input() label: string;
  @Input() name: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() rows: number;
  @Input() ltr: boolean;
  @Input() mask: string;
  @Input() thousandSeparator: string;
  @Input() classList: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() submitted: boolean;
  @Output() valueChange = new EventEmitter();
  @ViewChild('input') input: ElementRef;

  constructor(public tools: ToolsService) {
    this.phoneFocused = false; this.ltr = false; this.required = false; this.disabled = false; this.submitted = false;
    this.type = 'text'; this.inputType = 'text';
    this.ids = ''; this.label = ''; this.name = ''; this.value = ''; this.placeholder = ''; this.mask = ''; this.thousandSeparator = ''; this.classList = '';
    this.rows = 4;
  }

  get formControl(): any {
    return this.formGroup.controls[this.name];
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
    }
  }

  onPhoneChange(): void {
    let priceConverted = (this.input.nativeElement as HTMLInputElement).value;
    priceConverted = this.tools.convertFaToEnDigits(priceConverted);
    this.formGroup.controls[this.name].setValue(priceConverted || null);
    this.valueChange.emit(priceConverted || null);
  }

  onPhoneTglFocus(formControl: any): void {
    this.phoneFocused = formControl.value ? true : !this.phoneFocused;
  }

  isInvalid(): boolean {
    return this.formGroup.controls[this.name].invalid && (this.formGroup.controls[this.name].touched || this.formGroup.controls[this.name].dirty);
  }

  getErrorText(): string {
    const control = this.formGroup.controls[this.name];
    if (control.errors.required || control.errors.whitespace) {
      return `IS_REQUIRED`;
    }
    if (control.errors.email) {
      return `INVALID_EMAIL`;
    }
    if (control.errors.mask) {
      return `IS_INVALID`;
    }
    if (control.errors.custom) {
      return control.errors.custom;
    }
  }

}
