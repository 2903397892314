import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Injectable,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n, NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";

const MONTHS = ['1-Jan', '2-Feb', '3-Mar', '4-Apr', '5-May', '6-Jun', '7-Jul', '8-Aug', '9-Sep', '10-Oct', '11-Nov', '12-Dec'];
const WEEKDAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getMonthShortName = (month: number): string => MONTHS[month - 1];
  getMonthFullName = (month: number): string => MONTHS[month - 1];
  getWeekdayLabel = (weekday: number): string => WEEKDAYS[weekday - 1];
  getDayAriaLabel = (date: NgbDateStruct): string => `${ date.year }-${ date.month }-${ date.day }`;
}

@Component({
  selector: "app-range-datepicker",
  templateUrl: "./range-datepicker.component.html",
  styleUrls: ["./range-datepicker.component.scss"],
  providers: [{ provide: NgbDatepickerI18n, useClass: IslamicI18n }],
})
export class RangeDatepickerComponent
  implements OnInit, OnChanges, AfterContentInit
{
  formGroup: FormGroup;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate | null = null;
  @Input() form: FormGroupDirective;
  @Input() label = "";
  @Input() hasValue = false;
  @Input() start: string;
  @Input() end: string;
  @Input() isClearable = true;
  @Input() disable = false;
  @Input() classList = "";
  @Output() dateSelected = new EventEmitter();
  @ViewChild('datepicker') datepicker: any;

  constructor(
    private datePipe: DatePipe,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!this.hasValue) {
      this.fromDate = this.toDate = null;
    }
  }

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
      const methods = { equals: () => null, before: () => null, after: () => null };
      if (this.formGroup.value[this.start]) {
        const from = this.datePipe.transform(this.formGroup.value[this.start], 'YYYY-MM-dd').split('-');
        this.fromDate = { ...methods, year: +from[0], month: +from[1], day: +from[2] };
      }
      if (this.formGroup.value[this.end]) {
        const to = this.datePipe.transform(this.formGroup.value[this.end], 'YYYY-MM-dd').split('-');
        this.toDate = { ...methods, year: +to[0], month: +to[1], day: +to[2] };
      }
    }
  }

  getStandardDate(date: NgbDate) {
    return new Date(this.formatter.format(date)).toISOString();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  clearFromDateClick(): void {
    this.fromDate = this.toDate = null;
    this.formGroup.controls[this.start].setValue(null);
    this.formGroup.controls[this.end].setValue(null);
    this.dateSelected.emit({ fromDate: null, toDate: null });
  }

  clearToDateClick(): void {
    this.toDate = null;
    this.formGroup.controls[this.end].setValue(null);
    this.dateSelected.emit({
      fromDate:
        this.fromDate !== null ? this.getStandardDate(this.fromDate) : null,
      toDate: null,
    });
  }

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.formGroup.controls[this.start].setValue(
        this.getStandardDate(this.fromDate)
      );
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.formGroup.controls[this.end].setValue(
        this.getStandardDate(this.toDate)
      );
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.formGroup.controls[this.end].setValue(null);
      this.formGroup.controls[this.start].setValue(
        this.getStandardDate(this.fromDate)
      );
    }
    if (this.fromDate && this.toDate) {
      const from = this.getStandardDate(this.fromDate);
      const to = this.getStandardDate(this.toDate);
      this.dateSelected.emit({ fromDate: from, toDate: to });
    } else {
      // this.dateSelected.emit();
    }
  }

  onNavigate(event: any, today?: boolean) {
    if (!today && !event.current) return;
    if (today) {
      this.datepicker.navigateTo(this.calendar.getToday());
      event = { current: this.calendar.getToday(), next: this.calendar.getToday() };
    }
    let lastDay: number = 30;
    switch (event.next.month) {
      case 1: case 3: case 5: case 7: case 8: case 10: case 12: lastDay = 31; break;
      case 2: lastDay = 29; break;
    }
    this.fromDate = { ...event.next, day: 1 }; this.toDate = { ...event.next, day: lastDay };
    const from = this.getStandardDate(this.fromDate); const to = this.getStandardDate(this.toDate);
    this.formGroup.controls[this.start].setValue(from); this.formGroup.controls[this.end].setValue(to);
    this.dateSelected.emit({ [this.start]: from, [this.end]: to });
  }
}
