import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { ToolsService } from "src/app/core/services/tools.service";
import { ApiService } from "src/app/core/services/api.service";
import { UserModel } from "src/app/core/models/public-model";
import { BranchModel } from "src/app/core/models/donation-financial-model";

interface LangModel {
  id?: number;
  text?: string;
  flag?: string;
  lang?: string;
}

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  user: UserModel;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    public tools: ToolsService,
    private api: ApiService
  ) {}

  langList = [
    // { id: 1, text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { id: 2, text: "العربی", flag: "assets/images/flags/iraq.png", lang: "ar" },
    { id: 3, text: "فارسی", flag: "assets/images/flags/iran.png", lang: "ir" },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];
  selectedLang: LangModel;
  env = environment.api;
  openMobileMenu: boolean;
  branches: BranchModel[];
  selectedBranch: BranchModel;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.branches = this.tools.getBranches();
    this.selectedBranch = this.tools.getSelectedBranch();
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.langList.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    const langId = +localStorage.getItem("langId");
    if (langId) {
      this.selectedLang = this.langList.find((l) => l.id === langId);
    } else {
      this.selectedLang = this.langList[0];
      localStorage.setItem("langId", this.selectedLang.id.toString());
    }

    this.user = this.tools.getUser();
    // this.getBranches();
  }

  setLanguage(item: LangModel) {
    // this.countryName = text;
    // this.flagvalue = flag;
    // this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
    if (item.id === this.selectedLang.id) {
      return;
    }
    localStorage.setItem("langId", item.id.toString());
    location.reload();
  }

  // getBranches() {
  //   this.branches$ = this.api
  //     .post<any[]>({}, "/v3/Branch/FindBranch", "accounting")
  //     .pipe(map((m) => m.data));
  // }

  // setBranch(event) {
  //   localStorage.setItem("branch", event.id);
  // }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.tools.setColWidth();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultAuth === "firebase") {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  reload (): void {
    window.location.reload();
    window.location.href = '/';
  }
}
