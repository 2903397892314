import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-total-time-renderer",
  template: `<span>
    {{ totalTime }}
  </span>`,
})
export class TotalTimeRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  totalTime = "";
  hours = "";
  minutes = "";
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.totalTime = this.params?.data[this.params.colDef.field]?.totalHours;
    if (!this.totalTime) {
      return;
    }
    this.hours = this.totalTime.split(":")[0];
    this.minutes = this.totalTime.split(":")[1];
    if (this.hours.length === 1) {
      this.hours = `0${this.hours}`;
    }
    if (this.minutes.length === 1) {
      this.minutes = `0${this.minutes}`;
    }
    this.totalTime = `${this.hours}:${this.minutes}`;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
