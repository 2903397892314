import { Component, OnInit, AfterContentChecked, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToolsService } from 'src/app/core/services/tools.service';
import { CurrencyTypeModel } from 'src/app/core/models/donation-financial-model';

@Component({
  selector: 'price-input',
  templateUrl: 'price-input.component.html',
  styleUrls: ['price-input.component.scss']
})

export class PriceInputComponent implements OnInit, AfterContentChecked, OnDestroy {

  formGroup: FormGroup;
  subscriptions: Subscription;
  priceConverted: string;
  @Input() form: FormGroupDirective;
  @Input() ids: string;
  @Input() label: string;
  @Input() bindLabel: string;
  @Input() name: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() buttonForm: string;
  @Input() buttonBindValue: string;
  @Input() dropdownItems: CurrencyTypeModel[];
  @Input() dropdownItemSelected: CurrencyTypeModel;
  @Input() dropdownDisabled: boolean;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter();
  @Output() dropdownItemClicked = new EventEmitter();
  @Output() dropdownItemSelectedChange = new EventEmitter();
  @ViewChild('inputRef') inputRef: ElementRef;

  constructor(private pricePipe: DecimalPipe, public tools: ToolsService) {
    this.priceConverted = ''; this.ids = ''; this.label = ''; this.name = ''; this.value = ''; this.placeholder = '';
    this.dropdownDisabled = false; this.required = false; this.disabled = false;
    this.bindLabel = 'shortKey';
    this.buttonBindValue = 'id';
    this.dropdownItems = [];
  }

  ngOnInit(): void {
    if (this.form) {
      this.subscriptions = this.form.statusChanges.pipe(map(() => {
        !this.formGroup?.value[this.name] ? this.priceConverted = '' : null;
      })).subscribe();
      if (this.form.form?.value[this.name] || this.form.form?.value[this.name] === 0 || this.form.form?.value[this.name] === '0') {
        const value = this.form.form?.value[this.name];
        const valueConverted = typeof value === 'string' ? value.replaceAll(',', '') : value;
        this.priceConverted = this.pricePipe.transform(valueConverted);
      }
    }
    else {
      const value = this.value;
      const valueConverted = typeof value === 'string' ? value.replaceAll(',', '') : value;
      this.priceConverted = this.pricePipe.transform(valueConverted);
    }
  }

  ngAfterContentChecked(): void {
    if (this.form) {
      this.formGroup = this.form.form;
      if (this.buttonForm && !this.formGroup.value[this.buttonForm]) {
        this.formGroup.controls[this.buttonForm].setValue(this.dropdownItems?.[0]?.id);
      }
      if (this.form.form?.value[this.name] || this.form.form?.value[this.name] === 0 || this.form.form?.value[this.name] === '0') {
        const value = this.form.form?.value[this.name];
        const valueConverted = typeof value === 'string' ? value.replaceAll(',', '') : value;
        this.priceConverted = this.pricePipe.transform(valueConverted);
      }
    }
    else {
      const value = this.value;
      const valueConverted = typeof value === 'string' ? value.replaceAll(',', '') : value;
      this.priceConverted = this.pricePipe.transform(valueConverted);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  onInputChange() {
    if (!this.formGroup && !this.disabled) {
      this.priceConverted = (this.inputRef.nativeElement as HTMLInputElement).value;
      this.priceConverted = this.tools.convertFaToEnDigits(this.priceConverted).toLocaleString();
      const text = this.priceConverted.replaceAll(',', '');
      this.priceConverted = this.pricePipe.transform(text || '');
      this.valueChange.emit(text || null);
      return;
    }
    this.priceConverted = (this.inputRef.nativeElement as HTMLInputElement).value;
    this.priceConverted = this.tools.convertFaToEnDigits(this.priceConverted).toLocaleString();
    const text = this.priceConverted.replaceAll(',', '');
    this.formGroup.controls[this.name].setValue(text ? text.toLocaleString() : null);
    this.priceConverted = this.pricePipe.transform(text || '');
    this.valueChange.emit();
  }

  itemClicked(item: CurrencyTypeModel): void {
    this.dropdownItemSelected = item;
    this.formGroup.controls[this.buttonForm].setValue(item[this.buttonBindValue]);
    this.dropdownItemClicked.emit(item);
    this.dropdownItemSelectedChange.emit(item);
  }

  getErrorText(): string {
    const control = this.formGroup.controls[this.name];
    if (control.errors.required || control.errors.whitespace) {
      return `IS_REQUIRED`;
    }
    if (control.errors.min) {
      return `IS_INVALID`;
    }
  }

}
