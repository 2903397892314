<form [formGroup]="formGroup">
  <label *ngIf="label" [for]="name + ids"> {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span> </label>
  <div dir="ltr" class="border d-flex p-1">
    <ng-container *ngIf="isDay">
      <input (ngModelChange)="onChange('days', $event)" type="number" formControlName="days" [placeholder]="'DAYS' | translate" class="form-control text-center border-0" />
      <span class="align-items-center d-flex px-1"> : </span>
    </ng-container>
    <input (ngModelChange)="onChange('hours', $event)" type="number" formControlName="hours" [placeholder]="'HOURS' | translate" class="form-control text-center border-0" />
    <span class="align-items-center d-flex px-1"> : </span>
    <input (ngModelChange)="onChange('minutes', $event)" type="number" formControlName="minutes" [placeholder]="'MINUTES' | translate" class="form-control text-center border-0" />
  </div>
  <div *ngIf="
  required &&
  form.submitted &&
  (
    (isDay && !formGroup.value.days && formGroup.value.days !== 0) ||
    (!formGroup.value.hours && formGroup.value.hours !== 0) ||
    (!formGroup.value.minutes && formGroup.value.minutes !== 0)
  )" class="invalid-feedback d-block">
    <span> {{ label | translate }} {{ 'IS_REQUIRED' | translate }} </span>
  </div>
</form>
