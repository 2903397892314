<div *ngIf="form" [formGroup]="formGroup" [class]="classList" class="{{formGroup.controls[name].disabled || disabled?'disabled':''}}">
  <label *ngIf="label" [for]="name+ids" class="form-label {{formGroup.controls[name].disabled || disabled?'disabled':''}}"> {{ label | translate }} <span *ngIf="required"
      class="text-danger"> * </span> </label>

  <div *ngIf="isTime" class="input-group">
    <input type="datetime-local" [formControlName]="name" class="form-control" [disabled]="disabled" [attr.disabled]="disabled ? true : null" />
  </div>

  <div *ngIf="!isTime" class="input-group">

    <button *ngIf="formGroup.value[name]&&isClearable" (click)="clearDate()" class="btn btn-close btn-clear"> </button>

    <input ngbDatepicker [minDate]="minDate" [maxDate]="maxDate" #d="ngbDatepicker" name="hidden-input" (dateSelect)="onDateSelect($event)" class="d-none" [disabled]="disabled"
      readonly />

    <input (click)="d.toggle()" [formControlName]="name" [placeholder]="placeholder" [id]="name+ids" class="form-control"
      [disabled]="disabled" [attr.disabled]="disabled ? true : null" readonly />

    <i (click)="formGroup.controls[name].disabled || disabled?'':d.toggle()" class="icon icon-calendar {{formGroup.controls[name].disabled || disabled?'disabled':''}}"> </i>
  </div>

  <div *ngIf="(form.submitted && formGroup.controls[name].invalid) ||formGroup.controls[name].invalid && (formGroup.controls[name].dirty || formGroup.controls[name].touched)"
       class="invalid-feedback d-block">
    <div *ngIf="formGroup.controls[name].errors?.required"> {{label|translate}} {{ getErrorText()|translate }}
    </div>
    <div *ngIf="formGroup.controls[name].errors?.custom"> {{ getErrorText()|translate }}
    </div>
  </div>
</div>

<div *ngIf="!form" [class]="classList">
  <label *ngIf="label" [for]="name" class="form-label"> {{ label | translate }} <span *ngIf="required"
      class="text-danger"> * </span> </label>
  <div class="input-group">
    <input ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="selectedDate()" [(ngModel)]="date"
      [id]="name" class="form-control" [disabled]="disabled" [attr.disabled]="disabled ? true : null" readonly />
    <i (click)="d.toggle()" class="icon icon-calendar"> </i>
  </div>
</div>
