import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { KeyValuePipe } from "@angular/common";

@Component({
  selector: "tab-bar",
  templateUrl: "tab-bar.component.html",
  styleUrls: ["tab-bar.component.scss"],
})
export class TabBarComponent implements OnInit, OnChanges {
  @Input()classList = "";
  @Input() tabs;
  @Input() disabledTabs: number[] = [];
  @Input() activeId;
  @Output() tabChange = new EventEmitter();

  constructor(private enumPipe: KeyValuePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabledTabs && !changes.disabledTabs.firstChange) {
      this.setDisableTabs();
    }
  }

  ngOnInit(): void {
    this.tabs = this.enumPipe.transform(this.tabs, (a, b) =>
      +a.key < +b.key ? 0 : 1
    );
    this.tabs = (this.tabs as Array<any>).slice(0, this.tabs.length / 2);
    this.setDisableTabs();
  }

  setDisableTabs() {
    this.tabs.map((t) => {
      if (this.disabledTabs.indexOf(+t.key) >= 0) {
        t.disabled = true;
      } else {
        t.disabled = false;
      }
    });
  }

  onTabChange(): void {
    this.tabChange.emit(this.activeId);
  }
}
