import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/core/services/translate.service';

@Pipe({
  name: 'translate'
})

export class TranslatePipe implements PipeTransform {

  constructor(private langs: TranslateService) { }

  transform(value: any, ...args: any[]) {
    if (this.langs.keywords) {
      return this.langs.keywords[value] || value;
    }
    return value;
  }

}
