<div class="filter-container align-items-center gap-2 d-flex px-3">
  <div class="sort-title align-items-center justify-content-center gap-2 d-flex">
    <i class="icon icon-sort mx-0"> </i>
    {{ sort | translate }}
  </div>
  <div *ngFor="let item of sortItems; let i = index" (click)="onItemClick(i, item)" [class.active]="item.active"
       class="sort-item align-items-center d-flex px-3">
    {{ item.text | translate }}
  </div>
</div>
