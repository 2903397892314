<main *ngIf="!loading else loadingTemplate" class="gap-3 flex-column d-flex">
  <table>
    <thead>
      <tr>
        <th>
          <header id="main-header" class="gap-3 d-flex">
            <div class="gap-3 flex-column flex-fill d-flex">
              <h2> موسسه الیتیم الخیریه <span> ({{ translatePipe.transform('PERSONNEL_PROFILE') }}) </span> </h2>
              <div class="gap-3 d-flex">
                <img *ngIf="personnel.info.imageUrl" [src]="env + '/blob/' + personnel.info.imageUrl" width="90" height="80" alt="" class="rounded-circle" />
                <div class="row flex-fill gy-2">
                  <div *ngIf="selected.personnelCode" class="col-md-6"> <label> {{ translatePipe.transform('CODE') }} : </label> <span> {{ selected.personnelCode }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('BRANCH') }} : </label> <span> {{ selected.branch }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('PRINT_DATE') }} : </label> <pre> {{ printDate }} </pre> </div>
                </div>
              </div>
            </div>
            <img src="assets/images/logo-alyatim-2.svg" height="120" alt="" />
          </header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <section class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('EMPLOYEE_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <!-- <div class="col-md-6"> <label> {{ translatePipe.transform('PREFIX') }} : </label> <span> {{ personnel.info.userPrefix }} </span> </div> -->
                      <div class="col-md-6"> <label> {{ translatePipe.transform('NAME') }} : </label> <span> {{ selected.fullName }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('BIRTH_DATE') }} : </label> <pre> {{ personnel.info.birthDate }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ANCESTRY') }} : </label> <span> {{ selected.ancestry }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('GENDER') }} : </label> <span> {{ selected.gender }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('MARITAL_STATUS') }} : </label> <span> {{ selected.maritalStatus }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PHONE') }} : </label> <span> {{ selected.phone }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('EMAIL') }} : </label> <span> {{ personnel.info.email }} </span> </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('IS_RELIGIOUS_COMMITMENT') }} : </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (selected.isReligiousCommitment ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ID_CARD_NUMBER') }} : </label> <span> {{ personnel.info.idCardNumber }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('INTERESTS_HOBBIES') }} : </label> <span> {{ personnel.info.entertainment }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ISSUANCE') }} : </label> <span> {{ personnel.info.exporter }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.CONTACT_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('CONTACT_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <ng-container *ngIf="personnel.addressInfo && personnel.addressInfo.home">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('HOME_LOCATION') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('AREA') }} : </label> <span> {{ personnel.addressInfo.home.geoName }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ personnel.addressInfo.home.address }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="personnel.addressInfo && personnel.addressInfo.work">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('WORK_LOCATION') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('AREA') }} : </label> <span> {{ personnel.addressInfo.work.geoName }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ personnel.addressInfo.work.address }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="personnel.callNumbersData && personnel.callNumbersData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('CALL_NUMBERS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('CALL_NUMBER_TYPE') }} </th>
                              <th> {{ translatePipe.transform('CALL_NUMBER') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.callNumbersData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.callNumberType }} </td>
                              <td> {{ item.callNumber }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="personnel.socialNetworksData && personnel.socialNetworksData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('SOCIAL_NETWORKS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('SOCIAL_NETWORK_TYPE') }} </th>
                              <th> {{ translatePipe.transform('NETWORK') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.socialNetworksData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.socialNetworkType }} </td>
                              <td> {{ item.networkId }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <!-- <section *ngIf="hasData.ORGANIZATIONAL_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('ORGANIZATIONAL_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ORGANIZATIONAL_UNIT') }} : </label> <span> {{ personnel.organizationInfo.department }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ORGANIZATIONAL_POSITION') }} : </label> <span> {{ personnel.organizationInfo.organizationalPosition }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('RATE') }} : </label> <span> {{ personnel.organizationInfo.degree }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('BRANCH') }} : </label> <span> {{ personnel.organizationInfo.branch }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('START_JOB') }} : </label> <pre> {{ personnel.organizationInfo.fromDate }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('COOPERATION_END') }} : </label> <pre> {{ personnel.organizationInfo.toDate }} </pre> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section> -->
          <section *ngIf="hasData.OTHER_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('OTHER_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('MOTHER_NAME') }} : </label> <span> {{ personnel.otherInfo.mother }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PASSPORT_NUMBER') }} : </label> <span> {{ personnel.otherInfo.passportNo }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('EN_NAME_IN_PASSPORT') }} : </label> <span> {{ personnel.otherInfo.passportEnName }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PASSPORT_ISSUE_DATE') }} : </label> <pre> {{ personnel.otherInfo.passportIssueDate }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PASSPORT_EXPIRY_DATE') }} : </label> <pre> {{ personnel.otherInfo.passportExpiryDate }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PASSPORT_VALIDITY') }} : </label> <span> {{ personnel.otherInfo.passportValidity }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.DEPENDENTS" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('DEPENDENTS') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('NAME') }} </th>
                  <th> {{ translatePipe.transform('RELATION') }} </th>
                  <th> {{ translatePipe.transform('BIRTH_DATE') }} </th>
                  <th> {{ translatePipe.transform('AGE') }} </th>
                  <th> {{ translatePipe.transform('SOCIAL_STATUS') }} </th>
                  <th> {{ translatePipe.transform('IS_DISABLED') }} </th>
                  <th> {{ translatePipe.transform('HAS_INCOME_SOURCE') }} </th>
                  <th> {{ translatePipe.transform('IS_STUDENT') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of personnel.dependantsData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.fullName }} </td>
                  <td> {{ item.relationTypeTitle }} </td>
                  <td> {{ item.birthDate }} </td>
                  <td> {{ item.age }} </td>
                  <td> {{ item.maritalStatusTitle }} </td>
                  <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.outOfWork ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                  <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.hasSourceIncome ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                  <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.isStudent ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.EDUCATION_LICENSE_RECORDS" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('EDUCATION_LICENSE_RECORDS') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <ng-container *ngIf="personnel.educationsData && personnel.educationsData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('EDUCATION_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('DEGREE') }} </th>
                              <th> {{ translatePipe.transform('EducationStudyFields') }} </th>
                              <th> {{ translatePipe.transform('EDUCATION_PLACE_2') }} </th>
                              <th> {{ translatePipe.transform('EDUCATION_DATE') }} </th>
                              <th> {{ translatePipe.transform('LAST_EDUCATIONAL_CERTIFICATE') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.educationsData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.degree }} </td>
                              <td> {{ item.studyField }} </td>
                              <td> {{ item.place }} </td>
                              <td class="multiline-cell"> {{ item.startDate }} <br/> {{ item.endDate || '---' }} </td>
                              <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.isLast ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="personnel.certificatesData && personnel.certificatesData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('OTHER_LICENSES') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('PERIOD_TITLE') }} </th>
                              <th> {{ translatePipe.transform('PERIOD') }} </th>
                              <th> {{ translatePipe.transform('DATE') }} </th>
                              <th> {{ translatePipe.transform('ORGANIZATION_NAME_AND_PLACE') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.certificatesData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.certificate }} </td>
                              <td> {{ item.duration }} </td>
                              <td> {{ item.year }} </td>
                              <td> {{ item.institutePlace }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.JOB_RECORDS" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('JOB_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('JOB_TAGS') }} : </label> <span> {{ personnel.jobTagInfo }} </span> </div>
                        <div class="col-md-6"> <label> {{ translatePipe.transform('OTHER_INCOME') }} : </label> <span> {{ personnel.otherIncomeInfo + ' (' + translatePipe.transform('IQD') + ')' }} </span> </div>
                      </div>
                      <ng-container *ngIf="personnel.jobsData && personnel.jobsData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('JOB_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('OCCUPATION') }} </th>
                              <th> {{ translatePipe.transform('ORGANIZATION_NAME') }} </th>
                              <th> {{ translatePipe.transform('JOB_RESPONSIBILITIES') }} </th>
                              <th> {{ translatePipe.transform('JOB_RANGE_DATE') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.jobsData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.job }} </td>
                              <td> {{ item.institute }} </td>
                              <td> {{ item.position }} </td>
                              <td> {{ item.date }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.SKILLS" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('SKILLS') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('SKILL_TAGS') }} : </label> <span> {{ personnel.skillTagInfo }} </span> </div>
                      </div>
                      <ng-container *ngIf="personnel.skillsData && personnel.skillsData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('SKILL_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('SKILL') }} </th>
                              <th> {{ translatePipe.transform('SKILL_LEVEL') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of personnel.skillsData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.skill }} </td>
                              <td> {{ item.levelTitle }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.ATTACH" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('ATTACH') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('TYPE') }} </th>
                  <th> {{ translatePipe.transform('COUNT_UPLOADED') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of personnel.attachData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.attachmentTypeTitle }} </td>
                  <td> {{ item.count }} </td>
                </tr>
              </tbody>
            </table>
          </section>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<ng-template #loadingTemplate>
  <app-loader class="d-block my-4"> </app-loader>
</ng-template>
