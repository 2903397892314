import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class DonationService {

  constructor(private api: ApiService) { }

  aidCashAidCreateCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/CreateCashAid');
  }

  aidCashAidUpdateCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/UpdateCashAid');
  }

  aidCashAidDeleteCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidCashAid/DeleteCashAid');
  }

  aidCashAidGetCashAidById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidCashAid/GetCashAidById/${ query }`);
  }

  aidCashAidGetCashAidByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidCashAid/GetCashAidByCode/${ query }`);
  }

  aidCashAidGetCashAidByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidCashAid/GetCashAidByIdentifier/${ query }`);
  }

  aidCashAidGetCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/GetCashAid');
  }

  aidCashAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/Filter');
  }

  aidCashAidAuditCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/AuditCashAids');
  }

  aidCashAidFinalAuditCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/FinalAuditCashAids');
  }

  aidCashAidApproveCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/ApproveCashAids');
  }

  aidCashAidFinalApproveCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/FinalApproveCashAids');
  }

  aidCashAidAccountantApproval<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/AccountantApproval');
  }

  aidCashAidCreateUpdatePaymentFromCashRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/CreateUpdatePaymentFromCashRequest');
  }

  aidCashAidDeletePaymentFromCashRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidCashAid/DeletePaymentFromCashRequest');
  }

  aidCashAidFilterPaymentFromCashRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/FilterPaymentFromCashRequest');
  }

  aidCashAidGetPaymentRequestFromCash<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidCashAid/GetPaymentRequestFromCash/${ query }`);
  }

  aidCashAidAddCashAidToPaymentFromCashRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/AddCashAidToPaymentFromCashRequest');
  }

  aidCashAidDeleteCashAidFromPaymentFromCashRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidCashAid/DeleteCashAidFromPaymentFromCashRequest');
  }

  aidCashAidChangeDeliveryType<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/ChangeDeliveryType');
  }

  aidCashAidChangeDeliveryMethod<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidCashAid/ChangeDeliveryMethod');
  }

  aidCashAidFilterCashAidDeliveryWorkList<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidCashAid/FilterCashAidDeliveryWorkList');
  }

  aidRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequest/Create');
  }

  aidRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequest/Update');
  }

  aidRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequest/Delete');
  }

  aidRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequest/GetById/${ query }`);
  }

  aidRequestGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequest/GetByCode/${ query }`);
  }

  aidRequestGetByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequest/GetByIdentifier/${ query }`);
  }

  aidRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequest/Filter');
  }

  aidRequestExitFromWarehouseCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestExitFromWarehouse/Create');
  }

  aidRequestExitFromWarehouseUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestExitFromWarehouse/Update');
  }

  aidRequestExitFromWarehouseDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequestExitFromWarehouse/Delete');
  }

  aidRequestExitFromWarehouseCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestExitFromWarehouse/CreateItem');
  }

  aidRequestExitFromWarehouseCreateItems<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestExitFromWarehouse/CreateItems');
  }

  aidRequestExitFromWarehouseUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestExitFromWarehouse/UpdateItem');
  }

  aidRequestExitFromWarehouseDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequestExitFromWarehouse/DeleteItem');
  }

  aidRequestExitFromWarehouseFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestExitFromWarehouse/Filter');
  }

  aidRequestExitFromWarehouseGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetById/${ query }`);
  }

  aidRequestExitFromWarehouseGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetByCode/${ query }`);
  }

  aidRequestExitFromWarehouseGetByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetByIdentifier/${ query }`);
  }

  aidRequestExitFromWarehouseGetItemsByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetItemsByIdentifier/${ query }`);
  }

  aidRequestExitFromWarehouseGetItemsById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetItemsById/${ query }`);
  }

  aidRequestExitFromWarehouseGetItemsByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestExitFromWarehouse/GetItemsByCode/${ query }`);
  }

  aidRequestExitFromWarehouseSetAccountantApproval<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestExitFromWarehouse/SetAccountantApproval');
  }

  aidRequestExitFromWarehouseChangeDeliveryMethod<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestExitFromWarehouse/ChangeDeliveryMethod');
  }

  aidRequestOrderToLeaveTheWarehouseCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/Create');
  }

  aidRequestOrderToLeaveTheWarehouseUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/Update');
  }

  aidRequestOrderToLeaveTheWarehouseDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/Delete');
  }

  aidRequestOrderToLeaveTheWarehouseCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/CreateItem');
  }

  aidRequestOrderToLeaveTheWarehouseCreateItems<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/CreateItems');
  }

  aidRequestOrderToLeaveTheWarehouseUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/UpdateItem');
  }

  aidRequestOrderToLeaveTheWarehouseDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/DeleteItem');
  }

  aidRequestOrderToLeaveTheWarehouseDeleteItems<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/DeleteItems');
  }

  aidRequestOrderToLeaveTheWarehouseFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/Filter');
  }

  aidRequestOrderToLeaveTheWarehouseGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestOrderToLeaveTheWarehouse/GetById/${ query }`);
  }

  aidRequestOrderToLeaveTheWarehouseGetItemsById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidRequestOrderToLeaveTheWarehouse/GetItemsById/${ query }`);
  }

  aidRequestOrderToLeaveTheWarehouseDeliverToAgent<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidRequestOrderToLeaveTheWarehouse/DeliverToAgent');
  }

  aidServiceAidCreateServiceAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/AidServiceAid/CreateServiceAid');
  }

  aidServiceAidUpdateServiceAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidServiceAid/UpdateServiceAid');
  }

  aidServiceAidDeleteServiceAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/AidServiceAid/DeleteServiceAid');
  }

  aidServiceAidApproveServiceAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidServiceAid/ApproveServiceAids');
  }

  aidServiceAidFinalApproveServiceAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/AidServiceAid/FinalApproveServiceAids');
  }

  aidServiceAidGetServiceAidsById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidServiceAid/GetServiceAidsById/${ query }`);
  }

  aidServiceAidGetServiceAidsByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/AidServiceAid/GetServiceAidsByIdentifier/${ query }`);
  }

  bulkAidRequestCreateCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/BulkAidRequest/CreateCashAids');
  }

  bulkAidRequestCreateObjectiveAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/BulkAidRequest/CreateObjectiveAids');
  }

  campaignCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Campaign/Create');
  }

  campaignUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Campaign/Update');
  }

  campaignDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Campaign/Delete');
  }

  campaignFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Campaign/Filter');
  }

  cashDonationCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonation/Create');
  }

  cashDonationUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashDonation/Update');
  }

  cashDonationDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashDonation/Delete');
  }

  cashDonationFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonation/Filter');
  }

  cashDonationCreateCancelRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonation/CreateCancelRequest');
  }

  cashDonationSetApprovalStatusForCancelRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashDonation/SetApprovalStatusForCancelRequest');
  }

  cashDonationFilterCancelRequests<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonation/FilterCancelRequests');
  }

  cashDonationGetByRequestId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonation/GetByRequestId/${ query }`);
  }

  cashDonationReceiptCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationReceipt/Create');
  }

  cashDonationReceiptUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashDonationReceipt/Update');
  }

  cashDonationReceiptDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashDonationReceipt/Delete');
  }

  cashDonationReceiptFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationReceipt/Filter');
  }

  cashDonationReceiptAttachFiles<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashDonationReceipt/AttachFiles');
  }

  cashDonationReceiptGetAttachedFiles<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationReceipt/GetAttachedFiles/${ query }`);
  }

  cashDonationReceiptGetByCashDonationId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationReceipt/GetByCashDonationId/${ query }`);
  }

  cashDonationReceiptGetByDeliveryToCashierId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationReceipt/GetByDeliveryToCashierId/${ query }`);
  }

  cashDonationReceiptGetByDeliveryToCashierCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationReceipt/GetByDeliveryToCashierCode/${ query }`);
  }

  cashDonationReceiptGetLastReceiptNumber<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationReceipt/GetLastReceiptNumber');
  }

  cashDonationReceiptFilterTotal<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationReceipt/FilterTotal');
  }

  cashDonationRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationRequest/Create');
  }

  cashDonationRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashDonationRequest/Update');
  }

  cashDonationRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashDonationRequest/Delete');
  }

  cashDonationRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashDonationRequest/Filter');
  }

  cashDonationRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationRequest/GetById/${ query }`);
  }

  cashDonationRequestGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashDonationRequest/GetByCode/${ query }`);
  }

  cashTransactionTransfer<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransaction/Transfer');
  }

  cashTransactionFilterTransferBalance<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransaction/FilterTransferBalance');
  }

  cashTransferRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/Create');
  }

  cashTransferRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/Update');
  }

  cashTransferRequestAllocateRequest<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/AllocateRequest');
  }

  cashTransferRequestAllocateItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/AllocateItemDetail');
  }

  cashTransferRequestDeleteAllocateItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequest/DeleteAllocateItemDetail');
  }

  cashTransferRequestCancellItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/CancellItemDetail');
  }

  cashTransferRequestReturn<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/Return');
  }

  cashTransferRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequest/Delete');
  }

  cashTransferRequestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequest/SetStatus');
  }

  cashTransferRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/Filter');
  }

  cashTransferRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequest/GetById/${ query }`);
  }

  cashTransferRequestCreateOrUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/CreateOrUpdateItem');
  }

  cashTransferRequestDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequest/DeleteItem');
  }

  cashTransferRequestGetItemsByRequestId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequest/GetItemsByRequestId/${ query }`);
  }

  cashTransferRequestCreateOrUpdateItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/CreateOrUpdateItemDetail');
  }

  cashTransferRequestDeleteItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequest/DeleteItemDetail');
  }

  cashTransferRequestGetItemDetailsByItemId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequest/GetItemDetailsByItemId/${ query }`);
  }

  cashTransferRequestFilterItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/FilterItem');
  }

  cashTransferRequestFilterItemDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/FilterItemDetail');
  }

  cashTransferRequestFilterAllocate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequest/FilterAllocate');
  }

  cashTransferRequestAllocateBalance<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequest/AllocateBalance/${ query }`);
  }

  cashTransferRequestDeliveryCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequestDelivery/Create');
  }

  cashTransferRequestDeliveryDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequestDelivery/Delete');
  }

  cashTransferRequestDeliveryUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequestDelivery/Update');
  }

  cashTransferRequestDeliveryCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequestDelivery/CreateItem');
  }

  cashTransferRequestDeliveryDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTransferRequestDelivery/DeleteItem');
  }

  cashTransferRequestDeliveryFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTransferRequestDelivery/Filter');
  }

  cashTransferRequestDeliveryGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/Get/${ query }`);
  }

  cashTransferRequestDeliveryGetAllData<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/GetAllData/${ query }`);
  }

  cashTransferRequestDeliveryGetAccountByRequestId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/GetAccountByRequestId/${ query }`);
  }

  cashTransferRequestDeliveryGetAccounts<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/GetAccounts?${ query }`);
  }

  cashTransferRequestDeliveryPrint<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/Print/${ query }`);
  }

  cashTransferRequestDeliveryCreateStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequestDelivery/CreateStatus');
  } // OLD

  cashTransferRequestDeliverySetAsReceived<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequestDelivery/SetAsReceived');
  }

  cashTransferRequestDeliverySetAsAccepted<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/CashTransferRequestDelivery/SetAsAccepted');
  }

  cashTransferRequestDeliveryReturnReceive<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/CashTransferRequestDelivery/ReturnReceive/${ query }`);
  }

  cashTreeCreateCashTreeDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTree/CreateCashTreeDonationTree');
  }

  cashTreeDeleteCashTreeDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/CashTree/DeleteCashTreeDonationTree');
  }

  cashTreeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTree/Filter');
  }

  cashTreeFlat<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTree/Flat');
  }

  cashTreeFilterCashTransactionBalance<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTree/FilterCashTransactionBalance');
  }

  cashTreeFilterCashTransactionBalanceWithoutBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/CashTree/FilterCashTransactionBalanceWithoutBranch');
  }

  deliveryReportCreateForCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryReport/CreateForCashAid');
  }

  deliveryReportCreateForObjectiveAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryReport/CreateForObjectiveAid');
  }

  deliveryReportDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/DeliveryReport/Delete');
  }

  deliveryReportReview<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/DeliveryReport/Review');
  }

  deliveryReportFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryReport/Filter');
  }

  deliveryReportGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/DeliveryReport/GetById/${ query }`);
  }

  deliveryReportFilterByItemsIdentifier<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryReport/FilterByItemsIdentifier');
  }

  deliveryToCashierCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryToCashier/Create');
  }

  deliveryToCashierUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/DeliveryToCashier/Update');
  }

  deliveryToCashierDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/DeliveryToCashier/Delete');
  }

  deliveryToCashierApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/DeliveryToCashier/Approve');
  }

  deliveryToCashierFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryToCashier/Filter');
  }

  deliveryToCashierAddReceipt<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryToCashier/AddReceipt');
  }

  deliveryToCashierRemoveReceipt<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DeliveryToCashier/RemoveReceipt');
  }

  deliveryToCashierSetAuditResult<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/DeliveryToCashier/SetAuditResult');
  }

  donationTreeCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DonationTree/Create');
  }

  donationTreeUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/DonationTree/Update');
  }

  donationTreeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/DonationTree/Delete');
  }

  donationTreeGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/DonationTree/Get/${ query }`);
  }

  donorCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/Create');
  }

  donorUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/Donor/Update');
  }

  donorDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Donor/Delete');
  }

  donorFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/Filter');
  }

  donorAddReferer<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/AddReferer');
  }

  donorDeleteReferer<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Donor/DeleteReferer');
  }

  donorAddBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/AddBranch');
  }

  donorRemoveBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Donor/RemoveBranch');
  }

  donorGetBranches<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/Donor/GetBranches/${ query }`);
  }

  donorAssignAgents<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/AssignAgents');
  }

  donorUnassignAgents<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Donor/UnassignAgents');
  }

  donorGetAgents<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/GetAgents');
  }

  donorSetActivation<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Donor/SetActivation');
  }

  exportCashDonationRequests<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/CashDonationRequests');
  }

  exportCashDonations<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/CashDonations');
  }

  exportCashDonationReceipts<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/CashDonationReceipts');
  }

  exportExportReceivedServices<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/ExportReceivedServices');
  }

  exportExportReceivedObjectiveDonation<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/ExportReceivedObjectiveDonation');
  }

  exportExportDonors<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Export/ExportDonors');
  }

  festivalCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Festival/Create');
  }

  festivalUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/Festival/Update');
  }

  festivalDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Festival/Delete');
  }

  festivalFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Festival/Filter');
  }

  fundWithdrawalRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/FundWithdrawalRequest/Create');
  }

  fundWithdrawalRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/FundWithdrawalRequest/Update');
  }

  fundWithdrawalRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/FundWithdrawalRequest/Delete');
  }

  fundWithdrawalRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/FundWithdrawalRequest/Filter');
  }

  goodsBuyFactorCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyFactor/Create');
  }

  goodsBuyFactorUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsBuyFactor/Update');
  }

  goodsBuyFactorDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsBuyFactor/Delete');
  }

  goodsBuyFactorCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyFactor/CreateItem');
  }

  goodsBuyFactorUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsBuyFactor/UpdateItem');
  }

  goodsBuyFactorDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsBuyFactor/DeleteItem');
  }

  goodsBuyFactorGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetById/${ query }`);
  }

  goodsBuyFactorGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetByCode/${ query }`);
  }

  goodsBuyFactorFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyFactor/Filter');
  }

  goodsBuyFactorGetItemsById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetItemsById/${ query }`);
  }

  goodsBuyFactorGetItemsByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetItemsByCode/${ query }`);
  }

  goodsBuyFactorGetItemsByGoodsBuyingRequestItemId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetItemsByGoodsBuyingRequestItemId/${ query }`);
  }

  goodsBuyFactorGetAllData<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyFactor/GetAllData/${ query }`);
  }

  goodsBuyFactorFinalApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyFactor/FinalApprove');
  }

  goodsBuyingRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyingRequest/Create');
  }

  goodsBuyingRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsBuyingRequest/Update');
  }

  goodsBuyingRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsBuyingRequest/Delete');
  }

  goodsBuyingRequestCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyingRequest/CreateItem');
  }

  goodsBuyingRequestUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsBuyingRequest/UpdateItem');
  }

  goodsBuyingRequestDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsBuyingRequest/DeleteItem');
  }

  goodsBuyingRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetById/${ query }`);
  }

  goodsBuyingRequestGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetByCode/${ query }`);
  }

  goodsBuyingRequestGetByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetByIdentifier/${ query }`);
  }

  goodsBuyingRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyingRequest/Filter');
  }

  goodsBuyingRequestGetItemsById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetItemsById/${ query }`);
  }

  goodsBuyingRequestGetItemsByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetItemsByCode/${ query }`);
  }

  goodsBuyingRequestGetItemsByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/GoodsBuyingRequest/GetItemsByIdentifier/${ query }`);
  }

  goodsBuyingRequestSetReasonForNotBuying<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsBuyingRequest/SetReasonForNotBuying');
  }

  goodsTransferRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsTransferRequest/Create');
  }

  goodsTransferRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsTransferRequest/Update');
  }

  goodsTransferRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsTransferRequest/Delete');
  }

  goodsTransferRequestCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsTransferRequest/CreateItem');
  }

  goodsTransferRequestUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/GoodsTransferRequest/UpdateItem');
  }

  goodsTransferRequestDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/GoodsTransferRequest/DeleteItem');
  }

  goodsTransferRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsTransferRequest/Filter');
  }

  goodsTransferRequestGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsTransferRequest/Get');
  }

  goodsTransferRequestGetItems<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/GoodsTransferRequest/GetItems');
  }

  objectiveAidCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/Create');
  }

  objectiveAidUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveAid/Update');
  }

  objectiveAidDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveAid/Delete');
  }

  objectiveAidAddProductToAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/AddProductToAid');
  }

  objectiveAidRemoveProductFromAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveAid/RemoveProductFromAid');
  }

  objectiveAidRejectAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/RejectAid');
  }

  objectiveAidApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveAid/Approve');
  }

  objectiveAidFinalApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveAid/FinalApprove');
  }

  objectiveAidGetByRequestId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveAid/GetByRequestId/${ query }`);
  }

  objectiveAidGetByRequestIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveAid/GetByRequestIdentifier/${ query }`);
  }

  objectiveAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/Filter');
  }

  objectiveAidGetObjectiveAidProducts<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/GetObjectiveAidProducts');
  }

  objectiveAidFilterObjectiveAidDeliveryWorkList<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveAid/FilterObjectiveAidDeliveryWorkList');
  }

  objectiveDonationRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveDonationRequest/Create');
  }

  objectiveDonationRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveDonationRequest/Update');
  }

  objectiveDonationRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveDonationRequest/Delete');
  }

  objectiveDonationRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveDonationRequest/Filter');
  }

  objectiveDonationRequestGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveDonationRequest/Get/${ query }`);
  }

  objectiveDonationRequestApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveDonationRequest/Approve');
  }

  objectiveDonationRequestCreateDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveDonationRequest/CreateDetail');
  }

  objectiveDonationRequestUpdateDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveDonationRequest/UpdateDetail');
  }

  objectiveDonationRequestDeleteDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveDonationRequest/DeleteDetail');
  }

  objectiveDonationRequestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveDonationRequest/SetStatus');
  }

  objectiveTransferDeliveryCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferDelivery/Create');
  }

  objectiveTransferDeliveryUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferDelivery/Update');
  }

  objectiveTransferDeliverySetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferDelivery/SetStatus');
  }

  objectiveTransferDeliveryReturn<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferDelivery/Return');
  }

  objectiveTransferDeliveryDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveTransferDelivery/Delete');
  }

  objectiveTransferDeliveryGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveTransferDelivery/Get/${ query }`);
  }

  objectiveTransferDeliveryFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferDelivery/Filter');
  }

  objectiveTransferDeliveryReport<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveTransferDelivery/Report/${ query }`);
  }

  objectiveTransferDeliveryCreateSupply<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferDelivery/CreateSupply');
  }

  objectiveTransferDeliveryReturnSupply<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferDelivery/ReturnSupply');
  }

  objectiveTransferExitFromWarehouseCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferExitFromWarehouse/Create');
  }

  objectiveTransferExitFromWarehouseUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferExitFromWarehouse/Update');
  }

  objectiveTransferExitFromWarehouseCreateSupply<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferExitFromWarehouse/CreateSupply');
  }

  objectiveTransferExitFromWarehouseDeleteSupply<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveTransferExitFromWarehouse/DeleteSupply');
  }

  objectiveTransferRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/Create');
  }

  objectiveTransferRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferRequest/Update');
  }

  objectiveTransferRequestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferRequest/SetStatus');
  }

  objectiveTransferRequestReturn<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferRequest/Return');
  }

  objectiveTransferRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveTransferRequest/Delete');
  }

  objectiveTransferRequestGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ObjectiveTransferRequest/Get/${ query }`);
  }

  objectiveTransferRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/Filter');
  }

  objectiveTransferRequestCreateAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/CreateAid');
  }

  objectiveTransferRequestDeleteAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveTransferRequest/DeleteAid');
  }

  objectiveTransferRequestAidFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/AidFilter');
  }

  objectiveTransferRequestCreateOrUpdateAidDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/CreateOrUpdateAidDetail');
  }

  objectiveTransferRequestAidDetailSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ObjectiveTransferRequest/AidDetailSetStatus');
  }

  objectiveTransferRequestDeleteAidDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ObjectiveTransferRequest/DeleteAidDetail');
  }

  objectiveTransferRequestAidDetailFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/AidDetailFilter');
  }

  objectiveTransferRequestAidDetailSupplyFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ObjectiveTransferRequest/AidDetailSupplyFilter');
  }

  paymentReceiptToTheAgentCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/PaymentReceiptToTheAgent/CreateUpdate');
  }

  paymentReceiptToTheAgentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/PaymentReceiptToTheAgent/Delete');
  }

  paymentReceiptToTheAgentFinalRegistration<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/PaymentReceiptToTheAgent/FinalRegistration');
  }

  paymentReceiptToTheAgentAddCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/PaymentReceiptToTheAgent/AddCashAid');
  }

  paymentReceiptToTheAgentAddCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/PaymentReceiptToTheAgent/AddCashAids');
  }

  paymentReceiptToTheAgentDeleteCashAid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/PaymentReceiptToTheAgent/DeleteCashAid');
  }

  paymentReceiptToTheAgentDeleteCashAids<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/PaymentReceiptToTheAgent/DeleteCashAids');
  }

  paymentReceiptToTheAgentGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/PaymentReceiptToTheAgent/Get/${ query }`);
  }

  paymentReceiptToTheAgentFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/PaymentReceiptToTheAgent/Filter');
  }

  productCreateProductDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Product/CreateProductDonationTree');
  }

  productDeleteProductDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Product/DeleteProductDonationTree');
  }

  productFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Product/Filter');
  }

  productGetByDonationTreeId<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Product/GetByDonationTreeId');
  }

  productFilterProductGroups<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Product/FilterProductGroups');
  }

  productFlatProductGroup<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Product/FlatProductGroup');
  }

  projectCreateProjectDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Project/CreateProjectDonationTree');
  }

  projectDeleteProjectDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Project/DeleteProjectDonationTree');
  }

  projectFilterProjectGroups<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Project/FilterProjectGroups');
  }

  reasonCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Reason/Create');
  }

  reasonUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Reason/Update');
  }

  reasonDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Reason/Delete');
  }

  reasonFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Reason/Filter');
  }

  returnFromCashierCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ReturnFromCashier/Create');
  }

  returnFromCashierUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ReturnFromCashier/Update');
  }

  returnFromCashierDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ReturnFromCashier/Delete');
  }

  returnFromCashierFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ReturnFromCashier/Filter');
  }

  returnFromCashierApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ReturnFromCashier/Approve');
  }

  returnFromCashierSetAuditResult<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ReturnFromCashier/SetAuditResult');
  }

  returnFromCashierGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ReturnFromCashier/Get/${ query }`);
  }

  returnFromCashierDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ReturnFromCashier/DeleteItem');
  }

  serviceCreateServiceDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Service/CreateServiceDonationTree');
  }

  serviceDeleteServiceDonationTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/Service/DeleteServiceDonationTree');
  }

  serviceFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Service/Filter');
  }

  serviceGetByDonationTreeId<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Service/GetByDonationTreeId');
  }

  serviceFilterServiceGroups<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Service/FilterServiceGroups');
  }

  serviceFlatServiceGroups<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Service/FlatServiceGroups');
  }

  serviceDonationCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonation/Create');
  }

  serviceDonationUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ServiceDonation/Update');
  }

  serviceDonationDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ServiceDonation/Delete');
  }

  serviceDonationGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ServiceDonation/Get/${ query }`);
  }

  serviceDonationFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonation/Filter');
  }

  serviceDonationSetEvaluationResult<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonation/SetEvaluationResult');
  }

  serviceDonationApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonation/Approve');
  }

  serviceDonationSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ServiceDonation/SetStatus');
  }

  serviceDonationApproveReturn<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ServiceDonation/ApproveReturn');
  }

  serviceDonationAddBeneficiary<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ServiceDonation/AddBeneficiary');
  }

  serviceDonationRemoveBeneficiary<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/ServiceDonation/RemoveBeneficiary');
  }

  serviceDonationGetBeneficiaries<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ServiceDonation/GetBeneficiaries/${ query }`);
  }

  serviceDonationFilterReceivedServices<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonation/FilterReceivedServices');
  }

  serviceDonationRequestCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonationRequest/CreateUpdate');
  }

  serviceDonationRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/ServiceDonationRequest/Delete');
  }

  serviceDonationRequestGetByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ServiceDonationRequest/GetByIdentifier/${ query }`);
  }

  serviceDonationRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/ServiceDonationRequest/GetById/${ query }`);
  }

  serviceDonationRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/ServiceDonationRequest/Filter');
  }

  settingUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Setting/Update');
  }

  settingFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Setting/Filter');
  }

  translationCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Translation/Create');
  }

  translationGetAll<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/Translation/GetAll/${ query }`);
  }

  translationFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Translation/Filter');
  }

  translationUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/Translation/Update');
  }

  voidReceiptCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/VoidReceipt/CreateUpdate');
  }

  voidReceiptDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/VoidReceipt/Delete');
  }

  voidReceiptFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/VoidReceipt/Filter');
  }

  voidReceiptGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/VoidReceipt/Get/${ query }`);
  }

  voidReceiptCheckReceiptNumber<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/VoidReceipt/CheckReceiptNumber');
  }

  warehouseFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/Warehouse/Filter');
  }

  warehouseGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Donation/Warehouse/Get/${ query }`);
  }

  warehouseReceiptCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/Create');
  }

  warehouseReceiptDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/WarehouseReceipt/Delete');
  }

  warehouseReceiptUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/WarehouseReceipt/Update');
  }

  warehouseReceiptFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/Filter');
  }

  warehouseReceiptSetEvaluationResult<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/SetEvaluationResult');
  }

  warehouseReceiptFilterReceiptItems<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/FilterReceiptItems');
  }

  warehouseReceiptCreateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/CreateItem');
  }

  warehouseReceiptDeleteItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Donation/WarehouseReceipt/DeleteItem');
  }

  warehouseReceiptUpdateItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Donation/WarehouseReceipt/UpdateItem');
  }

  warehouseReceiptFilterReceivedObjectiveDonation<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Donation/WarehouseReceipt/FilterReceivedObjectiveDonation');
  }

}
