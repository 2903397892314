import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sum', pure: false })

export class SumPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(array: {}[], arg: string, returnType?: string) {
    let sum = 0;
    if (array && array.length) {
      array.map((item) => sum += typeof item[arg] === 'string' ? +item[arg].replaceAll(',', '') : item[arg]);
    }
    if (returnType === 'number') {
      return sum;
    }
    return this.decimalPipe.transform(sum) || 0;
  }

}
