<label *ngIf="label" class="form-label"> {{ label | translate }} </label>
<div class="row align-items-center gx-0 {{ classList }}">
  <div class="col-6">
    <div class="position-absolute dp-hidden">
      <div class="input-group">

        <input ngbDatepicker (dateSelect)="onDateSelection($event)" (navigate)="onNavigate($event)" #datepicker="ngbDatepicker" name="datepicker"
          [autoClose]="'outside'" [displayMonths]="2" [dayTemplate]="t" [footerTemplate]="tt" outsideDays="hidden" [startDate]="fromDate!"
          class="form-control" [disabled]="disable" />

        <ng-template #t let-date let-focused="focused">
          <span [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null" class="custom-day"> {{ date.day }} </span>
        </ng-template>

        <ng-template #tt>
          <app-btn-custom (onClick)="onNavigate({}, true)" [theme]="{ type: 'fill', color: 'blue', text: 'SELECT_CURRENT_MONTH' }" class="d-block m-1"> </app-btn-custom>
        </ng-template>

      </div>
    </div>
    <div class="input-group">

      <button *ngIf="fromDate" (click)="clearFromDateClick()" class="btn btn-close btn-clear"> </button>

      <input #dpFromDate [placeholder]="'FROM_DATE'|translate" name="dpFromDate" [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)" class="form-control" [disabled]="disable"
        (click)="datepicker.toggle()" />

      <button (click)="datepicker.toggle()" type="button" class="btn btn-outline-secondary icon icon-calendar">
      </button>

    </div>
  </div>
  <div class="col-6">
    <div class="input-group">
      <button *ngIf="toDate" (click)="clearToDateClick()" class="btn btn-close btn-clear"> </button>

      <input #dpToDate [placeholder]="'TO_DATE'|translate" name="dpToDate" [value]="formatter.format(toDate)"
        (input)="toDate = validateInput(toDate, dpToDate.value)" [class.clearable]="isClearable" class="form-control"
        [disabled]="disable" (click)="datepicker.toggle()" />

      <button (click)="datepicker.toggle()" type="button" class="btn btn-outline-secondary icon icon-calendar">
      </button>
    </div>
  </div>
</div>
