import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class SponsorshipService {

  constructor(private api: ApiService) { }

  enumsGetCollectionPeriods<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/Enums/GetCollectionPeriods/${ query }`);
  }

  enumsGetCollectionPlaces<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/Enums/GetCollectionPlaces/${ query }`);
  }

  requestEditSponsorshipCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestEditSponsorship/Create');
  }

  requestEditSponsorshipUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestEditSponsorship/Update');
  }

  requestEditSponsorshipDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/RequestEditSponsorship/Delete');
  }

  requestEditSponsorshipFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestEditSponsorship/Filter');
  }

  requestEditSponsorshipGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestEditSponsorship/GetById/${ query }`);
  }

  requestEditSponsorshipGetByRequestCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestEditSponsorship/GetByRequestCode/${ query }`);
  }

  requestEditSponsorshipAccept<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestEditSponsorship/Accept');
  }

  requestEditSponsorshipReject<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestEditSponsorship/Reject');
  }

  requestEditSponsorshipCancelBySponsor<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestEditSponsorship/CancelBySponsor');
  }

  requestForSponsorshipCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestForSponsorship/Create');
  }

  requestForSponsorshipUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/Update');
  }

  requestForSponsorshipDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/RequestForSponsorship/Delete');
  }

  requestForSponsorshipFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestForSponsorship/Filter');
  }

  requestForSponsorshipGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestForSponsorship/GetById/${ query }`);
  }

  requestForSponsorshipGetByRequestCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestForSponsorship/GetByRequestCode/${ query }`);
  }

  requestForSponsorshipAddOfferedOrphan<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestForSponsorship/AddOfferedOrphan');
  }

  requestForSponsorshipUpdateOfferedOrphan<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/UpdateOfferedOrphan');
  }

  requestForSponsorshipDeleteOfferedOrphan<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/RequestForSponsorship/DeleteOfferedOrphan');
  }

  requestForSponsorshipGetOfferedOrphans<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestForSponsorship/GetOfferedOrphans/${ query }`);
  }

  requestForSponsorshipSetAsOffered<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/SetAsOffered');
  }

  requestForSponsorshipApproveByBranchManager<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/ApproveByBranchManager');
  }

  requestForSponsorshipApproveByManager<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/ApproveByManager');
  }

  requestForSponsorshipFinalCancel<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestForSponsorship/FinalCancel');
  }

  requestStopSponsorshipCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestStopSponsorship/CreateUpdate');
  }

  requestStopSponsorshipDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/RequestStopSponsorship/Delete');
  }

  requestStopSponsorshipFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/RequestStopSponsorship/Filter');
  }

  requestStopSponsorshipGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/RequestStopSponsorship/Get/${ query }`);
  }

  requestStopSponsorshipAccept<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestStopSponsorship/Accept');
  }

  requestStopSponsorshipCanceled<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/RequestStopSponsorship/Canceled');
  }

  salaryRequestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequest/Create');
  }

  salaryRequestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/SalaryRequest/Update');
  }

  salaryRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/SalaryRequest/Delete');
  }

  salaryRequestFilterSalaryRequestSponsorOrphans<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequest/FilterSalaryRequestSponsorOrphans');
  }

  salaryRequestDeleteSalaryRequestSponsorOrphan<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/SalaryRequest/DeleteSalaryRequestSponsorOrphan');
  }

  salaryRequestGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/SalaryRequest/GetById/${ query }`);
  }

  salaryRequestGetByCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/SalaryRequest/GetByCode/${ query }`);
  }

  salaryRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequest/Filter');
  }

  salaryRequestWorkListQuery<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequest/WorkListQuery');
  }

  salaryRequestWorkListQueryDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequest/WorkListQueryDetail');
  }

  salaryRequestAccept<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/SalaryRequest/Accept');
  }

  salaryRequestCanceled<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/SalaryRequest/Canceled');
  }

  salaryRequestPaymentCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequestPayment/Create');
  }

  salaryRequestPaymentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/SalaryRequestPayment/Delete');
  }

  salaryRequestPaymentFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SalaryRequestPayment/Filter');
  }

  sponsorCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/Sponsor/Create');
  }

  sponsorUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Sponsorship/Sponsor/Update');
  }

  sponsorDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/Sponsor/Delete');
  }

  sponsorFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/Sponsor/Filter');
  }

  sponsorGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/Sponsor/Get/${ query }`);
  }

  sponsorGetOrphan<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/Sponsor/GetOrphan/${ query }`);
  }

  sponsorFilterOrphans<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/Sponsor/FilterOrphans');
  }

  sponsorOrphanCreateNewSponsorOrphanContractInfo<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SponsorOrphan/CreateNewSponsorOrphanContractInfo');
  }

  sponsorOrphanGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/SponsorOrphan/GetById/${ query }`);
  }

  sponsorOrphanSetAllInstullments<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/SponsorOrphan/SetAllInstullments');
  }

  paymentReceiptCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/TransactionReceipt/Create');
  }

  paymentReceiptDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Sponsorship/TransactionReceipt/Delete');
  }

  paymentReceiptFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Sponsorship/TransactionReceipt/Filter');
  }

  paymentReceiptGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/TransactionReceipt/GetById/${ query }`);
  }

  paymentReceiptGetByPaymentCode<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Sponsorship/TransactionReceipt/GetByPaymentCode/${ query }`);
  }

}
