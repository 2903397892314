import { Component, OnInit } from '@angular/core';
import { PrintService } from 'src/app/core/services/print.service';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss']
})

export class PrintPageComponent implements OnInit {

  constructor(public translatePipe: TranslatePipe, public printService: PrintService) {}

  ngOnInit(): void {}

  returnZero(): number {
    return 0;
  }

  typeOf(value: any): string {
    return typeof value;
  }

}
