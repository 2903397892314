import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'total-value-component',
  template: `
    <span>
      <button (click)="invock('edit')" class="btn btn-link"> Edit </button>
      <button (click)="invock('delete')" class="btn btn-link text-danger"> Delete </button>
    </span>
  `
})

export class TotalValueRenderer implements ICellRendererAngularComp {

  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public invock(action: string): void {
    this.params.context.componentParent.actionsGrid({ row: this.params.data, action: action });
  }

  refresh(): boolean {
    return false;
  }

}
