import { Pipe, PipeTransform } from '@angular/core';
import { LastStatus } from 'src/app/core/models/donation-service-model';

@Pipe({ name: 'status' })

export class StatusPipe implements PipeTransform {

  transform(statusLogs: any[], status: number) {
    const statusData = statusLogs?.find((item: LastStatus) => item.status === status);
    return statusData || {};
  }

}
