<div [class.disabled]="disabled || (form && (form.disabled || formGroup.controls[name].disabled))">
  <label *ngIf="label" [for]="name + ids" class="form-label"> {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span> </label>
  <div *ngIf="form" [formGroup]="formGroup" [class.disabled]="disabled" class="bg-white rounded">
    <div class="price-section align-items-center border d-flex ps-2 py-1">
      <input #inputRef (input)="onInputChange()" (keydown)="tools.checkNumberValidate($event)" type="text" [name]="name" [value]="priceConverted" [placeholder]="placeholder" [id]="name + ids"
             maxlength="15" [attr.disabled]="disabled || form.disabled ? true : null" [class.disabled]="disabled" class="form-control border-0 flex-fill {{ dropdownItems && dropdownItems.length ? '' : 'ltr' }}" />
      <ng-container *ngTemplateOutlet="dropdown"> </ng-container>
    </div>
    <div *ngIf="required && form.submitted && formGroup.controls[name].errors &&
    (formGroup.controls[name].status === 'INVALID' || !formGroup.controls[name].touched || formGroup.controls[name].pristine)" class="invalid-feedback d-block">
      {{ label | translate }} {{ getErrorText() | translate }}
    </div>
  </div>
  <div *ngIf="!form" [class.disabled]="disabled" class="bg-white rounded">
    <div class="price-section align-items-center border d-flex ps-2 py-1">
      <ng-container *ngIf="!disabled; else isDisabled">
        <input #inputRef (input)="onInputChange()" (keydown)="tools.checkNumberValidate($event)" type="text" [name]="name" [value]="priceConverted" [placeholder]="placeholder" [id]="name + ids"
               maxlength="15" [attr.disabled]="disabled ? true : null" [class.disabled]="disabled" class="form-control border-0 flex-fill {{ dropdownItems && dropdownItems.length ? '' : 'ltr' }}" />
      </ng-container>
      <ng-template #isDisabled>
        <input (ngModelChange)="valueChange.emit(+value)" [(ngModel)]="value" type="text" [name]="name" [placeholder]="placeholder" [id]="name + ids"
               maxlength="15" [attr.disabled]="disabled ? true : null" [class.disabled]="disabled" class="form-control border-0 flex-fill {{ dropdownItems && dropdownItems.length ? '' : 'ltr' }}" />
      </ng-template>
      <ng-container *ngTemplateOutlet="dropdown"> </ng-container>
    </div>
  </div>
</div>

<ng-template #dropdown>
  <div *ngIf="dropdownItems && dropdownItems.length" class="align-items-center gap-2 d-flex">
    <div ngbDropdown placement="bottom-right" class="dropdown-menu-end d-inline-block">
      <button ngbDropdownToggle type="button" class="btn btn-filter align-items-center justify-content-around d-flex" [disabled]="dropdownDisabled">
        <ng-container *ngIf="dropdownItemSelected"> {{ (dropdownItemSelected[bindLabel] || dropdownItemSelected.symbol || 'UNIT') | translate }} </ng-container>
        <ng-container *ngIf="!dropdownItemSelected"> {{ dropdownItems[0][bindLabel] | translate }} </ng-container>
      </button>
      <div ngbDropdownMenu>
        <button *ngFor="let item of dropdownItems; let i = index" ngbDropdownItem (click)="itemClicked(item)" type="button"> {{ item[bindLabel] || item.symbol }} </button>
      </div>
    </div>
  </div>
</ng-template>
