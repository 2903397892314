import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-drag-scroll",
  templateUrl: "./drag-scroll.component.html",
  styleUrls: ["./drag-scroll.component.scss"],
})
export class DragScrollComponent implements AfterViewInit {
  @ViewChild("dragScroll") dragScroll: ElementRef;
  slider;
  isDown = false;
  startX;
  scrollLeft;

  ngAfterViewInit(): void {
    this.slider = this.dragScroll.nativeElement;
    this.slider.addEventListener("mousedown", (e) => {
      this.isDown = true;
      this.startX = e.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    });
    this.slider.addEventListener("mouseleave", () => {
      this.isDown = false;
    });
    this.slider.addEventListener("mouseup", () => {
      this.isDown = false;
    });
    this.slider.addEventListener("mousemove", (e: any) => {
      if (!this.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.slider.offsetLeft;
      const walk = x - this.startX;
      // const walk = (x - startX) * 3; scroll-fast
      this.slider.scrollLeft = this.scrollLeft - walk;
    });
  }
}
