import { Component, OnInit, AfterContentInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})

export class TimepickerComponent implements OnInit, AfterContentInit {

  formGroup: FormGroup;
  error: string;
  @Input() form: FormGroupDirective;
  @Input() name: string;
  @Input() ids: string;
  @Input() label: string;
  @Input() isDay: boolean;
  @Input() required: boolean;

  constructor(private formBuilder: FormBuilder) {
    this.error = this.name = this.ids = this.label = '';
    this.isDay = this.required = false;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      days: null,
      hours: null,
      minutes: null
    });
  }

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup.patchValue(this.form.form.value[this.name]);
    }
  }

  onChange(name: string, value: any): void {
    switch (name) {
      case 'days': +value > 30 ? this.formGroup.controls[name].setValue(30) : +value; break;
      case 'hours': +value > 23 ? this.formGroup.controls[name].setValue(23) : +value; break;
      case 'minutes': +value > 59 ? this.formGroup.controls[name].setValue(59) : +value; break;
      default: break;
    }
    const temp = this.isDay ? this.formGroup.value : (this.formGroup.value.hours + ':' + this.formGroup.value.minutes);
    this.form.form.controls[this.name].setValue(temp);
  }

}
