import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <span>
      <div [ngStyle]="{ 'min-width': params.colDef['customWidth'] ? params.colDef['customWidth'] + 'px' : 'auto' }"
        class="theme {{
          params.colDef.field ? theme : params.data[theme]
        }} align-items-center justify-content-center d-flex my-2"
      >
        {{ params.data[status] || params.data["statusName"] | translate }}
      </div>
    </span>
  `,
  styleUrls: ["./grid-table-status.scss"],
})
export class StatusRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  status = "statusName";
  theme = "theme";

  agInit(params: ICellRendererParams): void {
    if (params.colDef.field) {
      this.status = params.colDef.field;
      this.theme = params.data["statusTheme"];
    }
    this.params = params;
  }

  public invock(action: string): void {}

  refresh(): boolean {
    return false;
  }
}
