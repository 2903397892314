import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ToolsService } from 'src/app/core/services/tools.service';

@Component({
  selector: 'app-btn-custom',
  templateUrl: './btn-custom.component.html',
  styleUrls: ['./btn-custom.component.scss']
})

export class BtnCustomComponent implements OnInit, OnChanges {

  @Input() type = 'button';
  @Input() theme = { type: '', color: '', size: '', icon: '', iconOrder: 0, text: '', rate: 0 };
  @Input() classList = '';
  @Input() loading = false;
  @Input() disabled = false;
  @Output() onClick = new EventEmitter()

  constructor(public toolsService: ToolsService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
  }

  onClickBtn() {
    this.onClick.emit()
  }



}
