import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: `
    <span>
      <div [class]="'form-check ps-4 ' + (params['label'] ? 'align-items-center gap-2 d-flex' : 'mt-2')">
        <input
          type="checkbox"
          [(ngModel)]="params.data[params.colDef.field]"
          [disabled]="params['disabled']"
          (change)="invock($event, 'check')"
          class="form-check-input"
        />
        <span *ngIf="params['label']"> {{ params['label'] }} </span>
      </div>
    </span>
  `,
})
export class CheckboxRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public invock(event, action: string): void {
    this.params.context.componentParent.actionsGrid({
      rowIndex: this.params.rowIndex,
      row: this.params.data,
      action: action,
      field: this.params.colDef.field
    });
  }

  refresh(): boolean {
    return false;
  }
}
