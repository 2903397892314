import { Component, Input } from "@angular/core";

@Component({
  selector: "app-group-attach",
  templateUrl: "./group-attach.component.html",
  styleUrls: ["./group-attach.component.scss"],
})
export class GroupAttachComponent {
  @Input() uploadGroups: Array<{ id: number; title: string }>;

  attachFile(id: number, file): void {
    const formData = new FormData();
    formData.append(
      "file",
      file[file.length - 1].file,
      file[file.length - 1].file.name
    );
    // this.api.blob(formData, '/blob/Blob/Upload').pipe(map(() => console.log('DEPARTMENT_ID', id))).subscribe();
  }
}
