import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TreeComponent, TreeNode } from "@circlon/angular-tree-component";

@Component({
    template: `
    <span>
      <div class="tree-dropdown row align-items-center gx-0">
    <button *ngIf="defaultTitle" id="custom" class="btn-close btn btn-custom ms-auto"
        (click)="clearTitle=true;" type="button"></button>
    <button (click)="clearTitle=false;onToggle($event)"
        class="accordion-button default {{acc.activeIds[0]==='toggle-1'?'':'collapsed'}} w-100 text-start" type="button">
        <span class="btn-text"> {{defaultTitle}}</span>
        <i class="bx bxs-down-arrow custom-arrow"></i>
    </button>
    <ngb-accordion #acc="ngbAccordion" class="acc" [class.border]="acc.activeIds[0]==='toggle-1'">
        <ngb-panel id="toggle-1">
            <ng-template ngbPanelContent>
                <search-field *ngIf="hasSearch" placeholder="SERACH_BY_TITLE" class="flex-fill"
                    (onSearch)="tree.treeModel.filterNodes($event)"></search-field>
                <tree-root #tree [focused]="true" [nodes]="nodes">
                </tree-root>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
    </span>
  `,
})
export class TreeRenderer implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    // button: any;
    // placement = "bottom-right";
    // dropdownItems: string[] = [];
    // selectedItem: any;
    // bindValue = "id";
    // bindLabel = "name";
    // clearable = true;

    // @Input() classList: string;
    @ViewChild('acc') acc;
    tree: TreeComponent;
    clearTitle = false;
    ids = "";
    defaultTitle = "";
    hasCheckbox = false;
    hasSearch = false;
    disabled = false;
    nodes = [];
    selectedNode: TreeNode;
    @Input() isEditting: boolean;
    displayField = "name";
    @Input() reset: string;
    @Output() resetChange = new EventEmitter();
    @Output() selectNode = new EventEmitter();
    @Output() clear = new EventEmitter();

    agInit(params: ICellRendererParams): void {
        // this.params = params;
        // if (this.params.data.clearable === "false") {
        //     this.clearable = false;
        // }
        // this.selectedItem = this.params.data.selectedItem ?? this.selectedItem;
        // this.bindLabel = this.params.data?.bindLabel ?? this.bindLabel;
        // this.bindValue = params.data?.bindValue;
    }

    public invock(): void {
        
    }

    refresh(): boolean {
        return false;
    }

    onToggle(e) {
        if (this.clearTitle) {
            e.preventDefault();
            return
        }
        this.acc.toggle('toggle-1')
    }
}
