import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToolsService } from 'src/app/core/services/tools.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router, private tools: ToolsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tools.getToken()) {
      return true;
    }
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } }).then(() => {});
    return false;
  }

}
