import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class AccountingService {

  constructor(private api: ApiService) { }

  currencyFind<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v1/Currency/Find', 'accounting');
  }

  fiscalYearFindFiscalYear<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v1/FiscalYear/FindFiscalYear', 'accounting');
  }

  accountAccountTree<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/Account/AccountTree', 'accounting');
  }

  accountGetAllAccountTree<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/v3/Account/GetAllAccountTree/${ query }`, 'accounting');
  }

  accountGetAccountsBalance<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/Account/GetAccountsBalance', 'accounting');
  }

  accountCAccountGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/AccountCAccount/Get', 'accounting');
  }

  accountCAccountFilterByAccount<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/AccountCAccount/FilterByAccount', 'accounting');
  }

  accountCAccount2Get<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/AccountCAccount2/Get', 'accounting');
  }

  accountCAccount2FilterByAccount<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/AccountCAccount2/FilterByAccount', 'accounting');
  }

  branchGetBranch<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/v3/Branch/GetBranch/${ query }`, 'accounting');
  }

  branchFindBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/Branch/FindBranch', 'accounting');
  }

  cAccountFind<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/CAccount/Find', 'accounting');
  }

  cAccount2Find<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/CAccount2/Find', 'accounting');
  }

  itemFindItem<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/Item/FindItem', 'warehousing');
  }

  itemCategoryFindItemCategory<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/ItemCategory/FindItemCategory', 'warehousing');
  }

  unitFindUnit<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/v3/Unit/FindUnit', 'warehousing');
  }

}
