import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  template: ` <app-btn-custom
    (onClick)="invock(button.action)"
    [disabled]="button.disabled"
    [theme]="{
      type: button.type,
      color: button.color,
      size: 'md',
      icon: button.icon,
      text: button.text
    }"
    classList="in-grid"
  >
  </app-btn-custom>`,
})
export class ButtonRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  button: any;

  agInit(params: ICellRendererParams): void {
    this.params = this.button = params;
  }

  public invock(action: string): void {
    this.params.context.componentParent.actionsGrid({
      row: this.params.data,
      action: action,
    });
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.button = params;
    return true;
  }
}
