import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileModel } from 'src/app/core/models/public-model';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss']
})

export class SelectFileComponent implements OnInit {

  env = environment.api;
  modalRef: NgbModalRef;
  reader = new FileReader();
  fileSelected: any;
  @Input() files: Array<FileModel> = [];
  @Input() disabled: boolean;
  @Output() filesChange: EventEmitter<FileModel[]> = new EventEmitter();
  @ViewChild('confirmModal') confirmModal: ElementRef;
  @ViewChild('profileImage') profileImage: ElementRef;

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {
    if (this.files.length) {
      this.files.map((item) => item.domain = this.env + `/blob/`);
    }
  }

  openModal(content: any, size = 'lg'): void {
    this.modalRef = this.modal.open(content, { size: size, centered: true });
    this.modalRef.dismissed.pipe(map(() => {})).subscribe();
  }

  onSelectFile(event: Event): void {
    if (['jpg', 'jpeg', 'png', 'jfif', 'gif'].includes((event.target as HTMLInputElement).files[0].name.split('.')[1])) {
      const temp: FileModel = {};
      temp.file = (event.target as HTMLInputElement).files[0];
      this.reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
      this.reader.onload = (event) => {
        temp.url = event.target.result as string;
        this.files.push(temp);
        this.filesChange.emit(this.files);
        this.profileImage.nativeElement.value = '';
      };
    }
  }

  onRemoveImage(index: number): void {
    this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
      if (res === 'confirm') {
        this.profileImage.nativeElement.value = '';
        this.filesChange.emit(this.files);
        this.files.splice(index, 1);
      }
      else {
        return of([]);
      }
    })).subscribe();
  }

}
