import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpResponseModel } from "../models/public-model";
import { ToolsService } from "./tools.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  urls = {
    api: environment.api,
    account: environment.account,
    accounting: environment.accounting,
    warehousing: environment.warehousing,
  };

  constructor(private http: HttpClient, private toolsService: ToolsService) {}

  get<T>(
    data: any,
    url: string,
    domain: string = "api"
  ): Observable<{ data: T }> {
    let queryParams = "";
    for (let key in data) {
      queryParams += key + "=" + data[key] + "&";
    }
    queryParams = queryParams.substring(0, queryParams.length - 1);
    queryParams = queryParams.replace(/_/g, ".");
    url =
      url.indexOf("?") >= 0 ? url + `&${queryParams}` : url + "?" + queryParams;
    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }
    this.toolsService.isLoadingApi = true;
    return this.http.get<T>((domain ? `${this.urls[domain]}` : "") + url).pipe(
      map((res: HttpResponseModel<T>) => {
        this.toolsService.isLoadingApi = false;
        return { data: res.data };
      }), catchError(() => {
        this.toolsService.isLoadingApi = false;
        return of({ data: null });
      })
    );
  }

  post<T>(
    data: any,
    url: string,
    domain: string = "api"
  ): Observable<{ data: T; total?: number }> {
    if (!data.pagination && (url.indexOf('filter') !== -1 || url.indexOf('Filter') !== -1)) {
      const array = [
        { service: '/Hr/Employee/Filter', additionalProp: 'employeeId' }
      ];
      const temp = array.find((item) => url.indexOf(item.service) !== -1);
      const sortProperties = !temp ? { id: 1 } : { [temp.additionalProp]: 1 };
      data.pagination = { take: 500, skip: 0, sortProperties };
    }
    const headers = { "Content-Type": "application/json" };
    this.toolsService.isLoadingApi = true;
    return this.http
      .post<T>(
        (domain ? `${this.urls[domain]}` : "") + url,
        JSON.stringify(data),
        { headers }
      )
      .pipe(
        map((res: HttpResponseModel<T>) => {
          if (!res.data) {
            const temp: any = res;
            res = {};
            res.data = temp;
          }
          this.toolsService.isLoadingApi = false;
          return { data: res.data, total: res?.pagination?.total };
        }), catchError(() => {
          this.toolsService.isLoadingApi = false;
          return of({ data: null, total: null });
        })
      );
  }

  put<T>(
    data: any,
    url: string,
    domain: string = "api"
  ): Observable<{ data: T; total?: number }> {
    const headers = { "Content-Type": "application/json" };
    this.toolsService.isLoadingApi = true;
    return this.http
      .put<T>(
        (domain ? `${this.urls[domain]}` : "") + url,
        JSON.stringify(data),
        { headers }
      )
      .pipe(
        map((res: HttpResponseModel<T>) => {
          if (!res.data) {
            const temp: any = res;
            res = {};
            res.data = temp;
          }
          this.toolsService.isLoadingApi = false;
          return { data: res.data, total: res?.pagination?.total };
        }), catchError(() => {
          this.toolsService.isLoadingApi = false;
          return of({ data: null, total: null });
        })
      );
  }

  update<T>(data: any, url: string, domain: string = "api"): Observable<T> {
    const headers = { "Content-Type": "application/json" };
    return this.http.put<T>(
      (domain ? `${this.urls[domain]}` : "") + url,
      JSON.stringify(data),
      { headers }
    );
  }

  delete<T>(
    data: any = {},
    url: string,
    domain: string = "api"
  ): Observable<T> {
    const headers = { "Content-Type": "application/json" };
    this.toolsService.isLoadingApi = true;
    return this.http
      .delete<T>((domain ? `${this.urls[domain]}` : "") + url, {
        headers,
        body: data,
      })
      .pipe(
        map(() => {
          this.toolsService.isLoadingApi = false;
        }), catchError(() => {
          this.toolsService.isLoadingApi = false;
          return of(null);
        })
      );
  }

  blob<T>(data: any, url: string): Observable<T> {
    return this.http.post<T>(this.urls.api + url, data).pipe();
  }

  accPost<T>(
    data: any,
    url: string,
    domain: string = "accounting"
  ): Observable<{ data: T }> {
    const headers = { "Content-Type": "application/json" };
    this.toolsService.isLoadingApi = true;
    return this.http
      .post<T>(
        (domain ? `${this.urls[domain]}` : "") + url,
        JSON.stringify(data),
        { headers }
      )
      .pipe(
        map((res: HttpResponseModel<T>) => {
          this.toolsService.isLoadingApi = false;
          return { data: res.data };
        }), catchError(() => {
          this.toolsService.isLoadingApi = false;
          return of({ data: null });
        })
      );
  }

  login<T>(data: any, url: string, domain: string = "api") {
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const body = new URLSearchParams();
    for (let key in data) {
      body.set(key, data[key]);
    }
    return this.http
      .post<T>((domain ? `${this.urls[domain]}` : "") + url, body.toString(), {
        headers,
      })
      .pipe();
  }

  filterSettings<T>(data: {
    identifier: string;
    title?: string;
    searchableFieldName?: string;
    keywords?: string;
  }): Observable<{ data: T; total?: number }> {
    const headers = { "Content-Type": "application/json" };
    this.toolsService.isLoadingApi = true;
    return this.http
      .post(
        `${this.urls.api}/Family/Setting/FilterSettingData`,
        JSON.stringify(data),
        { headers }
      )
      .pipe(
        map((res: HttpResponseModel<T>) => {
          if (!res.data) {
            const temp: any = res;
            res = {};
            res.data = temp;
          }
          this.toolsService.isLoadingApi = false;
          return { data: res.data, total: res?.pagination?.total };
        }), catchError(() => {
          this.toolsService.isLoadingApi = false;
          return of({ data: null, total: null });
        })
      );
  }

  getFamilyByIdentifiers(identifiers: string[], model: any[]) {
    if (identifiers.length === 0) {
      return of([]);
    }
    return this.post<any[]>(
      { identifiers },
      "/Family/Family/GetByIdentifiers"
    ).pipe(
      map((res) => res.data),
      map((res) => {
        model.map((m) => {
          const family = res.find((r) => r.identifier === m.familyIdentifier);
          m["familyId"] = family?.id;
          m["familyCode"] = family?.code;
          m["supervisorUserIdentifier"] = family?.supervisorUserIdentifier;
          m["supervisorMemberIdentifier"] = family?.supervisorIdentifier;
          m["supervisor"] = family?.supervisor;
          m["supervisorPhone"] = family?.supervisorPhone;
          m["beneficiaryType"] = family?.beneficiaryType;
          m["geoName"] = family?.geoName;
          m["latitude"] = family?.latitude;
          m["longitude"] = family?.longitude;
          m["address"] = family?.address;
          m["phone"] = family?.phone;
        });
        return model;
      })
    );
  }

  getMembersByIdentifiers(identifiers: string[], model: any[]) {
    if (identifiers.length === 0) {
      return of([]);
    }
    return this.post<any[]>(
      { identifiers },
      "/Family/FamilyMember/GetByIdentifiers"
    ).pipe(
      map((res) => res.data),
      map((res) => {
        model.map((m) => {
          const member = res.find(
            (r) => r.identifier === m.familyMemberIdentifier
          );
          m["familyCode"] = member?.familyCode;
          m["familyMemberCode"] = member?.code;
          m["familyMemberUserIdentifier"] = member?.userIdentifier;
          m["beneficiaryName"] = member?.fullName;
        });
        return model;
      })
    );
  }
}
