<div *ngIf="form" [formGroup]="formGroup" [class.disabled]="disabled || (form && (form.disabled || formGroup.controls[name].disabled))" class="{{ classList }}">
  <label *ngIf="label" [for]="name + ids" class="form-label"> {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span> </label>
  <ng-container *ngIf="type === 'phone'">
    <input #input (input)="onPhoneChange()" (focus)="onPhoneTglFocus(formControl)" (blur)="onPhoneTglFocus(formControl)" (keydown)="tools.checkNumberValidate($event)" type="text"
           [formControlName]="name" [placeholder]="!phoneFocused && !formControl?.value ? placeholder : ''" [id]="name + ids" maxlength="10"
           [attr.disabled]="disabled ? true : null" [ngClass]="{ 'disabled': disabled, 'px-5': phoneFocused || formControl?.value }" class="form-control text-left direction-ltr" />
    <span *ngIf="phoneFocused || formControl?.value" class="text-prefix"> +964 </span>
  </ng-container>
  <input #input *ngIf="type === 'text'" (ngModelChange)="valueChange.emit(value)" [type]="inputType" [name]="name" [formControlName]="name" [placeholder]="placeholder"
         [id]="name + ids" [attr.disabled]="disabled ? true : null" [ngClass]="{ 'disabled': disabled, 'is-invalid': isInvalid() }" class="form-control {{ ltr ? 'ltr' : '' }}" />
  <input #input *ngIf="type === 'email'" [type]="inputType" [name]="name" [formControlName]="name" [placeholder]="placeholder" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
         [id]="name + ids" [attr.disabled]="disabled ? true : null" [ngClass]="{ 'disabled': disabled, 'is-invalid': isInvalid() }" class="form-control {{ ltr ? 'ltr' : '' }}" />
  <textarea *ngIf="type === 'textarea'" [rows]="rows" [name]="name" [formControlName]="name" [id]="name + ids" [attr.disabled]="disabled ? true : null"
            [ngClass]="{ 'disabled': disabled, 'is-invalid': isInvalid() }" class="form-control"> </textarea>
  <div *ngIf="(form.submitted && formGroup.controls[name].invalid) || formGroup.controls[name].invalid && (formGroup.controls[name].dirty || formGroup.controls[name].touched)" class="invalid-feedback d-block">
    <ng-container *ngIf="formGroup.controls[name].errors?.required"> {{ label | translate }} {{ getErrorText() | translate }} </ng-container>
    <ng-container *ngIf="formGroup.controls[name].errors?.email"> {{ getErrorText() | translate }} </ng-container>
    <ng-container *ngIf="type === 'phone' && formGroup.controls[name].errors?.mask"> {{ label | translate }} {{ getErrorText() | translate }} </ng-container>
    <ng-container *ngIf="formGroup.controls[name].errors?.custom"> {{ getErrorText() | translate }} </ng-container>
  </div>
</div>
<div *ngIf="!form" [class.disabled]="disabled" class="{{ classList }}">
  <label *ngIf="label" [for]="name + ids" class="form-label"> {{ label | translate }} <span *ngIf="required" class="text-danger"> * </span> </label>
  <input *ngIf="type === 'text'" (ngModelChange)="valueChange.emit(value)" [(ngModel)]="value" [type]="inputType" [placeholder]="placeholder"
         [id]="name + ids" [attr.disabled]="disabled ? true : null" class="form-control {{ ltr ? 'ltr' : '' }}" />
  <textarea *ngIf="type === 'textarea'" [rows]="rows" [id]="ids" [attr.disabled]="disabled ? true : null" class="form-control"> </textarea>
</div>
