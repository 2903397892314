import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { ToolsService } from 'src/app/core/services/tools.service';

@Injectable()

export class ErrorInterceptor implements HttpInterceptor {

  constructor(private tools: ToolsService, private translatePipe: TranslatePipe) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map((res) => {
      if (res['status'] === 200) {
        const url = request.url.toLowerCase();
        if (url.includes('create')) {
          this.tools.toastSuccess('SUCCESSFULLY_CREATED');
        }
        else if (url.includes('update')) {
          this.tools.toastSuccess('SUCCESSFULLY_UPDATED');
        }
        else if (url.includes('delete')) {
          this.tools.toastSuccess('SUCCESSFULLY_DELETED');
        }
      }
      return res;
    }), catchError((err) => {
      if (err.status === 401) {
        this.tools.logout();
      }
      else if (err.status === 400 || err.status === 404) {
        this.tools.toastError(this.translatePipe.transform(err.error.error_description) || err.error.meta.errorMessage || err.error.meta.errors[0].errors[0] || 'SERVER_ERROR_2');
      }
      else {
        this.tools.toastError('SERVER_ERROR_2');
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }

}
