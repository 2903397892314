import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, forkJoin } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { AccountingService } from 'src/app/core/api/accounting.service';
import { AccountService } from 'src/app/core/api/account.service';
import { ToolsService } from 'src/app/core/services/tools.service';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { Utils } from 'src/app/core/helpers/utils';
import { SETTING_IDS } from 'src/app/core/constants';
import { Ancestry, Gender, UserTabs, Roles, ActiveStatuses, Apps } from 'src/app/core/models/enums';
import { PublicModel } from 'src/app/core/models/public-model';
import { PermissionModel, RoleFilterModel, RoleGetModel } from 'src/app/core/models/auth.models';
import { BranchModel, UserModel } from 'src/app/core/models/donation-financial-model';
import { PagingModel } from 'src/app/core/models/paging.model';

@Component({
  selector: 'app-user-modal',
  templateUrl: 'user-modal.component.html',
  styleUrls: ['user-modal.component.scss']
})

export class UserModalComponent implements OnInit {

  personInfoForm: FormGroup;
  organizationalInfoForm: FormGroup;
  userInfoForm: FormGroup;
  modalRef: NgbModalRef;
  userPrefixes$: Observable<PublicModel[]>;
  jobs$: Observable<PublicModel[]>;
  branches$: Observable<BranchModel[]>;
  ancestryItems = Ancestry;
  genderItems = Gender;
  tabs = UserTabs;
  activeTab = UserTabs.PERSON_INFO;
  disableTabs: number[];
  roles = [{ id: 2, name: 'ADMIN', active: false }]; // Utils.enumToArray(Roles);
  permissions: PermissionModel[];
  permissionsFiltered: PermissionModel[];
  permissionFilterSelected: { active?: any, appId?: any };
  isBranch: boolean;
  permissionIsSelected: boolean;
  employeeCreateStatus: boolean = false;
  branchAddStatus: boolean = false;
  passwordSetStatus: boolean = false;
  isOnlyShowPersonalInfo: boolean = false;
  activeStatuses = [
    { id: -1, text: 'ALL', active: true },
    { id: 0, text: 'DE_ACTIVE', active: false },
    { id: 1, text: 'ACTIVE', active: false }
  ];
  appStatuses = Apps;
  branchIsDisabled: boolean = this.toolsService.branchIsDisabled();
  paging: PagingModel = { take: 300, skip: 0, sortProperties: { title: 1 }};
  @Input() userSelected: UserModel = {};
  @Output() dismissed = new EventEmitter;
  @ViewChild('userModal') userModal: ElementRef;
  @ViewChild('confirmModal') confirmModal: ElementRef;

  constructor(private formBuilder: FormBuilder, private modal: NgbModal, private api: ApiService, private accountingService: AccountingService,
    private accountService: AccountService, private toolsService: ToolsService,
    private translatePipe: TranslatePipe) {
    this.personInfoForm = this.formBuilder.group({
      userIdentifier: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: null,
      jobId: [null, Validators.required],
      userPrefixId: null,
      ancestry: [null, Validators.required],
      gender: null,
      maritalStatus: null,
      birthDate: new Date(),
      isActive: false
    });
    this.organizationalInfoForm = this.formBuilder.group({
      branchId: [this.toolsService.getSelectedBranch()?.id, Validators.required]
    });
    this.userInfoForm = this.formBuilder.group({
      phone: [null, Validators.required],
      newPassword: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
    this.disableTabs = [UserTabs.USER_INFO, UserTabs.SINGLE_PERMISSIONS]; // UserTabs.ORGANIZATIONAL_INFO
    this.roles = this.roles.filter((role) => role.name !== Roles[8]);
    this.permissions = [];
    this.permissionsFiltered = [...this.permissions];
    this.permissionFilterSelected = { active: null, appId: null };
    this.isBranch = false;
    this.permissionIsSelected = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.userSelected?.userIdentifier) {
      this.disableTabs = [];
      forkJoin({
        userPrefix: this.userPrefixes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.userPrefixes),
        job: this.getJobs(),
        branch: this.getBranches(),
        permission: this.getPermissions(),
        permissionGroup: this.getPermissionGroups()
      }).pipe(concatMap(() => {
        return forkJoin([ this.getUserByIdentifier(), this.getUserRoles().pipe(concatMap(() => this.getUserAllPermission())) ]).pipe(map(() => { // this.getUserBranches(),
          this.userSelected.employeeCode || this.userSelected.branch ? this.disableTabs = [] : null;
          this.openModal(this.userModal);
        }));
      })).subscribe();
    }
    else {
      forkJoin({
        userPrefix: this.userPrefixes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.userPrefixes),
        job: this.getJobs(),
        branch: this.getBranches(),
        permission: this.getPermissions(),
        permissionGroup: this.getPermissionGroups()
      }).pipe(map(() => this.openModal(this.userModal))).subscribe();
    }
  }

  openModal(content: any, size = 'lg'): void {
    this.modalRef = this.modal.open(content, { size, centered: true });
    this.modalRef.dismissed.pipe(map(() => this.dismissed.emit())).subscribe();
  }

  onSearch(permissionName?: string) {
    if (!permissionName) {
      this.permissionsFiltered = [...this.permissions];
      return;
    }
    this.permissionsFiltered = this.permissions.filter((item) => item.titleTranslated.includes(permissionName));
  }

  onPermissionSort(type: string, event: any): void {
    this.permissionFilterSelected[type] = event.id !== -1 ? event : null;
    const sortSelected = this.permissionFilterSelected;
    if (event.id !== -1) {
      if (sortSelected.active && sortSelected.appId)
        this.permissionsFiltered = this.permissions.filter((item) => item.active === (sortSelected.active?.id === ActiveStatuses.ACTIVE) && item.appId === sortSelected.appId?.id);
      else {
        switch (type) {
          case 'active': this.permissionsFiltered = this.permissions.filter((item) => item.active === (event.id === ActiveStatuses.ACTIVE)); break;
          case 'appId': this.permissionsFiltered = this.permissions.filter((item) => item.appId === event.id); break;
          default: break;
        }
      }
    }
    else {
      if (sortSelected.active) this.permissionsFiltered = this.permissions.filter((item) => item.active === (sortSelected.active?.id === ActiveStatuses.ACTIVE));
      else if (sortSelected.appId) this.permissionsFiltered = this.permissions.filter((item) => item.appId === sortSelected.appId?.id);
      else this.permissionsFiltered = [...this.permissions];
    }
  }

  onLinkButton(name: string): void {
    switch (name) {
      case 'userPrefixes': window.open('/settings/dynamic/' + SETTING_IDS.userPrefixes + '/grid', '_blank'); break;
      case 'jobs': window.open('/settings/dynamic/' + SETTING_IDS.jobs + '/tree', '_blank'); break;
      default: break;
    }
  }

  filterSettings<T>(identifier: string) {
    return this.api.filterSettings<T>({ identifier }).pipe(map((res) => res.data), shareReplay(), catchError(() => of([])));
  }

  getUserByIdentifier() {
    return this.accountService.userGetByIdentifier({}, this.userSelected?.userIdentifier).pipe(map((res) => {
      res.data['gender'] = res.data['gender'] || null;
      this.personInfoForm.patchValue(res.data);
      this.userInfoForm.controls['phone'].setValue(this.personInfoForm.value.phone);
      for (const key of Object.keys(this.userInfoForm.controls)) {
        this.userInfoForm.controls[key].removeValidators(Validators.required);
        this.userInfoForm.controls[key].updateValueAndValidity();
      }
      this.employeeCreateStatus = true;
      this.isOnlyShowPersonalInfo = res.data['onlyShowPersonalInfo'];
    }), shareReplay(), catchError(() => of([])));
  }

  getJobs() {
    return this.jobs$ = this.filterSettings<PublicModel[]>(SETTING_IDS.jobsTree).pipe(map((data) => {
      data = Utils.convertAccountToTree(data, null, 'ParentId', 'Id');
      data = data.filter((item: any) => item.children.length);
      return data;
    }), shareReplay(), catchError(() => of([])));
  }

  getBranches() {
    return this.branches$ = this.accountingService.branchFindBranch<BranchModel[]>({}).pipe(map((res) => res.data), shareReplay(), catchError(() => of([])));
  }

  getUserBranches() {
    return this.accountService.userGetBranches<any[]>({}, this.userSelected?.userIdentifier).pipe(map((res) => {
      if (res.data && res.data.length) {
        this.organizationalInfoForm.patchValue(res.data[0]);
        this.disableTabs = [];
        this.isBranch = true;
        this.branchAddStatus = true;
      }
      else {
        this.disableTabs = [UserTabs.USER_INFO, UserTabs.SINGLE_PERMISSIONS];
      }
    }), concatMap(() => {
      return this.accountService.userRoleGetByUserIdentifier<PublicModel[]>({}, this.userSelected).pipe(map((res) => {
        const ids: number[] = [];
        res.data.map((item) => item.id && !ids.includes(item.id) ? ids.push(item.id) : null);
        this.roles.map((item) => item.active = ids.indexOf(item.id) !== -1);
        this.checkUserRole();
      }));
    }), shareReplay(), catchError(() => of([])));
  }

  getUserRoles() {
    return this.accountService.userRoleGetByUserIdentifier<PublicModel[]>({}, this.userSelected).pipe(map((res) => {
      const ids: number[] = [];
      res.data.map((item) => item.id && !ids.includes(item.id) ? ids.push(item.id) : null);
      this.roles.map((item) => item.active = ids.indexOf(item.id) !== -1);
      this.permissionGroups.map((item) => item.active = ids.indexOf(item.id) !== -1);
      this.checkUserRole();
    }));
  }

  getPermissions() {
    return this.accountService.permissionFilter<PermissionModel[]>({ pagination: this.paging }).pipe(map((res) => {
      res.data.map((item) => {
        item.titleTranslated = this.translatePipe.transform(item.title);
        item.active = false;
      });
      this.permissions = res.data;
      this.permissionsFiltered = [...this.permissions];
    }), shareReplay(), catchError(() => of([])));
  }

  personInfoSubmit() {
    if (this.personInfoForm.invalid) {
      return false;
    }
    if (!this.userSelected?.userIdentifier) {
      const userExistsParam = { phone: this.personInfoForm.value.phone, firstName: this.personInfoForm.value.firstName, lastName: this.personInfoForm.value.lastName, role: 8 };
      this.accountService.userUserExists(userExistsParam).pipe(map((resUserExists) => resUserExists), concatMap((resUserExists) => {
        if (!resUserExists.data['identifier']) {
          return this.accountService.accountApiCreateEmployee(this.personInfoForm.value).pipe(map((resCreateEmployee) => {
            this.userSelected.userIdentifier = resCreateEmployee.data['userIdentifier'];
            this.userInfoForm.controls['phone'].setValue(this.personInfoForm.value.phone);
            // this.activeTab = UserTabs.ORGANIZATIONAL_INFO;
            this.disableTabs = [UserTabs.USER_INFO, UserTabs.SINGLE_PERMISSIONS];
            this.employeeCreateStatus = true;
          }), shareReplay(), catchError(() => of([])));
        }
        else {
          this.personInfoForm.controls['userIdentifier'].setValue(resUserExists.data['identifier']);
          return this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((resConfirm) => resConfirm), concatMap((resConfirm) => {
            if (resConfirm === 'confirm') {
              return this.accountService.userUpdateProfile(this.personInfoForm.value).pipe(map(() => {}), concatMap(() => {
                return this.accountService.accountApiCreateEmployee(this.personInfoForm.value).pipe(map((resCreateEmployee) => {
                  this.userSelected.userIdentifier = resCreateEmployee.data['userIdentifier'];
                  this.userInfoForm.controls['phone'].setValue(this.personInfoForm.value.phone);
                  // this.activeTab = UserTabs.ORGANIZATIONAL_INFO;
                  this.employeeCreateStatus = true;
                }), concatMap(() => {
                  return forkJoin([ this.getUserRoles(), this.getUserAllPermission() ]); // this.getUserBranches(),
                }), shareReplay(), catchError(() => of([])));
              }));
            }
            else {
              this.personInfoForm.controls['phone'].setValue(null);
              return of([]);
            }
          }));
        }
      })).subscribe();
    }
    else {
      this.personInfoForm.controls['userIdentifier'].setValue(this.userSelected?.userIdentifier);
      this.accountService.userUpdateProfile(this.personInfoForm.value).pipe(map(() => {
        this.userInfoForm.controls['phone'].setValue(this.personInfoForm.value.phone);
        // this.activeTab = UserTabs.ORGANIZATIONAL_INFO;
      }), shareReplay(), catchError(() => of([]))).subscribe();
    }
  }

  organizationalInfoSubmit() {
    if (this.organizationalInfoForm.invalid) {
      return false;
    }
    const param = { ...this.organizationalInfoForm.value, userIdentifier: this.userSelected?.userIdentifier };
    this.accountService.userAddToBranch(param).pipe(map(() => {
      this.isBranch = true;
      this.branchAddStatus = true;
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  selectUserRole(event: any): void {
    const role = +event.target.value;
    this.roles.map((item) => item.id === role ? item.active = event.target.checked : null);
    event.target.checked ? this.createUserRole(role).subscribe() : this.deleteUserRole(role).subscribe();
    this.checkUserRole();
  }

  checkUserRole(): void {
    if (this.disableTabs.length) {
      const activeItems = this.roles.filter((item) => item.active);
      this.disableTabs = activeItems.length ? [UserTabs.SINGLE_PERMISSIONS] : [UserTabs.USER_INFO, UserTabs.SINGLE_PERMISSIONS]
    }
  }

  createUserRole(role: number) {
    const param = { role, userIdentifier: this.userSelected?.userIdentifier, branchId: this.userSelected?.['branchId'] };
    return this.accountService.userRoleCreate(param).pipe(map(() => {}), shareReplay(), catchError(() => of([])));
  }

  deleteUserRole(role: number) {
    const param = { role, userIdentifier: this.userSelected?.userIdentifier };
    return this.accountService.userRoleDelete(param).pipe(map(() => {}), shareReplay(), catchError(() => of([])));
  }

  userInfoSubmit() {
    if ((this.disableTabs.length && !this.passwordSetStatus) || (this.userInfoForm.value.newPassword?.length && this.userInfoForm.value.confirmPassword?.length)) {
      if (this.userInfoForm.invalid) {
        return false;
      }
      if (this.userInfoForm.value.newPassword !== this.userInfoForm.value.confirmPassword) {
        this.toolsService.toast('error', 'PASSWORD_IS_NOT_MATCH');
        return false;
      }
      const param = { ...this.userInfoForm.value, userIdentifier: this.userSelected?.userIdentifier };
      this.accountService.userSetPassword(param).pipe(map(() => {
        this.activeTab = UserTabs.SINGLE_PERMISSIONS;
        this.disableTabs = [];
        this.passwordSetStatus = true;
      }), shareReplay(), catchError(() => of([]))).subscribe();
    }
    else {
      this.activeTab = UserTabs.SINGLE_PERMISSIONS;
    }
  }

  selectPermission(event: any): void {
    const permission = +event.target.value;
    const IsPermissionInRole = !!this.permissionsByGroupId.find((item) => item.permissionId === permission);
    this.permissions.map((item) => item.id === permission ? item.active = event.target.checked : null);
    this.permissionsByGroupId.map((item) => item.permissionId === permission ? item.active = event.target.checked : null);
    if (!IsPermissionInRole) {
      event.target.checked ? this.addPermission([permission], false, { isAllowed: true }) : this.removePermission(permission);
      this.checkPermission();
    }
    else {
      event.target.checked ? this.removePermission(permission) : this.addPermission([permission], false, { isDenied: true });
    }
  }

  selectPermissionAll(): void {
    const temp = [];
    this.permissionsFiltered.map((item) => !item.active ? temp.push(item.id) : null);
    this.onPermissionSort('active', { id: -1, text: 'ALL', active: true });
    this.addPermission(temp, true);
    this.permissionIsSelected = true;
    this.activeStatuses.map((item) => item.active = item.id === -1);
  }

  checkPermission(): void {
    this.permissionIsSelected = false;
    this.permissions.find((item) => item.active ? this.permissionIsSelected = true : null);
  }

  addPermission(permissionIds: number[], refresh?: boolean, other?: any): void {
    const param = { permissionIds, userIdentifier: this.userSelected?.userIdentifier, branchId: this.userSelected?.['branchId'], ...other };
    this.accountService.userAddPermission(param).pipe(map(() => {
      if (refresh) this.permissionsFiltered.map((item) => item.active = true);
      this.toolsService.toast('success', 'SUCCESSFULLY_CREATED');
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  removePermission(permissionId: number): void {
    const param = { permissionId, userIdentifier: this.userSelected?.userIdentifier, branchId: this.userSelected?.['branchId'] };
    this.accountService.userRemovePermission(param).pipe(map(() => this.toolsService.toast('success', 'SUCCESSFULLY_CREATED')), shareReplay(), catchError(() => of([]))).subscribe();
  }

  selectDataFiltered(event: any): void {
    const param = { userIdentifier: this.userSelected?.userIdentifier, onlyShowPersonalInfo: event.target.checked };
    this.accountService.userSetPermissionPolicy(param).pipe(map(() => this.toolsService.toast('success', 'SUCCESSFULLY_CREATED'))).subscribe();
  }

  onChangeBranchId(event: any): void {
    this.userSelected['branchId'] = event.id;
    this.activeStatuses.map((item) => item.active = item.id === -1);
    this.permissionGroups.map((item) => item.active = false);
    this.permissionsByGroupId = [];
    this.onPermissionSort('appId', { id: -1, text: 'ALL', active: true });
    forkJoin([ this.getUserByIdentifier(), this.getUserRoles().pipe(concatMap(() => this.getUserAllPermission())) ]).subscribe();
  }

  // --------------------------------------------------

  permissionGroups: RoleFilterModel[] = [];
  permissionsByGroupId: PermissionModel[] = [];
  groupIdOld: number = 0;

  selectPermissionGroup(event: any): void {
    const groupId = +event.target.value;
    if (event.target.checked) {
      this.permissionGroups.map((item) => item.active = item.id === groupId ? event.target.checked : false);
      if (this.permissionsByGroupId?.length) {
        this.deleteUserRole(this.groupIdOld).pipe(concatMap(() => this.selectPermissionGroup_(groupId))).subscribe();
      }
      else {
        this.selectPermissionGroup_(groupId).subscribe();
      }
    }
  }

  selectPermissionGroup_(groupId: number) {
    return this.createUserRole(groupId).pipe(concatMap(() => this.getUserAllPermission()));
  }

  getPermissionGroups() {
    return this.accountService.roleFilter<RoleFilterModel[]>({ pagination: this.paging }).pipe(map((res) => {
      this.permissionGroups = res.data.filter((item) => item.id !== Roles.ADMIN && !item.isSystemic);
    }), catchError(() => {
      this.permissionGroups = [];
      return of([]);
    }));
  }

  getPermissionsByGroupId(groupId: number) {
    this.groupIdOld = groupId;
    return this.accountService.roleGet<RoleGetModel>({}, groupId).pipe(map((res) => {
      res.data.rolePermissions.map((item) => item.titleTranslated = this.translatePipe.transform(item.title));
      this.permissionsByGroupId = res.data.rolePermissions;
    }), catchError(() => {
      this.permissionsByGroupId = [];
      return of([]);
    }));
  }

  getUserAllPermission() {
    return this.accountService.userGetAllPermission<PermissionModel[]>({}, this.userSelected).pipe(concatMap((res) => {
      const roleId = this.permissionGroups.find((item) => item.active)?.id;
      if (res.data && res.data.length) {
        this.permissions.map((items) => items.active = !!res.data.find((item) => items.id === item.permissionId));
        this.permissionsFiltered = [...this.permissions];
        if (roleId) {
          const ids: number[] = [];
          res.data.map((item) => item.permissionId && !ids.includes(item.permissionId) ? ids.push(item.permissionId) : null);
          return this.getPermissionsByGroupId(roleId).pipe(map(() => {
            this.permissionsByGroupId.map((item) => {
              if (ids.includes(item.permissionId)) item.active = true;
            });
          }));
        }
      }
      else {
        this.permissions.map((items) => items.active = false);
        this.permissionsFiltered = [...this.permissions];
      }
      return of(res.data);
    }), catchError(() => of([])));
  }

}
