import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Utils } from "src/app/core/helpers/utils";

@Component({
  selector: "app-dropdown",
  templateUrl: "dropdown.component.html",
  styleUrls: ["dropdown.component.scss"],
})
export class DropdownComponent
  implements OnInit, AfterContentInit, AfterViewInit, OnChanges
{
  subscription: Subscription;
  subject = new Subject();
  formGroup: FormGroup;
  @Input() form: FormGroupDirective;
  @Input() icon = "";
  @Input() label = "";
  @Input() bindLabel = "name";
  @Input() name = "";
  @Input() bindValue = "id";
  @Input() placeholder = "";
  @Input() multiple = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() isClearable = true;
  @Input() disabled = false;
  @Input() classList = "";
  @Input() dataType: string;
  @Input() items: any[] = [];
  @Input() hasPrint = false;
  @Input() selectedItem = {};
  @Input() bindLabel0: string;
  @Input() bindLabel2: string;
  @Input() key: string;
  @Input() key2: string;
  @Input() value: number;
  @Input() ids = "";
  @Input() disabledItem: any = 0;
  @Input() defaultSelectedItem: any = {};
  @Output() selectItem = new EventEmitter();
  @Output() search = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() openClick = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes && !changes.disabled?.firstChange) {
      this.changeInputState();
    }
    if (
      changes?.items &&
      this.items?.length &&
      this.formGroup?.value[this.name]
      && this.hasPrint
    ) {
      this.setExtraData(this.formGroup.value[this.name]);
    }
    if (changes && changes.items && !changes.items.firstChange && !changes.items.previousValue) {
      const form = this.form?.form || this.formGroup;
      const formControl = form?.controls[this.name];
      const formValue = form?.value[this.name];
      if (changes.items.currentValue && changes.items.currentValue.length) {
        let temp = formValue ? changes.items.currentValue.find((item: any) => !this.multiple ? item[this.bindValue] === formValue : formValue.find((t: any) => item[this.bindValue] === t)) : null;
        !temp && formControl ? formControl.setValue(formValue && Array.isArray(formValue) ? [] : null) : null;
      }
      else {
        formControl ? formControl.setValue(formValue && Array.isArray(formValue) ? [] : null) : null;
      }
    }
  }

  ngOnInit(): void {
    if (this.dataType === "enum") {
      this.items = Utils.enumToArray(this.items);
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.subject
      .pipe(
        debounceTime(500),
        map((event) => event as KeyboardEvent),
        distinctUntilChanged(),
        map((res) => this.search.emit(res))
      )
      .subscribe();
  }

  ngAfterContentInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
    }

    if (
      this.items?.length &&
      this.hasPrint &&
      this.formGroup.getRawValue()[this.name]
    ) {
      this.setExtraData(this.formGroup.getRawValue()[this.name]);
    }
    // this.changeInputState();
  }

  changeInputState() {
    if (this.disabled) {
      // this.formGroup?.controls[this.name].disable({ onlySelf: true, emitEvent: false });
    } else if (!this.disabled && !this.form?.disabled) {
      this.formGroup?.controls[this.name].enable();
    }
  }

  onSelectChange(event): void {
    if (this.multiple) {
      this.selectItem.emit(event);
    } else {
      this.selectedItem = this.items.find((i) => i[this.bindValue] === event);
      this.selectItem.emit(this.selectedItem);
    }
  }

  changeValue(value): void {
    if (!value || (value && !value.length)) {
      return;
    }
    if (this.items && this.items.length && this.hasPrint) {
      this.setExtraData(value);
    }
    if (this.items && this.items.length) {
      const form = this.form?.form || this.formGroup;
      const formControl = form?.controls[this.name];
      let temp = value ? this.items.find((item: any) => !this.multiple ? item[this.bindValue] === value : value.find((t: any) => item[this.bindValue] === t)) : null;
      !temp && formControl ? formControl.setValue(value && Array.isArray(value) ? [] : null) : null;
    }
    this.valueChange.emit(value);
  }

  setExtraData(value) {
    if (!value || this.multiple) {
      return;
    }
    const item = this.items.find((i) => i[this.bindValue] === value)?.[
      this.bindLabel
    ];

    this.formGroup.controls?.extraData?.setValue({
      ...this.formGroup.value["extraData"],
      [this.label]: item,
    });
  }

  onSearch(event): void {
    if (!event) {
      event = { term: "", items: [] };
    }
    this.subject.next(event.term);
  }

  getErrorText(): string {
    const control = this.formGroup.controls[this.name];
    if (control.errors.required || control.errors.whitespace) {
      return "IS_REQUIRED";
    }
  }

  onClear() {
    this.formGroup.controls[this.name].setValue(null);
    this.search.emit("");
    // this.onOpen();
  }

  onChange(event) {
    if (!event) {
      // this.onClear();
    }
  }

  onOpen() {
    if (this.multiple) {
      this.search.emit("");
    }
  }

  test() {
    return this.disabled;
  }

  onRemoveItem(event) {
    if (event.value === this.defaultSelectedItem.identifier) {
      this.formGroup.controls[this.name].setValue([
        this.defaultSelectedItem.identifier,
        ...this.formGroup.value[this.name],
      ]);
    }
  }
}
