import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, forkJoin } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { FamilyService } from 'src/app/core/api/family.service';
import { ToolsService } from 'src/app/core/services/tools.service';
import { SETTING_IDS } from 'src/app/core/constants';
import { Utils } from 'src/app/core/helpers/utils';
import { InputTypes } from 'src/app/core/models/enums';
import { FamilySettingFormData } from 'src/app/core/models/family-model';

@Component({
  selector: 'app-settings-modal',
  templateUrl: 'settings-modal.component.html',
  styleUrls: ['settings-modal.component.scss']
})

export class SettingsModalComponent implements OnInit {

  Form: FormGroup;
  modalRef: NgbModalRef;
  langID: number;
  inputTypes = InputTypes;
  primaryKey: string
  data: FamilySettingFormData[];
  data$: Observable<FamilySettingFormData[]>;
  dataTable: any[];
  hiddenParameters: any[];
  recordId: number;
  textError: string;
  parentTreeDisabled: boolean;
  parentTreeDisabledPro: boolean;
  isDuplicateValue: boolean;
  isUpdate: boolean;
  @Input() param: { identifier?: string, assign?: { id?: number, name?: string }};
  @Input() dataSelected: any;
  @Input() disables: string = '';
  @Output() isLoading = new EventEmitter;
  @Output() dismissed = new EventEmitter;
  @ViewChild('modal') modal: ElementRef;

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private api: ApiService, private familyService: FamilyService, private tools: ToolsService) {
    this.Form = this.formBuilder.group({});
    this.langID = +localStorage.getItem('langId') - 1;
    this.primaryKey = this.textError = '';
    this.param = { identifier: '', assign: { id: null, name: '' }};
    this.hiddenParameters = [];
    this.recordId = null;
    this.isDuplicateValue = this.isUpdate = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getData().pipe(map(() => {
      if (this.dataSelected) {
        this.Form.patchValue(this.dataSelected);
        this.isUpdate = this.dataSelected?.[this.primaryKey];
      }
      this.openModal(this.modal);
    })).subscribe();
  }

  openModal(content: any, size = 'lg'): void {
    this.modalRef = this.modalService.open(content, { size, centered: true });
    this.modalRef.dismissed.pipe(map(() => this.dismissed.emit(this.recordId))).subscribe();
  }

  onChangeItem(id_selected: number, data: FamilySettingFormData): void {
    this.data.forEach((item: any) => {
      if (item.dependentOnParameter && item.dependentOnParameter === data.columnName) {
        if (id_selected) {
          const url = item.dataQuery + '?' + item.dependentOnParameter + '=' + id_selected;
          this.api.get<any[]>({}, url, null).pipe(map((res) => item.inputItems = res.data), shareReplay()).subscribe();
        }
        else {
          item.inputItems = item.items;
        }
      }
    });
  }

  onSelectItem(data: FamilySettingFormData): void {
    this.data.forEach((item: any) => {
      if (item.dependentOnParameter && item.dependentOnParameter === data.columnName) {
        this.onClearNodeInForm(this.Form, item.columnName);
      }
    });
  }

  onClearNodeInForm(form: FormGroup, columnName: string): void {
    form.controls[columnName].setValue(null);
  }

  checkIsIncomeAccountField(data: any[], field: string) {
    return field === 'IncomeAccountId' ? data.filter((item) => item.id === 6) : data;
  }

  getSourceFieldData(data: any, items: any) {
    items.map((item: any) => {
      item.id = data.sourceFields && data.sourceFields.idField ? item[data.sourceFields.idField] : item.id;
      item.title = data.sourceFields && data.sourceFields.titleField ? item[data.sourceFields.titleField] : item.title;
    });
    return items;
  }

  getData() {
    let dataQueries = [];
    this.isLoading.emit(true);
    this.parentTreeDisabled = !![SETTING_IDS.festivals, SETTING_IDS.festivalTypes, SETTING_IDS.departmentAndCenter].find((item) => item === this.param.identifier);
    this.parentTreeDisabledPro = !![SETTING_IDS.festivals].find((item) => item === this.param.identifier);
    this.Form = this.formBuilder.group({});
    return this.data$ = this.familyService.settingGetSettingFormData<FamilySettingFormData[]>({ identifier: this.param.identifier }).pipe(map((res) => {
      res.data['columnsData'].forEach((item: any) => {
        if (item.isPrimaryKey) {
          this.primaryKey = item.columnName;
        }
        if (item.showInForm && item.columnName === this.param.assign.name) {
          item.showInForm = false;
        }
        if (item.showInForm && !item.isHiddenParameter) {
          const defaultValue = item.inputType === this.inputTypes.Check ? false : null;
          this.Form.addControl(item.columnName, item.isRequired ? new FormControl(defaultValue, Validators.required) : new FormControl(defaultValue));
        }
        if (item.isHiddenParameter && item.columnName === 'BranchId') {
          this.Form.addControl(item.columnName, new FormControl(this.tools.getSelectedBranch()?.id, Validators.required));
        }
        if (item.isHiddenParameter && item.columnName === 'AgentUserIdentifier') {
          this.Form.addControl(item.columnName, new FormControl(this.tools.getUser()?.identifier, Validators.required));
        }
        if (item.isHiddenParameter && !isNaN(item.dataQuery) && item.columnName !== 'AgentUserIdentifier') {
          this.hiddenParameters = { ...this.hiddenParameters, [item.columnName]: item.dataQuery };
        }
        if (item.refrenceType === 2 && item.dataQuery) {
          dataQueries.push(this.api.get<any[]>({}, item.dataQuery, null).pipe(map((res) => {
            res.data = this.getSourceFieldData(item, res.data);
            item.items = res.data;
            item.inputItems = item.inputType === this.inputTypes.Tree ? this.checkIsIncomeAccountField(Utils.convertAccountToTree(res.data), item.columnName) : res.data;
          })));
        }
        if ((item.refrenceType === 1 || item.refrenceType === 3) && item.data) {
          item.data = this.getSourceFieldData(item, item.data);
          item.items = item.data;
          item.inputItems = item.inputType === this.inputTypes.Tree ? Utils.convertAccountToTree(item.data, null, 'parentId') : item.data;
        }
      });
      return this.data = res.data['columnsData'];
    }), concatMap((res) => {
      return dataQueries.length ? forkJoin(dataQueries).pipe(concatMap(() => this.getDataTable().pipe(map(() => res)))) : this.getDataTable().pipe(map(() => res));
    }), catchError(() => {
      this.isLoading.emit(false);
      return of([]);
    }), shareReplay());
  }

  getDataTable() {
    return this.familyService.settingFilterSettingDataWithSearch<any[]>({ identifier: this.param.identifier }).pipe(map((res) => {
      this.dataTable = res.data;
      this.isLoading.emit(false);
      return res.data;
    }), catchError(() => {
      this.isLoading.emit(false);
      return of([]);
    }), shareReplay());
  }

  save() {
    const multiSelects: any = {};
    this.isDuplicateValue = false;
    Object.keys(this.Form.value).forEach((key) => {
      this.data.forEach((item) => {
        if (item.doNotRepeat && item.columnName === key && this.Form.value[key]) {
          this.dataTable.find((t) => {
            if (t[this.primaryKey] !== this.dataSelected?.[this.primaryKey] && !this.isDuplicateValue) {
              this.isDuplicateValue = t[item.columnName] === this.Form.value[key];
            }
          });
        }
      });
    });
    this.data.forEach((item) => {
      if (item.showInForm && !item.isHiddenParameter) {
        switch (item.inputType) {
          case InputTypes.Money: this.Form.controls[item.columnName].setValue(this.Form.value[item.columnName] ? this.Form.value[item.columnName].replace(/,(?=\d{3})/g, '') : null); break;
          case InputTypes.MultiSelect: multiSelects[item.columnName] = this.Form.value[item.columnName] ? '[' + this.Form.value[item.columnName].toString() + ']' : null; break;
          default: break;
        }
      }
    });
    if (this.Form.invalid || this.isDuplicateValue) {
      if (this.isDuplicateValue) {
        this.textError = 'ONE_OF_THE_VALUES_IS_DUPLICATED';
      }
      return;
    }
    const list = [{ ...this.Form.value, ...this.hiddenParameters, [this.param.assign.name]: this.param.assign.id, ...multiSelects }];
    const param = { identifier: this.param.identifier, list };
    if (this.isUpdate) {
      param.list[0][this.primaryKey] = this.dataSelected[this.primaryKey];
      this.familyService.settingUpdateRecord(param).subscribe((res: any) => {
        this.recordId = res.data && res.data.length ? res.data[0].id : 1;
        this.modalRef.dismiss();
      }, () => {
        this.modalRef.dismiss();
      });
    }
    else {
      this.familyService.settingCreateRecord(param).subscribe((res: any) => {
        this.recordId = res.data && res.data.length ? res.data[0].id : 1;
        this.modalRef.dismiss();
      }, () => {
        this.modalRef.dismiss();
      });
    }
  }

}
