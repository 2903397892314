import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  template: `
    <a *ngIf="params.data[params.colDef.field] || params['text']" [href]="params['url']" target="_blank">
      <i class="bx bx-link"> </i>
      <span> {{ params.data[params.colDef.field] || params['text'] }} </span>
    </a>
  `,
})

export class LinkRenderer implements ICellRendererAngularComp {

  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

}
