<ng-container *ngIf="!loading else loadingTemplate">
  <div [ngStyle]="{ 'height': tableData && tableData.length && tableData.length > 10 ? '500px' : '', 'minHeight': gridHeight !== 'unset' ? '500px' : 'auto' }"
    class="table-responsive advanced-table">
    <table id="basic-datatable" class="table dt-responsive nowrap no-footer dtr-inline">
      <thead>
        <tr>
          <th *ngFor="let item of colDefs" [ngStyle]="{'min-width': item.minWidth+'px' }" [style]="item?.style"
            class="parent {{item.pinned==='right'? 'right':'left'}}"
            [class.sticky-col]="item.field==='action' || item.pinned==='left' || item.pinned==='right'"
            (click)="onSort(item)">
            {{item.headerName|translate}}
            <ng-container *ngIf="!item.headerName.toString().toLowerCase().includes('_ar') && !item.headerName.toString().toLowerCase().includes(('IQD' | translate))">
              {{item.field.toString().toLowerCase().includes('amount') || item.headerName.toString().toLowerCase().includes('amount') || (item.field.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + ('IQD' | translate) + ')') : ''}}
            </ng-container>
            <i *ngIf="item.sort&&item.sort==='asc'" class="bx bx-up-arrow-alt"></i>
            <i *ngIf="item.sort&&item.sort==='desc'" class="bx bx-down-arrow-alt"></i>
          </th>
        </tr>
      </thead>

      <tbody *ngFor="let data of tableData;let i=index">
        <tr>
          <td *ngFor="let item of colDefs"
            [class.sticky-col]="item.type==='dropdown' || item.pinned==='left' || item.pinned==='right'"
            class="{{item.pinned==='right'? 'right':'left'}}" [style]="item.style">
            <ng-container *ngIf="!item.cellRenderer">

              <ng-container *ngIf="!item.type">
                <ng-container *ngIf="item.field!=='#'">
                  <ng-container *ngIf="item?.editable; else readOnly0">
                    <input type="text" class="form-control-custom" [(ngModel)]="data[item.field]"
                      (ngModelChange)="onTableEdit({row: data, colDef: item})"
                      [attr.disabled]="data[item.field + 'Disabled'] ? true : null">
                  </ng-container>

                  <ng-template #readOnly0>
                    {{item.typeValue === 'number' ? (data[item.field] | number) : data[item.field]}}
                  </ng-template>

                </ng-container>

                <ng-container *ngIf="item.field==='#'">
                  <ng-container>
                    <i [class.disabled]="loadingSubTableData"
                      class="bx bx-{{ loadingSubTableData ? 'loader' : (hideme[i] ? 'plus' : 'minus') }} icon-expand"
                      (click)="onActionClick('view', data, i)">
                    </i>
                  </ng-container>

                  {{i+1+paging?.skip}}
                </ng-container>
              </ng-container>

              <ng-container *ngIf="item.type === 'dropdownTreeComponent'">
                <app-dropdown-tree (selectNode)="$event ? onTreeInTableEdit({ row: data, colDef: item }, $event) : null"
                  (clear)="onTreeInTableEdit({ row: data, colDef: item }, null)" [nodes]="data[item.field]"
                  [selectedNode]="data[item.field + 'Selected']" [idField]="item.idField"
                  [displayField]="item.displayField" [disabledParents]="item.disabledParents" [isClearable]="item.isClearable"
                  [hasCheckbox]="false" [disabled]="data[item.field + 'Disabled']"> </app-dropdown-tree>
              </ng-container>

            </ng-container>

            <ng-container *ngIf="item.cellRenderer">

              <ng-container *ngIf="item.type==='template'">
                <div [outerHTML]="item|renderer:{data:data}"></div>
              </ng-container>

              <ng-container *ngIf="item.type==='button'">
                <app-btn-custom
                  [theme]="{type:item.cellRenderer.type, color: item.cellRenderer.color,size:'sm',text: item.cellRenderer.text}"
                  classList="in-grid" (onClick)="onActionClick(item.cellRenderer.text, data, i, 'parent')">
                </app-btn-custom>
              </ng-container>

              <ng-container *ngIf="item.type!=='template' &&item.type!=='button'">
                {{item|renderer:{data:data} }}
              </ng-container>

            </ng-container>
            <ng-container *ngIf="item.type==='dropdown'">
              <div ngbDropdown placement="bottom-left" container="body" display="dynamic">

                <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                  aria-expanded="true">
                </i>

                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

                  <ng-container *ngIf="item|renderer:{data:data}:'dropdownItems' as dropdownItems">
                    <a *ngFor="let dropdown of dropdownItems" class="dropdown-item" href="javascript: void(0);"
                      (click)="onActionClick(dropdown, data, i)">
                      {{dropdown|translate}}
                    </a>
                  </ng-container>
                </div>

              </div>
            </ng-container>
          </td>

        </tr>

        <tr *ngIf="!hideme[i]" class="sub-table no-bg">
          <!-- <td align="center"> </td> -->

          <td colspan="20" class="px-3 py-0">
            <div class="table-responsive overflow-visible my-3 me-2">
              <table class="table table-striped">
                <thead>
                  <tr>

                    <th *ngFor="let item of subTableColDefs" [ngStyle]="{'min-width': item.minWidth+'px'}"
                      [class.sticky-col]="item.field==='action' || item.pinned==='left' || item.pinned==='right'"
                      class="{{item.pinned==='right'? 'right':'left'}}" [style]="item.style">
                      {{item.headerName|translate}}
                      <ng-container *ngIf="!item.headerName.toString().toLowerCase().includes('_ar') && !item.headerName.toString().toLowerCase().includes(('IQD' | translate))">
                        {{item.field.toString().toLowerCase().includes('amount') || item.headerName.toString().toLowerCase().includes('amount') || (item.field.toString().toLowerCase().includes('total') && item.cellRenderer) ? (' (' + ('IQD' | translate) + ')') : ''}}
                      </ng-container>
                    </th>

                  </tr>
                </thead>

                <tbody *ngIf="subTables[i]?.length; else noData">
                  <tr *ngFor="let table of subTables[i]; let j=index">
                    <td *ngFor="let item of subTableColDefs"
                      [class.sticky-col]="item.type==='dropdown' || item.pinned==='left' || item.pinned==='right'"
                      class="{{item.pinned==='right'? 'right':'left'}}" [style]="item.style">

                      <ng-container *ngIf="!item.cellRenderer">
                        <ng-container *ngIf="!item.type">
                          <ng-container *ngIf="item.field!=='#'">
                            <ng-container *ngIf="item?.editable; else readOnly">
                              <input *ngIf="item?.number" type="text" class="form-control-custom"
                                [(ngModel)]="table[item.field]" mask="separator.0" thousandSeparator=","
                                (ngModelChange)="onSubtableEdit({row: table, colDef: item, parentRowIndex: i, childRowIndex: j})"
                                [attr.disabled]="table[item.field + 'Disabled'] ? true : null">
                              <input *ngIf="!item?.number" type="text" class="form-control-custom"
                                [(ngModel)]="table[item.field]"
                                (ngModelChange)="onSubtableEdit({row: table, colDef: item, parentRowIndex: i, childRowIndex: j})"
                                [attr.disabled]="table[item.field + 'Disabled'] ? true : null">
                            </ng-container>

                            <ng-template #readOnly>
                              <!-- {{item.field.includes('amount')?(table[item.field]|number):table[item.field]}} -->
                              {{item.typeValue === 'number' ? (table[item.field] | price) : table[item.field]}}
                            </ng-template>

                          </ng-container>

                          <ng-container *ngIf="item.field==='#'">
                            {{j+1}}
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="item.type === 'dropdownComponent'">
                          <ng-container *ngIf="item | renderer: { data: table }: 'dropdownItems' as component">
                            <app-dropdown
                              (selectItem)="table[item.field] = $event[item.bindValue]; onSubtableEdit({ row: table, colDef: item, parentRowIndex: i, childRowIndex: j })"
                              [items]="component.data[item.field + 'Data'] || component.data.dropdownItems" [value]="table[item.field]"
                              [bindValue]="item.bindValue" [bindLabel]="item.bindLabel" [isClearable]="false"
                              [disabled]="table[item.field + 'Disabled']"> </app-dropdown>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="item.type === 'dropdownTreeComponent'">
                          <app-dropdown-tree
                            (selectNode)="$event ? onTreeInSubTableEdit({ row: table, colDef: item }, $event) : null"
                            (clear)="onTreeInSubTableEdit({ row: table, colDef: item }, null)"
                            [nodes]="table[item.field]" [selectedNode]="table[item.field + 'Selected']"
                            [idField]="item.idField" [displayField]="item.displayField"
                            [disabledParents]="item.disabledParents" [isClearable]="item.isClearable"
                            [hasCheckbox]="false" [disabled]="table[item.field + 'Disabled']"> </app-dropdown-tree>
                        </ng-container>

                      </ng-container>

                      <ng-container *ngIf="item.cellRenderer">

                        <ng-container *ngIf="item.type==='template'">
                          <div [outerHTML]="item|renderer:{data:table}"></div>
                        </ng-container>

                        <ng-container *ngIf="item.type==='button'">
                          <app-btn-custom
                            [theme]="{type:item.cellRenderer.type, color: item.cellRenderer.color,size:'sm',text: item.cellRenderer.text}"
                            classList="in-grid"
                            (onClick)="onActionClick(item.cellRenderer.text, table, i, 'sub', data)">
                          </app-btn-custom>
                        </ng-container>

                        <ng-container *ngIf="item.type!=='template' && item.type!=='button'">
                          {{item|renderer:{data:table} }}
                        </ng-container>

                      </ng-container>
                      <ng-container *ngIf="item.type==='dropdown'">

                        <div ngbDropdown placement="bottom-left" container="body" display="dynamic">
                          <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                            aria-expanded="true">
                          </i>

                          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <ng-container *ngIf="item|renderer:{data:table}:'dropdownItems' as dropdownItems">
                              <a *ngFor="let dropdown of dropdownItems" class="dropdown-item"
                                href="javascript: void(0);" (click)="onActionClick(dropdown, table, i, 'sub', data)">
                                {{dropdown|translate}}
                              </a>
                            </ng-container>
                          </div>

                        </div>

                      </ng-container>
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ngb-pagination *ngIf="paging" [page]="(paging.skip / paging.take) + 1" [pageSize]="paging.take" [maxSize]="10"
    [collectionSize]="paging.total" (pageChange)="onPageChange($event)"
    class="pagination pagination-rounded justify-content-end mt-3">
  </ngb-pagination>
</ng-container>

<ng-template #noData>
  <tbody>
    <tr>
      <td [attr.colspan]="subTableColDefs.length" class="text-center">
        No data to show
      </td>
    </tr>
  </tbody>
</ng-template>

<ng-template #loadingTemplate>
  <app-loader height="500"> </app-loader>
</ng-template>
