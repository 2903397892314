import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { Utils } from "src/app/core/helpers/utils";

interface SortItemModel {
  id?: number | null;
  text?: string;
  active?: boolean;
}

@Component({
  selector: "app-sort-box",
  templateUrl: "./sort-box.component.html",
  styleUrls: ["./sort-box.component.scss"],
})
export class SortBoxComponent implements OnInit, AfterContentInit {
  @Input() currentActiveIndex = 0;
  selectedItem: SortItemModel = {};
  formGroup: FormGroup;
  @Input() form: FormGroupDirective;
  @Input() sort = "SORT";
  @Input() name: string;
  @Input() sortItems: SortItemModel[] = [];
  @Input() sortEnum;
  @Output() sortFilter: EventEmitter<SortItemModel> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.sortEnum) {
      const array = Utils.enumToArray(this.sortEnum);
      this.sortItems = [{ id: -1, text: "ALL", active: !this.currentActiveIndex }];
      array.forEach((a) =>
        this.sortItems.push({ id: a.id, text: a.name, active: false })
      );
    }
    if (this.sortItems.length) {
      this.sortItems[this.currentActiveIndex].active = true;
      this.selectedItem = this.sortItems[this.currentActiveIndex];
    }
  }

  ngAfterContentInit() {
    if (this.form) {
      this.formGroup = this.form.form;
    }
  }

  onItemClick(index: number, item: SortItemModel): void {
    this.selectedItem = item;
    this.sortItems.map((i) => (i.active === true ? (i.active = false) : ""));
    this.sortItems[index].active = true;
    this.currentActiveIndex = index;
    if (this.form) {
      this.formGroup.controls[this.name].setValue(
        this.selectedItem.id === -1 ? null : this.selectedItem.id
      );
    }
    this.sortFilter.emit(this.selectedItem);
  }
}
