<div class="{{ classList }}">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs" (activeIdChange)="onTabChange()">
    <li *ngFor="let tab of tabs" [ngbNavItem]="+tab.key" [disabled]="tab.disabled" >
      <a ngbNavLink>{{ tab.value | translate }}</a>
    </li>
  </ul>
  <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="activeIndex" class="">
    <li *ngFor="let tab of tabs; let i = index" [ngbNavItem]="+tab.key" (click)="onTabChange(tab)" [class.active]="+tab.key === activeIndex"
        class="list-inline-item tab-list-item">
      <span> {{ tab.value | translate }} </span>
    </li>
  </ul> -->
  <ng-content> </ng-content>
</div>