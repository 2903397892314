import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() height: number;

  // loading: boolean = true;

  // constructor(private loaderService: LoaderService) {

  //   this.loaderService.isLoading.subscribe((v) => {
  //     setTimeout(() => {
  //       this.loading = v;
  //     }, 1500);     
  //   });
  // }
  ngOnInit(): void {
  }

}
