import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

// const numberPipe = new DecimalPipe(null)
@Pipe({
  name: "price",
})
export class PricePipe implements PipeTransform {
  constructor(private numberPipe: DecimalPipe) {}
  transform(value: any, ...args: any[]) {
    if (value && typeof value === "number") {
      // value = formatNumber(value,);
      return this.numberPipe.transform(value);
    } else {
      return value;
    }
  }
}
