import { MenuItem } from './menu.model';

export const MENU_APP: MenuItem[] = [
  { id: 1, icon: '', label: 'CONFIRM_REQUEST_DONATIONS', link: '/donation/cash/create-request', parentId: 0 },
  { id: 2, icon: '', label: 'CASH_DONATION_REQUEST_LIST', link: '/donation/cash/request-list', parentId: 0 },
  { id: 3, icon: '', label: 'CASH_DONATIONS', link: '/donation/cash/list', parentId: 0 },
  { id: 4, icon: '', label: 'CASH_RECEIVES', link: '/donation/cash/receive-list', parentId: 0 },
  { id: 5, icon: '', label: 'GOOD_RECEIVES', link: '/donation/goods/receive-list', parentId: 0 },
  { id: 6, icon: '', label: 'SERVICE_RECEIVES', link: '/donation/services/receive-list', parentId: 0 },
  { id: 7, icon: '', label: 'SINGLE_AGENT_RECEIVES', link: '/donation/cash/agent-receive-list', parentId: 0 },
  { id: 8, icon: '', label: 'SUBMIT_DONATION_GOODS_REQUEST', link: '/donation/goods/create-request', parentId: 0 },
  { id: 9, icon: '', label: 'GOODS_DONATION_REQUEST_LIST', link: '/donation/goods/request-list', parentId: 0 },
  { id: 10, icon: '', label: 'SUBMIT_DONATION_SERVICE_REQUEST', link: '/donation/services/create-request', parentId: 0 },
  { id: 11, icon: '', label: 'SERVICE_DONATION_REQUEST_LIST', link: '/donation/services/request-list', parentId: 0 },
  { id: 12, icon: '', label: 'CREATE_VOID_RECEIPT', link: '/donation/void-receipt/create-receipt', parentId: 0 },
  { id: 13, icon: '', label: 'VOID_RECEIPTS_LIST', link: '/donation/void-receipt/list', parentId: 0 },
  { id: 14, icon: '', label: 'SINGLE_AGENT_SALARY_LIST', link: '/hr/agent/salary-list', parentId: 0 }
];
