<ng-container *ngIf="!loading else loadingTemplate">
  <router-outlet> </router-outlet>
</ng-container>
<ng-template #loadingTemplate>
  <app-loader height="600"> </app-loader>
</ng-template>

<app-print-page *ngIf="printService.page === 'default'"> </app-print-page>
<family-member-print *ngIf="printService.page === 'member'"> </family-member-print>
<family-print *ngIf="printService.page === 'family'"> </family-print>
<personnel-print *ngIf="printService.page === 'personnel'"> </personnel-print>
<hr-all-salary-print *ngIf="printService.page === 'salary'"> </hr-all-salary-print>
