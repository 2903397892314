import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { icon, Map, marker, tileLayer, latLng } from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, AfterViewInit, OnChanges {

  formGroup: FormGroup;
  mapBox: any;
  public mapMarker: any = null;
  options = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 17, attribution: 'Open Street Map' })],
    zoom: 17
  }
  @Input() form: FormGroupDirective;
  @Input() formLat = 'latitude';
  @Input() formLon = 'longitude';
  @Input() lat: number = null;
  @Input() lng: number = null;
  @Input() disabled = false;
  @Output() locationSelect = new EventEmitter();

  ngOnInit(): void {
    if (!this.lat && !this.lng) {
      this.options['center'] = latLng(33.294308, 44.3388565);
    }
    else {
      this.options['center'] = latLng(this.lat, this.lng);
      this.options['zoom'] = 17;
    }
  }

  ngAfterViewInit(): void {
    if (this.form) {
      this.formGroup = this.form.form;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.lat.firstChange && (changes.lat || changes.lng)) {
      if (this.lat && this.lng) {
        this.addMarker(this.lat, this.lng);
        this.mapBox.flyTo([this.lat, this.lng], 17);
      }
      else {
        this.mapBox.removeLayer(this.mapMarker);
      }
    }
  }

  onMapReady(map: Map): void {
    this.mapBox = map;
    setTimeout(() => this.mapBox.invalidateSize(), 0);
    setTimeout(() => {
      this.setCurrentLocation();
      if (this.lat && this.lng) {
        this.addMarker(this.lat, this.lng);
      }
    }, 0);
  }

  setCurrentLocation(): void {
    this.mapBox.locate({ setView: true });
    this.mapBox.on('locationfound', (e) => {
      this.lat = e.latlng.lat;
      this.lng = e.latlng.lng;
      this.addMarker(this.lat, this.lng);
    });
  }

  addMarker(lat, lng) {
    if (!lat || !lng) {
      return;
    }
    if (this.mapMarker) {
      this.mapBox.removeLayer(this.mapMarker);
    }
    this.mapMarker = marker([lat, lng], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/leaflet/marker-icon.png',
        shadowUrl: 'assets/leaflet/marker-shadow.png'
      }),
    }).addTo(this.mapBox);
  }

  onClick(event) {
    if (this.disabled || this.formGroup.disabled) {
      return;
    }
    let lat = event['latlng']['lat'];
    let lng = event['latlng']['lng'];
    this.locationSelect.emit({ lat, lng });
    if (this.formGroup) {
      this.formGroup.controls[this.formLat].setValue(lat);
      this.formGroup.controls[this.formLon].setValue(lng);
    }
    this.addMarker(lat, lng);
  }

}
