import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-header',
  template: `<div *ngFor='let item of params["data"]'> {{ (item | translate) + (params["symbol"] ? " (" + (params["symbol"] | translate) + ")" : "") }} </div>`
})

export class CustomHeader implements IHeaderAngularComp {

  public params: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams) {
    return true;
  }

}
