<div>
  <div *ngIf="hasCRUD" class="button-items mb-1">
    <button (click)="addClick.emit()" type="button" class="btn btn-success"> Add New </button>
    <button (click)="actionsGrid({ action: 'edit', selectedRows: selectedRows })" class="btn btn-light"
      [disabled]="selectedRows.length !== 1"> Edit </button>
    <button *ngIf="isAdmin" (click)="actionsGrid({ action: 'delete', selectedRows: selectedRows })"
      class="btn btn-danger" [disabled]="selectedRows.length === 0"> Delete </button>
  </div>

  <div class="d-flex gap-3 {{searchSlotClass}}">
    <search-field *ngIf="hasSearch" (onSearch)="searchTerm = $event" [dropdownItems]="searchItems"
      [(dropdownItemSelected)]="dropdownItemSelected" (dropdownItemSelectedChange)="onSearchItemChange()"
      (onSearch)="searchTerm=$event; onSearch()" class="flex-fill d-block">
    </search-field>
    <ng-content></ng-content>
  </div>

  <div *ngIf="!loading; else loadingTemplate" class="position-relative">

    <div class="row">

      <ag-grid-angular (cellEditingStopped)="onCellEdit($event)" [rowClassRules]="rowClassRules"
        [defaultColDef]="defaultColDef" [gridOptions]="{enableCellTextSelection: true}" [columnDefs]="columnDefs"
        [rowData]="data && data.length ? data : []" [context]="context" [enableRtl]="rtl" rowSelection="multiple"
        [ngStyle]="{ 'height': gridFixHeight || (data && data.length && data.length > 10 ? '500px' : ''), 'minHeight': gridHeight === 'unset' ? '200px' : (gridFixHeight ? 'auto' : '500px') }"
        class="ag-theme-alpine w-100" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"
        (sortChanged)="onSortChange($event)" (selectionChanged)="onSelectedChange()" [suppressRowClickSelection]="true"
        [animateRows]="true" [tooltipShowDelay]="500" [tooltipHideDelay]="500">
      </ag-grid-angular>

    </div>

    <i *ngIf="loading" class="bx bx-hourglass bx-spin grid-loading align-middle me-2"> </i>
  </div>

  <div *ngIf="(showTotal && total) || paging" [class]="'d-flex ' + (showTotal && total && paging ? 'justify-content-between' : 'justify-content-end')">
    <div *ngIf="showTotal && total" class="page-link border-0 py-0 mt-3">
      <span> {{ 'TOTAL_COUNT' | translate }} : {{ total }} </span>
    </div>

    <ngb-pagination *ngIf="paging&&data&&data.length" [page]="(paging.skip / paging.take) + 1" [pageSize]="paging.take"
      [maxSize]="5" [collectionSize]="total" (pageChange)="change($event)"
      class="pagination pagination-rounded justify-content-end mt-3">
    </ngb-pagination>
  </div>


</div>

<ng-template #loadingTemplate>
  <app-loader height="500"> </app-loader>
</ng-template>
