<div *ngIf="form" [formGroup]="formGroup" [class]="classList + (disabled || form?.disabled ? ' disabled' : '' )">

  <label *ngIf="label" [for]="disabled || form?.disabled ? '' : (name + ids)" class="form-label"> {{ label | translate }}
    <span *ngIf="required" class="text-danger"> * </span>
  </label>

  <div class="form-group">
    <i *ngIf="icon" class="bx bx-{{ icon }}"> </i>

    <ng-select [closeOnSelect]="multiple?false:true" [formControlName]="name" [placeholder]="placeholder"
      [multiple]="multiple" [labelForId]="name + ids" [class.clearable]="isClearable" [readonly]="readonly"
      [disabled]="formGroup.controls[name].disabled||form.disabled||disabled" (change)="onSelectChange($event)" [clearable]="isClearable"
      (ngModelChange)="changeValue($event)" (clear)="onClear()" (open)="onOpen()" (change)="onChange($event)"
      (search)="onSearch($event)" [tabIndex]="disabled ? -1 : 0" (remove)="onRemoveItem($event)">

      <ng-option *ngFor="let item of items" [value]="item[bindValue]" [disabled]="disabledItem===item[bindValue]">
        <ng-container *ngIf="bindLabel0"> {{ item[bindLabel0] | translate }} - </ng-container>
        {{ item[bindLabel] | translate }}
        <ng-container *ngIf="bindLabel2"> - {{ item[bindLabel2] | translate }} </ng-container>
      </ng-option>

    </ng-select>

  </div>

  <div
    *ngIf="(form.submitted&&formGroup.controls[name].invalid)||formGroup.controls[name].invalid && (formGroup.controls[name].dirty || formGroup.controls[name].touched)"
    class="invalid-feedback">
    <div *ngIf="formGroup.controls[name].errors?.required"> {{ label | translate }} {{ getErrorText()|translate }}
    </div>
  </div>

</div>

<div *ngIf="!form" [class]="classList">
  <label *ngIf="label" [for]="name + ids" class="form-label"> {{ label | translate }} <span *ngIf="required"
      class="text-danger"> * </span>
  </label>

  <div class="form-group">
    <i *ngIf="icon" class="bx bx-{{ icon }}"> </i>
    <ng-select (change)="onSelectChange($event)" [clearable]="isClearable" [(ngModel)]="value"
      (ngModelChange)="changeValue($event)" (search)="onSearch($event)" [placeholder]="placeholder"
      [multiple]="multiple" [labelForId]="name + ids" [class.clearable]="isClearable" [readonly]="readonly"
      [disabled]="disabled" [tabIndex]="disabled ? -1 : 0">

      <ng-option *ngFor="let item of items" [value]="item[bindValue]">
        <ng-container *ngIf="bindLabel0"> {{ item[bindLabel0] | translate }} - </ng-container>
        {{ item[bindLabel] | translate }}
        <ng-container *ngIf="bindLabel2"> - {{ item[bindLabel2] | translate }} </ng-container>
      </ng-option>

    </ng-select>
  </div>
</div>
