import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: "app-quantity-search",
  templateUrl: "./quantity-search.component.html",
  styleUrls: ["./quantity-search.component.scss"],
})
export class QuantitySearchComponent implements AfterViewInit {
  @Output() search = new EventEmitter();
  @Input() label: string;
  @Input() value: string = "";
  @Output() valueChange = new EventEmitter()
  @ViewChild("input") input: ElementRef;
  subscription: Subscription;

  constructor() { }

  ngAfterViewInit() {
    this.subscription = fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        debounceTime(300),
        map((event) => event as KeyboardEvent),
        map((event) => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        map((res) => {
          this.valueChange.emit(res);
          this.search.emit(res);
        })
      )
      .subscribe();
  }
}
