import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from '@circlon/angular-tree-component';
import { Observable, of, forkJoin } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { AccountService } from 'src/app/core/api/account.service';
import { DonationService } from 'src/app/core/api/donation.service';
import { FamilyService } from 'src/app/core/api/family.service';
import { SponsorshipService } from 'src/app/core/api/sponsorship.service';
import { FullNamePipe } from 'src/app/core/pipes/full-name.pipe';
import { Utils } from 'src/app/core/helpers/utils';
import { Ancestry, Gender, SponsorTabs } from 'src/app/core/models/enums';
import { User, UserCallNumberModel, UserSocialNetworkModel } from 'src/app/core/models/auth.models';
import { GeonameModel } from 'src/app/core/models/settings-model';
import { PublicModel, AddressModel } from 'src/app/core/models/public-model';
import { SponsorFilterModel } from 'src/app/core/models/sponsorship/sponsor-model';
import { SETTING_IDS } from 'src/app/core/constants';
import { CallNumbersCols, SocialNetworksCols, ReferralsCols } from 'src/app/core/models/column-defs-model';

@Component({
  selector: 'app-sponsor-modal',
  templateUrl: 'sponsor-modal.component.html',
  styleUrls: ['sponsor-modal.component.scss']
})

export class SponsorModalComponent implements OnInit {

  sponsorForm: FormGroup;
  sponsorAddressForm: FormGroup;
  sponsorWorkAddressForm: FormGroup;
  sponsorCallNumberForm: FormGroup;
  sponsorSocialNetworkForm: FormGroup;
  referralsForm: FormGroup;
  modalRef: NgbModalRef;
  userPrefixes: PublicModel[];
  userPrefixes$: Observable<PublicModel[]>;
  callNumberTypes$: Observable<PublicModel[]>;
  socialNetworkTypes$: Observable<PublicModel[]>;
  jobs: PublicModel[];
  jobs$: Observable<PublicModel[]>;
  geoname$: Observable<GeonameModel[]>;
  ancestryItems = Ancestry;
  genderItems = Gender;
  tabs = SponsorTabs;
  activeTab = SponsorTabs.SPONSOR_INFO;
  callNumbersCols = CallNumbersCols;
  callNumbersData: UserCallNumberModel[] = [];
  socialNetworksCols = SocialNetworksCols;
  socialNetworksData: UserSocialNetworkModel[] = [];
  referralsCols = ReferralsCols;
  referralsData = [];
  sponsorDisabledTabs: number[];
  sponsorInfoCreated: { id: number; code: string };
  selectedNode: TreeNode;
  isDuplicatePhone: boolean;
  @Input() sponsorSelected: SponsorFilterModel = {};
  @Input() donationLength: number;
  @Output() dismissed = new EventEmitter;
  @ViewChild('sponsor') sponsor: ElementRef;
  @ViewChild('confirmModal') confirmModal: ElementRef;
  @ViewChild('deleteModal') deleteModal: ElementRef;

  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private modal: NgbModal, private api: ApiService, private accountService: AccountService,
    private donationService: DonationService, private familyService: FamilyService, private sponsorshipService: SponsorshipService, private fullNamePipe: FullNamePipe) {
    this.sponsorForm = this.formBuilder.group({
      userIdentifier: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: null,
      jobId: [null, Validators.required],
      userPrefixId: null,
      ancestry: [null, Validators.required],
      gender: null,
      maritalStatus: null,
      birthDate: new Date()
    });
    this.sponsorAddressForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      name: null,
      addressType: [null, Validators.required],
      address: [null, Validators.required],
      latitude: null,
      longitude: null,
      geoNameId: [null, Validators.required],
      geoName: null
    });
    this.sponsorWorkAddressForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      name: null,
      addressType: [null, Validators.required],
      address: [null, Validators.required],
      latitude: null,
      longitude: null,
      geoNameId: [null, Validators.required],
      geoName: null
    });
    this.sponsorCallNumberForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      callNumber: [null, Validators.required],
      callNumberTypeId: [null, Validators.required]
    });
    this.sponsorSocialNetworkForm = this.formBuilder.group({
      id: null,
      userIdentifier: null,
      networkId: [null, Validators.required],
      socialNetworkTypeId: [null, Validators.required]
    });
    this.referralsForm = this.formBuilder.group({
      userIdentifier: null,
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: null,
      jobId: null,
      userPrefixId: null,
      ancestry: null,
      gender: null,
      maritalStatus: null,
      birthDate: new Date()
    });
    this.sponsorDisabledTabs = [SponsorTabs.CONTACT_INFO]; // SponsorTabs.REFERRALS
    this.sponsorInfoCreated = { id: 0, code: null };
    this.isDuplicatePhone = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    forkJoin({
      userPrefix: this.userPrefixes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.userPrefixes).pipe(map((data) => this.userPrefixes = data)),
      callNumberType: this.callNumberTypes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.callNumberTypes),
      socialNetworkType: this.socialNetworkTypes$ = this.filterSettings<PublicModel[]>(SETTING_IDS.socialNetworkTypes),
      // job: this.getJobs(),
      geoName: this.getGeoName()
    }).pipe(map(() => {
      if (this.sponsorSelected && this.sponsorSelected.id) {
        this.openModal(this.sponsor);
        this.sponsorDisabledTabs = [];
        this.sponsorInfoCreated = { id: this.sponsorSelected.id, code: this.sponsorSelected.sponsorCode };
        this.accountService.userGetByIdentifier<User>({}, this.sponsorSelected.userIdentifier).pipe(map((res) => {
          this.sponsorForm.patchValue({
            ...res.data,
            userIdentifier: res.data.identifier,
            ancestry: res.data.ancestry || null,
            gender: res.data.gender || null,
            birthDate: this.datePipe.transform(res.data.birthDate, 'YYYY-MM-dd')
          });
        }), concatMap(() => this.getJobs()), shareReplay()).subscribe();
        this.getAddress();
        this.getCallNumbers();
        this.getSocialNetworks();
        if (this.sponsorSelected.referers && this.sponsorSelected.referers.length) {
          let refererIdentifiers = [];
          this.sponsorSelected.referers.map((item) => refererIdentifiers.push(item['userIdentifier']));
          this.accountService.userGetByIdentifiers<any[]>({ identifiers: refererIdentifiers }).pipe(map((res) => {
            res.data.map((item) => {
              item.userIdentifier = item.identifier;
              item.fullName = (item?.userPrefix || '') + ' ' + this.fullNamePipe.transform(item);
            });
            this.referralsData = res.data;
          }), shareReplay()).subscribe();
        }
      }
      else {
        this.getJobs();
        this.openModal(this.sponsor);
      }
    })).subscribe();
  }

  openModal(content: any, size = 'lg', name?: string): void {
    this.modalRef = this.modal.open(content, { size: size, centered: true });
    this.modalRef.result.then(() => this.resetAll(name), () => this.resetAll(name));
  }

  resetAll(name?: string): void {
    if (name !== 'referrals') {
      this.dismissed.emit(this.sponsorInfoCreated.code);
      this.modal.dismissAll();
    }
    else {
      Utils.resetForm(this.referralsForm);
      this.isDuplicatePhone = false;
    }
  }

  onCallNumberActionClick(event: any): void {
    switch (event.action) {
      case 'edit': this.sponsorCallNumberForm.patchValue(event.row); break;
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            this.accountService.userCallNumberDelete({ id: event.row.id }).pipe(map(() => this.getCallNumbers())).subscribe();
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onSocialNetworkActionClick(event: any): void {
    switch (event.action) {
      case 'edit': this.sponsorSocialNetworkForm.patchValue(event.row); break;
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            this.accountService.userSocialNetworkDelete({ id: event.row.id }).pipe(map(() => this.getSocialNetworks())).subscribe();
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onReferralActionClick(event: any): void {
    switch (event.action) {
      case 'delete':
        this.modal.open(this.deleteModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
          if (res === 'confirm') {
            return this.deleteReferral(event.row.userIdentifier).subscribe(() => {
              const data = [...this.referralsData];
              data.splice(event.rowIndex, 1);
              this.referralsData = data;
            });
          }
          else {
            return of([]);
          }
        })).subscribe();
        break;
      default: break;
    }
  }

  onLinkButton(name: string): void {
    switch (name) {
      case 'userPrefixes': window.open('/settings/dynamic/' + SETTING_IDS.userPrefixes + '/grid', '_blank'); break;
      case 'callNumberTypes': window.open('/settings/dynamic/' + SETTING_IDS.callNumberTypes + '/grid', '_blank'); break;
      case 'socialNetworkTypes': window.open('/settings/dynamic/' + SETTING_IDS.socialNetworkTypes + '/grid', '_blank'); break;
      case 'jobs': window.open('/settings/dynamic/' + SETTING_IDS.jobs + '/tree', '_blank'); break;
      case 'geoName': window.open('/settings/family-geoname', '_blank'); break;
      default: break;
    }
  }

  filterSettings<T>(identifier?: string) {
    return this.api.filterSettings<T>({ identifier }).pipe(map((res) => res.data), shareReplay(), catchError((err) => of(err)));
  }

  getJobs() {
    return this.jobs$ = this.filterSettings<PublicModel[]>(SETTING_IDS.jobsTree).pipe(map((data) => {
      this.jobs = data;
      data = Utils.convertAccountToTree(data, null, 'ParentId', 'Id');
      data = data.filter((item: any) => item.children.length);
      return data;
    }), shareReplay());
  }

  getAddress(): void {
    this.accountService.userGetAddresses<AddressModel[]>({ userIdentifier: this.sponsorSelected.userIdentifier }).pipe(map((res) => {
      if (res.data.length) {
        this.sponsorAddressForm.patchValue(res.data.filter((item) => item.addressType === 0)[0]);
        this.sponsorWorkAddressForm.patchValue(res.data.filter((item) => item.addressType === 1)[0]);
      }
    }), shareReplay()).subscribe();
  }

  getGeoName(name?: string) {
    const param = name && name.length ? { name } : {};
    return this.geoname$ = this.familyService.geoNameFilter<GeonameModel[]>(param).pipe(map((res) => res.data), shareReplay());
  }

  getCallNumbers() {
    this.accountService.userCallNumberGetByUserIdentifier<UserCallNumberModel[]>({}, this.sponsorSelected.userIdentifier).pipe(map((res) => {
      this.callNumbersData = res.data;
      return res.data;
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  getSocialNetworks() {
    this.accountService.userSocialNetworkGetByUserIdentifier<UserSocialNetworkModel[]>({}, this.sponsorSelected.userIdentifier).pipe(map((res) => {
      this.socialNetworksData = res.data;
      return res.data;
    }), shareReplay(), catchError(() => of([]))).subscribe();
  }

  sponsorInfoSubmit() {
    if (this.sponsorForm.invalid) {
      return false;
    }
    if (!this.sponsorInfoCreated.code) {
      const userExistsParam = { phone: this.sponsorForm.value.phone, firstName: this.sponsorForm.value.firstName, lastName: this.sponsorForm.value.lastName, role: 9 };
      this.accountService.userUserExists(userExistsParam).pipe(map((resUserExists) => resUserExists), concatMap((resUserExists) => {
        if (!resUserExists.data['identifier']) {
          return this.accountService.accountApiCreateSponsorship(this.sponsorForm.value).pipe(map((resCreateSponsor) => {
            this.sponsorForm.controls['userIdentifier'].setValue(resCreateSponsor.data['userIdentifier']);
          }), concatMap(() => {
            return this.sponsorshipService.sponsorCreate(this.sponsorForm.value).pipe(map((resSponsorCreate) => this.sponsorInfoComplete(resSponsorCreate.data)));
          }));
        }
        else {
          this.sponsorForm.controls['userIdentifier'].setValue(resUserExists.data['identifier']);
          return this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((resConfirm) => resConfirm), concatMap((resConfirm) => {
            if (resConfirm === 'confirm') {
              return this.accountService.userUpdateProfile(this.sponsorForm.value).pipe(map(() => {}), concatMap(() => {
                if (!resUserExists.data['roleExists']) {
                  return this.accountService.userRoleCreate({ userIdentifier: resUserExists.data['identifier'], role: 9 }).pipe(map(() => {}), concatMap(() => {
                    return this.sponsorshipService.sponsorCreate(this.sponsorForm.value).pipe(map((resSponsorCreate) => this.sponsorInfoComplete(resSponsorCreate.data)));
                  }));
                }
                else {
                  return this.sponsorshipService.sponsorFilter({ userIdentifier: resUserExists.data['identifier'] }).pipe(map((resSponsorFilter) => {
                    this.sponsorInfoComplete(resSponsorFilter.data[0]);
                    this.getAddress();
                    if (this.sponsorSelected.referers && this.sponsorSelected.referers.length) {
                      let refererIdentifiers = [];
                      this.sponsorSelected.referers.map((item) => refererIdentifiers.push(item['userIdentifier']));
                      this.accountService.userGetByIdentifiers<any[]>({ identifiers: refererIdentifiers }).pipe(map((res) => {
                        res.data.map((item) => {
                          item.userIdentifier = item.identifier;
                          item.fullName = (item?.userPrefix || '') + ' ' + this.fullNamePipe.transform(item);
                        });
                        this.referralsData = res.data;
                      }), shareReplay()).subscribe();
                    }
                  }));
                }
              }));
            }
            else {
              this.sponsorForm.controls['phone'].setValue(null);
              return of([]);
            }
          }));
        }
      })).subscribe();
    }
    else {
      this.accountService.userUpdateProfile(this.sponsorForm.value).pipe(map(() => this.activeTab = SponsorTabs.CONTACT_INFO)).subscribe();
    }
  }

  sponsorInfoComplete(sponsor: SponsorFilterModel): void {
    this.activeTab = SponsorTabs.CONTACT_INFO;
    this.sponsorDisabledTabs = [];
    this.sponsorInfoCreated = { id: sponsor.id, code: sponsor.sponsorCode };
    this.sponsorSelected = sponsor;
  }

  sponsorAddressInfoSubmit(panelId: any) {
    this.sponsorAddressForm.controls['userIdentifier'].setValue(this.sponsorForm.value.userIdentifier);
    this.sponsorAddressForm.controls['addressType'].setValue(0);
    if (this.sponsorAddressForm.invalid) {
      return false;
    }
    if (!this.sponsorAddressForm.value.id) {
      return this.accountService.userAddAddress(this.sponsorAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-1');
      })).subscribe();
    }
    else {
      return this.accountService.userUpdateAddress(this.sponsorAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-1');
      })).subscribe();
    }
  }

  sponsorWorkAddressInfoSubmit(panelId: any) {
    this.sponsorWorkAddressForm.controls['userIdentifier'].setValue(this.sponsorForm.value.userIdentifier);
    this.sponsorWorkAddressForm.controls['addressType'].setValue(1);
    if (this.sponsorWorkAddressForm.invalid) {
      return false;
    }
    if (!this.sponsorWorkAddressForm.value.id) {
      return this.accountService.userAddAddress(this.sponsorWorkAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-2');
      })).subscribe();
    }
    else {
      return this.accountService.userUpdateAddress(this.sponsorWorkAddressForm.value).pipe(map(() => {
        this.getAddress();
        panelId.collapse('toggle-2');
      })).subscribe();
    }
  }

  sponsorCallNumberInfoSubmit(form: any) {
    this.sponsorCallNumberForm.controls['userIdentifier'].setValue(this.sponsorForm.value.userIdentifier);
    if (this.sponsorCallNumberForm.invalid) {
      return false;
    }
    if (!this.sponsorCallNumberForm.value.id) {
      return this.accountService.userCallNumberCreate(this.sponsorCallNumberForm.value).pipe(map(() => {
        this.getCallNumbers();
        form.resetForm();
      })).subscribe();
    }
    else {
      return this.accountService.userCallNumberUpdate(this.sponsorCallNumberForm.value).pipe(map(() => {
        this.getCallNumbers();
        form.resetForm();
      })).subscribe();
    }
  }

  sponsorSocialNetworkInfoSubmit(form: any) {
    this.sponsorSocialNetworkForm.controls['userIdentifier'].setValue(this.sponsorForm.value.userIdentifier);
    if (this.sponsorSocialNetworkForm.invalid) {
      return false;
    }
    if (!this.sponsorSocialNetworkForm.value.id) {
      return this.accountService.userSocialNetworkCreate(this.sponsorSocialNetworkForm.value).pipe(map(() => {
        this.getSocialNetworks();
        form.resetForm();
      })).subscribe();
    }
    else {
      return this.accountService.userSocialNetworkUpdate(this.sponsorSocialNetworkForm.value).pipe(map(() => {
        this.getSocialNetworks();
        form.resetForm();
      })).subscribe();
    }
  }

  referralInfoSubmit() {
    this.isDuplicatePhone = false;
    if (this.referralsData && this.referralsData.length && this.referralsForm.value.phone) {
      this.referralsData.find((item) => {
        if (!this.isDuplicatePhone) {
          this.isDuplicatePhone = item.phone === this.referralsForm.value.phone;
        }
      });
    }
    if (this.referralsForm.invalid || this.isDuplicatePhone) {
      return false;
    }
    const userExistsParam = { phone: this.referralsForm.value.phone, firstName: this.referralsForm.value.firstName, lastName: this.referralsForm.value.lastName, role: 6 };
    this.accountService.userUserExists(userExistsParam).pipe(map((resUserExists) => resUserExists), concatMap((resUserExists) => {
      if (!resUserExists.data['identifier']) {
        return this.accountService.accountApiCreateReffer(this.referralsForm.value).pipe(map((resCreateReffer) => {
          this.referralsForm.controls['userIdentifier'].setValue(resCreateReffer.data['userIdentifier']);
        }), concatMap(() => {
          const param = { donorId: this.sponsorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
          return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
        }));
      }
      else {
        this.referralsForm.controls['userIdentifier'].setValue(resUserExists.data['identifier']);
        return this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((resConfirm) => resConfirm), concatMap((resConfirm) => {
          if (resConfirm === 'confirm') {
            return this.accountService.userUpdateProfile(this.referralsForm.value).pipe(map(() => {}), concatMap(() => {
              if (!resUserExists.data['roleExists']) {
                return this.accountService.userRoleCreate({ userIdentifier: resUserExists.data['identifier'], role: 6 }).pipe(map(() => {}), concatMap(() => {
                  const param = { donorId: this.sponsorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
                  return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
                }));
              }
              else {
                const param = { donorId: this.sponsorInfoCreated.id, refererUserIdentifier: this.referralsForm.value.userIdentifier };
                return this.donationService.donorAddReferer(param).pipe(map(() => this.referralInfoComplete()));
              }
            }));
          }
          else {
            this.referralsForm.controls['phone'].setValue(null);
            return of([]);
          }
        }));
      }
    })).subscribe();
  }

  referralInfoComplete(): void {
    const userPrefix = this.userPrefixes.find((item) => item['Id'] === this.referralsForm.value.userPrefixId)?.['Title'];
    this.referralsData.push(this.referralsForm.value);
    this.referralsData[this.referralsData.length - 1].fullName = (userPrefix || '') + ' ' + this.fullNamePipe.transform(this.referralsForm.value);
    this.referralsData[this.referralsData.length - 1].job = this.jobs.find((item) => item.Id === this.referralsForm.value.jobId)?.Title;
    this.referralsData = this.referralsData.slice();
    Utils.resetForm(this.referralsForm);
    this.modalRef.dismiss();
  }

  deleteReferral(userIdentifier: string) {
    const param = { donorId: this.sponsorInfoCreated.id, refererUserIdentifier: userIdentifier };
    return this.donationService.donorDeleteReferer(param).pipe(map(() => {}));
  }

}
