<div class="row gy-2">
  <div *ngFor="let type of attachmentTypes" class="col-md-6 flex-column d-flex">
    <div class="form-label fw-medium"> {{ type.title }} </div>
    <div class="upload-container flex-fill">
      <div class="align-items-center gap-3 flex-wrap d-flex">
        <div *ngFor="let file of attachments[type.id]; let i = index" class="img-preview">
          <img [src]="env + '/blob/' + file.filePreviewUrl" width="50%" height="50%" alt="" [class.is-image]="file.isImage" />
          <div class="icon-overlay">
            <div *ngIf="file.isImage" (click)="fileSelected = file; openModal(modal)" class="view"> <i class="fa fa-eye"> </i> </div>
            <a *ngIf="!file.isImage" [href]="env + '/blob/' + file.fileUrl" target="_blank" class="view"> <i class="fa fa-download"> </i> </a>
            <div *ngIf="!disabled" (click)="onRemoveImage(file)" class="close"> <i class="bx bx-x"> </i> </div>
          </div>
          <p *ngIf="fileSize" class="text-file-size"> {{ (file.fileSize / 1000) || 0 }}Kb </p>
        </div>
        <app-loader *ngIf="loading && type.id === selectedType" class="img-container"> </app-loader>
        <div *ngIf="!disabled" (click)="profileImage.click()" class="img-container">
          <i class="bx bx-plus"> </i>
          <input #profileImage (change)="onSelectFile($event, type.id)" type="file" name="profileImage" accept="image/jpg, image/jpeg, image/png, image/jfif, image/gif" id="profileImage" class="d-none" />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <div class="modal-header">
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </div>
  <div class="modal-body text-center">
    <figure class="figure my-4">
      <img [src]="env + '/blob/' + fileSelected.fileUrl" width="400" height="400" alt="" class="img-fluid figure-img mb-0" />
    </figure>
  </div>
</ng-template>
<ng-template #confirmModal let-modal>
  <app-confirm-modal (confirm)="modal.dismiss('confirm')" [modalRef]="modal" color="danger" secondColor="c-3"> </app-confirm-modal>
</ng-template>
