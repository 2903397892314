import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PrintService {

  public page: string;
  public view: string;
  public subView: string;
  public mainTitle: string;
  public subTitle: string;
  public mainData: any;
  public headerData: Array<{ key: string; value: string | number, class?: string }>;
  public secondaryHeader: any;
  public columnDefs: any[];
  public subColumnDefs: any[];
  public tableData: any[];
  public description: string;
  public footerData: any;
  public footerCustomData: any[];
  public isFinger: boolean;
  public isSignature: boolean;
  public rowId: number;
  public listId: number;

  resetData(): void {
    this.page = 'default';
    this.view = '';
    this.subView = '';
    this.mainTitle = '';
    this.subTitle = '';
    this.mainData = {};
    this.headerData = [];
    this.secondaryHeader = {};
    this.columnDefs = [];
    this.subColumnDefs = [];
    this.tableData = [];
    this.description = '';
    this.footerData = null;
    this.footerCustomData = [];
    this.isFinger = false;
    this.isSignature = false;
    this.rowId = 0;
    this.listId = 0;
  }

}
