import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { of, forkJoin } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { AccountingService } from 'src/app/core/api/accounting.service';
import { HrService } from 'src/app/core/api/hr.service';
import { PrintService } from 'src/app/core/services/print.service';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { environment } from 'src/environments/environment';
import { FiscalYearModel } from 'src/app/core/models/public-model';
import { BranchModel } from 'src/app/core/models/donation-financial-model';
import { FilterSalaryListDetailModel } from 'src/app/core/models/hr/salary-list-model';

@Component({
  selector: 'hr-all-salary-print',
  templateUrl: 'all-salary-print.component.html',
  styleUrls: ['all-salary-print.component.scss'],
})

export class HrAllSalaryPrintComponent implements OnInit {

  env: string;
  header: any;
  salaryDetails: FilterSalaryListDetailModel[];
  loading: boolean;

  constructor(public datePipe: DatePipe, private accountingService: AccountingService, private hrService: HrService, private printService: PrintService, public translatePipe: TranslatePipe) {
    this.env = environment.api;
    this.header = this.printService.secondaryHeader;
    this.salaryDetails = [];
    this.loading = false;
  }

  ngOnInit(): void {
    this.filterSalaryListDetail();
  }

  filterSalaryListDetail(): void {
    let userIdentifiers = [];
    this.salaryDetails = [];
    this.hrService.salaryListFilterSalaryListDetail<FilterSalaryListDetailModel[]>({ salaryListId: this.printService.listId }).pipe(map((res) => {
      res.data.map((items) => {
        items.totalBenefits = items.totalReward || 0;
        items.salaryFactories.forEach((item) => (items.totalBenefits += item.amount));
        items.totalDeduction = (items.totalFines || 0) + (items.loanDebt?.totalAmount || 0) + (items.imprestDebt?.totalAmount || 0) + (items.employeeInsuranceShare || 0) + (items.taxAmount || 0);
      });
      if (this.printService.rowId) {
        this.salaryDetails.push(res.data.find((item) => item.id === this.printService.rowId));
      }
      else {
        this.salaryDetails = res.data;
      }
      this.salaryDetails.forEach((item) => userIdentifiers.push(item.employeeUserIdentifier));
    }), concatMap(() => {
      return forkJoin({
        branches: this.getBranches(),
        years: this.getFiscalYears()
      }).pipe(map((result) => {
        this.header.salaryPeriod = this.salaryDetails[0].salaryPeriod;
        const year = result.years.find((item) => item.id === this.salaryDetails[0].fiscalYearId);
        this.header.fiscalYear = year.title;
        const branch = result.branches.find((item) => item.id === this.salaryDetails[0].branchId);
        this.header.branch = branch.title;
        setTimeout(() => window.print(), 100);
      }));
    })).subscribe();
  }

  getBranches() {
    return this.accountingService.branchFindBranch<BranchModel[]>({}).pipe(map((res) => {
      return res.data;
    }), shareReplay(), catchError(() => of([])));
  }

  getFiscalYears() {
    return this.accountingService.fiscalYearFindFiscalYear<FiscalYearModel[]>({}).pipe(map((res) => {
      return res.data;
    }), shareReplay(), catchError(() => of([])));
  }

}
