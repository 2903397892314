import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { AccountTreeModel } from "../models/donation-request-model";
import { PublicModel } from "../models/public-model";

export class Utils {
  public static resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      const control = form.controls[key];
      control.setValue(typeof control.value === "boolean" ? false : null);
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  public static convertAccountToTree<T>(
    account: any,
    rootId: number | null = null,
    parent = "parentID",
    id = "id"
  ): T[] {
    let map = {};
    let node: AccountTreeModel;
    let roots = [];

    for (let i = 0; i < account.length; i++) {
      if (!account[i].id) {
        account[i].id = account[i][id];
      }

      map[account[i][id]] = i;
      if (account[i].children && account[i].children.length) {
        account[i].children = account[i].children;
      } else {
        account[i].children = [];
      }
    }

    for (let i = 0; i < account.length; i++) {
      node = account[i];
      if (node[parent] !== rootId && node[parent] && node[parent] !== -1) {
        account[map[node[parent]]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  public static enumToArray(e: object): PublicModel[] {
    let keys = Object.values(e);
    let temp: PublicModel[] = [];
    for (let index = 0; index < keys.length / 2; index++) {
      temp.push({ name: keys[index], id: +keys[keys.length / 2 + index] });
    }
    return temp;
  }

  public static disableForm(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      if (key === "firstName" || key === "lastName") {
        return;
      }
      form.controls[key].disable();
    });
  }

  public static resetAutoCompleteForm(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      if (key === "firstName" || key === "lastName") {
        return;
      }
      const control = form.get(key);
      control.reset();
      control.enable();
    });
  }

  public static getFamilyIds(
    model: Array<{ familyIdentifier?: string; familyMemberIdentifier?: string }>
  ) {
    let familyIdentifiers: string[] = [];
    let memberIdentifiers: string[] = [];
    model.map((m) => {
      if (
        m.familyIdentifier &&
        familyIdentifiers.indexOf(m.familyIdentifier) === -1
      ) {
        familyIdentifiers.push(m.familyIdentifier);
      }

      if (
        m.familyMemberIdentifier &&
        memberIdentifiers.indexOf(m.familyMemberIdentifier) === -1
      ) {
        memberIdentifiers.push(m.familyMemberIdentifier);
      }
    });
    return { familyIdentifiers, memberIdentifiers };
  }

  public static getArrayIds<T>(model: Array<T>, key: string) {
    let ids: string[] = [];
    model.forEach((m) => {
      if (m[key] && ids.indexOf(m[key]) === -1) {
        ids.push(m[key]);
      }
    });
    return ids;
  }

  public static convertAccountToTreeVahid(data: any[]): any[] {
    data.map((items) => {
      items.treeId = items.id * 5000;
      items.children.map((item: any) => {
        item.treeId = item.id;
        item.productGroupId = item.parentId;
        return item;
      });
    });
    return data;
  }
}

export const MockDropdownItems = [
  {
    name: "item1",
    id: 1,
  },
  {
    name: "item2",
    id: 2,
  },
  {
    name: "item3",
    id: 3,
  },
  {
    name: "item4",
    id: 4,
  },
];

export const MockTreeNodes = [
  {
    name: "root1",
    checked: false,
    id: 1,
    children: [
      { name: "child1", id: 10 },
      { name: "child2", id: 11 },
    ],
  },
  {
    name: "root2",
    checked: false,
    id: 2,
    children: [
      { name: "child2.1", id: 20, children: [] },
      {
        name: "child2.2",
        id: 21,
        children: [{ name: "grandchild2.2.1", id: 210 }],
      },
    ],
  },
  { name: "root3", checked: false, id: 3 },
  { name: "root4", checked: false, id: 4, children: [] },
  { name: "root5", checked: false, id: 5, children: null },
];

export function familyRentValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const familyRent = form.get("familyRentAmount").value;

    const houseRent = form.get("houseRentAmount").value;

    if (familyRent && houseRent) {
      const isRangeValid = +familyRent <= +houseRent;
      isRangeValid
        ? form.controls.familyRentAmount.setErrors(null)
        : form.controls.familyRentAmount.setErrors({
            custom: "INVALID_FAMILY_RENT_AMOUNT",
          });
    } else {
      form.controls.familyRentAmount.setErrors(null);
    }

    return null;
  };
}

export function compareValidator(data: { type: string, field_1: string, field_2: string, error?: string, extra?: string }): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    data.error = data.error || 'INVALID_VALUE';
    data.extra = data.extra || 'canEqual';
    const field_1_Value = form.get(data.field_1).value;
    const field_2_Value = form.get(data.field_2).value;
    if (field_1_Value && field_2_Value) {
      let isValid = null;
      switch (data.type) {
        case 'number': isValid = data.extra === 'canNotEqual' ? +field_1_Value < +field_2_Value : +field_1_Value <= +field_2_Value; break;
        case 'date': isValid = data.extra === 'canNotEqual' ? new Date(field_1_Value) < new Date(field_2_Value) : new Date(field_1_Value) <= new Date(field_2_Value); break;
        default: break;
      }
      isValid ? form.controls[data.field_1].setErrors(null) : form.controls[data.field_1].setErrors({ custom: data.error });
    }
    else {
      form.controls[data.field_1].setErrors(null);
    }
    return null;
  }
}

export function minDateValidator(min: string, controlName: string, errorMassage: string): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const minDate = new Date(min).setHours(0, 0, 0, 0);
    const date = new Date(form.get(controlName).value).setHours(0, 0, 0, 0);
    form.controls[controlName].setErrors(date < minDate ? { custom: errorMassage } : null);
    return null;
  }
}

export function groupDateValidator(from: string, to: string, errorMessage: string): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    if (!form?.value?.[to])
      return null;
    if (new Date(form.value[to]) <= new Date(form.value[from]))
      form.controls[to].setErrors({ custom: errorMessage });
    return null;
  }
}

export function rangeValidator(min: number, max: number, errorMassage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control?.value) {
      return;
    }
    const value = +control.value;
    if (value < min || max < value) {
      return { custom: errorMassage };
    }
    return null;
  }
}

export function rangeDateValidator(min: string, max: string, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control?.value) {
      return;
    }
    const minDate = new Date(min);
    const date = new Date(control.value);
    const maxDate = new Date(max);
    if (date < minDate || maxDate < date) {
      return { custom: errorMessage };
    }
    return null;
  }
}
