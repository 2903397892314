import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { TreeModule } from "@circlon/angular-tree-component";
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTimepickerModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { ArchwizardModule } from "angular-archwizard";
import { NgxMaskModule } from "ngx-mask";
// import { CellRendererPipe } from 'src/app/core/pipes/cell-renderer.pipe';
import { FullNamePipe } from "src/app/core/pipes/full-name.pipe";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { PrettyJsonPipe } from "src/app/core/pipes/pretty-json.pipe";
import { PricePipe } from "src/app/core/pipes/price.pipe";
import { StatusPipe } from "src/app/core/pipes/status.pipe";
import { SumPipe } from "src/app/core/pipes/sum.pipe";
import { TranslatePipe } from "src/app/core/pipes/translate.pipe";
import { AdvancedTableComponent } from "./advanced-table/advanced-table.component";
import { GroupFieldComponent } from "./group-field/group-field.component";
import { BtnCustomComponent } from "./btn-custom/btn-custom.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { DividerComponent } from "./divider/divider.component";
import { DonorModalComponent } from "./donor-modal/donor-modal.component";
import { DragScrollComponent } from "./drag-scroll/drag-scroll.component";
import { DropdownTreeComponent } from "./dropdown-tree/dropdown-tree.component";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { FileBoxComponent } from "./file-box/file-box.component";
import { FilterCardComponent } from "./filter-card/filter-card.component";
import { GridTableComponent } from "./grid-table/grid-table.component";
import { ButtonRenderer } from "./grid-table/renderer/button-renderer";
import { CheckboxRenderer } from "./grid-table/renderer/checkbox-renderer";
import { CustomHeader } from "./grid-table/renderer/custom-header.component";
import { DateRenderer } from "./grid-table/renderer/date-renderer";
import { DropdownButtonRenderer } from "./grid-table/renderer/dropdown-button-renderer";
import { DropdownRenderer } from "./grid-table/renderer/dropdown-renderer";
import { DropdownTreeRenderer } from "./grid-table/renderer/dropdown-tree-renderer";
import { LinkRenderer } from "./grid-table/renderer/link-renderer";
import { ProgressRenderer } from "./grid-table/renderer/progress-renderer";
import { StatusRenderer } from "./grid-table/renderer/status-renderer";
import { TotalTimeRenderer } from "./grid-table/renderer/total-time-renderer";
import { TotalValueRenderer } from "./grid-table/renderer/total-value-renderer";
import { TreeRenderer } from "./grid-table/renderer/tree-renderer";
import { GroupAttachComponent } from "./group-attach/group-attach.component";
import { LoaderModule } from "./loader/loader.module";
import { MapComponent } from "./map/map.component";
import { PageTitleComponent } from "./page-title/page-title.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { PriceInputComponent } from "./price-input/price-input.component";
import { ProfileImageComponent } from "./profile-image/profile-image.component";
import { PublicCardComponent } from "./public-card/public-card.component";
import { QuantitySearchComponent } from "./quantity-search/quantity-search.component";
import { RangeDatepickerComponent } from "./range-datepicker/range-datepicker.component";
import { SearchFieldComponent } from "./search-field/search-field.component";
import { SelectFileComponent } from "./select-file/select-file.component";
import { SettingsModalComponent } from "./settings-modal/settings-modal.component";
import { SimpleInputComponent } from "./simple-input/simple-input.component";
import { SortBoxComponent } from "./sort-items/sort-box.component";
import { StepperComponent } from "./stepper/stepper.component";
import { TabBarComponent } from "./tab-bar/tab-bar.component";
import { TimepickerComponent } from "./timepicker/timepicker.component";
import { UserModalComponent } from "./user-modal/user-modal.component";
import { SponsorModalComponent } from "./sponsor-modal/sponsor-modal.component";

@NgModule({
  declarations: [
    BtnCustomComponent,
    GridTableComponent,
    ButtonRenderer,
    CheckboxRenderer,
    DateRenderer,
    LinkRenderer,
    DropdownButtonRenderer,
    StatusRenderer,
    TotalValueRenderer,
    PageTitleComponent,
    RangeDatepickerComponent,
    SearchFieldComponent,
    SimpleInputComponent,
    DatePickerComponent,
    DragScrollComponent,
    SortBoxComponent,
    DropdownComponent,
    PriceInputComponent,
    FilterCardComponent,
    MapComponent,
    PricePipe,
    SumPipe,
    // CellRendererPipe,
    ConfirmModalComponent,
    DropdownTreeComponent,
    PublicCardComponent,
    QuantitySearchComponent,
    DropdownRenderer,
    DropdownTreeRenderer,
    SelectFileComponent,
    TranslatePipe,
    CustomHeader,
    AdvancedTableComponent,
    FullNamePipe,
    PaymentStatusComponent,
    DividerComponent,
    TabBarComponent,
    ProfileImageComponent,
    GroupAttachComponent,
    GroupFieldComponent,
    FileBoxComponent,
    TreeRenderer,
    CheckboxComponent,
    DonorModalComponent,
    UserModalComponent,
    SettingsModalComponent,
    StepperComponent,
    PaginationComponent,
    StatusPipe,
    PrettyJsonPipe,
    TimepickerComponent,
    ProgressRenderer,
    TotalTimeRenderer,
    SponsorModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbAccordionModule,
    NgSelectModule,
    ArchwizardModule,
    NgxMaskModule.forRoot(),
    LeafletModule,
    TreeModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbTypeaheadModule,
    PipesModule,
    AgGridModule.withComponents([
      ButtonRenderer,
      CheckboxRenderer,
      DateRenderer,
      LinkRenderer,
      DropdownButtonRenderer,
      TotalValueRenderer,
      StatusRenderer,
      DropdownRenderer,
      DropdownTreeRenderer,
      CustomHeader,
      TreeRenderer,
    ]),
    LoaderModule,
  ],
  exports: [
    CommonModule,
    BtnCustomComponent,
    GridTableComponent,
    ReactiveFormsModule,
    PageTitleComponent,
    RangeDatepickerComponent,
    SearchFieldComponent,
    SimpleInputComponent,
    DatePickerComponent,
    DragScrollComponent,
    SortBoxComponent,
    DropdownComponent,
    PriceInputComponent,
    FilterCardComponent,
    MapComponent,
    PricePipe,
    SumPipe,
    // CellRendererPipe,
    ConfirmModalComponent,
    DropdownTreeComponent,
    PublicCardComponent,
    QuantitySearchComponent,
    NgbAccordionModule,
    SelectFileComponent,
    TranslatePipe,
    NgbNavModule,
    LoaderModule,
    AdvancedTableComponent,
    FullNamePipe,
    PaymentStatusComponent,
    DividerComponent,
    TabBarComponent,
    ProfileImageComponent,
    GroupAttachComponent,
    GroupFieldComponent,
    FileBoxComponent,
    CheckboxComponent,
    DonorModalComponent,
    UserModalComponent,
    SettingsModalComponent,
    StepperComponent,
    PaginationComponent,
    PipesModule,
    StatusPipe,
    PrettyJsonPipe,
    TimepickerComponent,
    ProgressRenderer,
    TotalTimeRenderer,
    SponsorModalComponent
  ],
})
export class UIModule {}
