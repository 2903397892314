import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";

import { PagingModel } from "src/app/core/models/paging.model";
import { Table } from "src/app/pages/tables/advancedtable/advanced.model";
import { AdvancedService } from "src/app/pages/tables/advancedtable/advanced.service";

@Component({
  selector: "app-advanced-table",
  templateUrl: "./advanced-table.component.html",
  styleUrls: ["./advanced-table.component.scss"],
})
export class AdvancedTableComponent<T, D, SC, SD> implements OnInit, OnChanges {
  @Input() colDefs: T[];
  @Input() subTableColDefs: SC[];
  @Input() subTableData: SD[];
  @Input() subTableAllData: Array<SD[]>;
  @Input() tableData: D[];
  @Input() gridHeight: string;
  @Input() paging: PagingModel;
  @Input() loading: boolean;
  @Input() loadingSubTableData: boolean;
  @Output() actionClick = new EventEmitter();
  @Output() childActionClick = new EventEmitter();
  @Output() pagingChange = new EventEmitter<PagingModel>();
  @Output() subTableEdit = new EventEmitter();
  @Output() tableEdit = new EventEmitter();
  tables$: Observable<Table[]>;
  total$: Observable<number>;
  subTables: Array<SD[]> = [];
  hideme: boolean[] = [];
  editableTable: any;
  hasRowNumber = false;
  selectedRowIndex: number;

  constructor(public service: AdvancedService) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData) {
      this.collapseTable();
    }
    if (changes.subTableData && this.subTableData?.length >= 0) {
      this.subTables[this.selectedRowIndex] = this.subTableData;
    }
    if (changes.subTableAllData&&this.subTableAllData?.length) {
      this.subTables = this.subTableAllData;
    }
  }

  ngOnInit(): void {
  }

  collapseTable() {
    if (this.tableData) {
      this.hideme = [];
      for (let i = 0; i <= this.tableData.length; i++) {
        this.hideme.push(true);
      }
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  onActionClick(action: string, row, index, table = "parent", parentData?) {
    action = action.toLowerCase();
    if (action === "view") {
      this.changeValue(index);
    }
    this.selectedRowIndex = index;
    if (table === "parent") {
      this.actionClick.emit({ action, row, toggleStatus: this.hideme[index] });
    } else {
      this.childActionClick.emit({ action, row, parentData });
    }
  }

  onPageChange(event) {
    this.paging.skip = (event - 1) * this.paging.take;
    this.pagingChange.emit(this.paging);
  }

  onSort(col) {
    if (!col.sortable) {
      return;
    }
    this.paging.take = 10;
    this.paging.skip = 0;

    if (!col["sort"] || col["sort"] === "desc") {
      col["sort"] = "asc";
      this.paging.sortProperties = { [col.field]: 0 };
    } else {
      col["sort"] = "desc";
      this.paging.sortProperties = { [col.field]: 1 };
    }

    this.pagingChange.emit(this.paging);
    this.hideme = [];
    this.collapseTable();
    this.colDefs.map((c) => {
      if (c["field"] === col["field"]) {
        return;
      } else {
        c["sort"] = null;
      }
    });
  }

  onSubtableEdit(obj) {
    this.subTableEdit.emit(obj);
  }

  onTableEdit(obj) {
    this.tableEdit.emit(obj);
  }

  onTreeInTableEdit(obj: any, event: any): void {
    obj.row[obj.colDef.field + 'Selected'] = event || null;
    this.tableEdit.emit(obj);
  }

  onTreeInSubTableEdit(obj: any, event: any): void {
    obj.row[obj.colDef.field + 'Selected'] = event || null;
    this.subTableEdit.emit(obj);
  }
}
