import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { PermissionModel } from 'src/app/core/models/auth.models';
import { UserModel } from 'src/app/core/models/public-model';
import { BranchModel } from 'src/app/core/models/donation-financial-model';
import { LastStatus } from 'src/app/core/models/donation-service-model';

@Injectable({
  providedIn: 'root'
})

export class ToolsService {

  private user: UserModel;
  toastOptions = { timeOut: 3000, positionClass: 'toast-bottom-center' };
  decodedToken = {};
  branch$ = new BehaviorSubject<number>(null);
  branchId$ = this.branch$.asObservable();
  branches: BranchModel[] = [];
  userBranch: BranchModel;
  selectedBranch: BranchModel;
  permissions: PermissionModel[] = [];
  gridParams: { gridApi?: null, gridColumnApi?: null, gridFit?: null };
  isLoadingApi: boolean;

  constructor(private location: Location, private router: Router, private toastr: ToastrService, private translate: TranslatePipe) {
    if (this.getToken()) {
      this.decodeToken();
    }
  }

  toast(type: string, message: string, title = null, options = this.toastOptions): void {
    switch (type) {
      case 'success':
        this.toastr.success(this.translate.transform(message), this.translate.transform(title), options);
        break;
      case 'error':
        this.toastr.error(this.translate.transform(message), this.translate.transform(title), options);
        break;
    }
  }

  toastError(message: string, postfix: any = ''): void {
    this.toastr.error(this.translate.transform(message) + postfix, null, this.toastOptions);
  }

  toastSuccess(message: string): void {
    this.toastr.success(this.translate.transform(message), null, this.toastOptions);
  }

  setToken(token: any): void {
    localStorage.setItem('token', token);
    this.decodeToken();
  }

  decodeToken(): void {
    const base64Url = this.getToken().split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window.atob(base64).split('').map((item) => '%' + ('00' + item.charCodeAt(0).toString(16)).slice(-2)).join('')
    );
    this.decodedToken = JSON.parse(jsonPayload);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setUser(user: UserModel): void {
    this.user = { ...user, fullName: `${ user.firstName } ${ user.lastName }` };
  }

  getUser(): UserModel {
    return this.user;
  }

  getUserID() {
    if (!this.getToken()) {
      return null;
    }
    return this.decodedToken['sub'];
  }

  getUserRoles() {
    if (!this.getToken()) {
      return [];
    }
    return this.decodedToken['roles'];
  }

  logout(): void {
    this.deleteStorageData();
    this.router.navigate(['/account/login']).then(() => {});
  }

  setBranches(branches: BranchModel[]): void {
    this.branches = branches;
  }

  getBranches() {
    return this.branches;
  }

  setSelectedBranch(branch: BranchModel, type?: string): void {
    this.selectedBranch = branch;
    type === 'user' ? this.userBranch = branch : null;
    localStorage.setItem('branchId', branch.id.toString());
    this.branch$.next(branch.id);
  }

  getSelectedBranch() {
    return { ...this.selectedBranch, id: this.selectedBranch?.id || null };
  }

  branchIsDisabled(): boolean {
    return this.getUserRoles().indexOf('Admin') === -1; // || !this.userBranch?.isCentral
  }

  setPermissions(permissions: PermissionModel[]): void {
    const urls = [];
    permissions.map((item) => urls.push(item.url.toLowerCase()));
    localStorage.setItem('permissions', urls.join(', '));
  }

  getPermissions(): string {
    return localStorage.getItem('permissions');
  }

  deleteStorageData(): void {
    localStorage.removeItem('langId');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
    localStorage.removeItem('branchId');
  }

  setColWidth(param?) {
    this.gridParams = param = param || this.gridParams;
    if (!param || !param.gridColumnApi) {
      return;
    }
    const allColumnIds: string[] = [];
    const cols = param.gridColumnApi?.getAllColumns();
    cols?.forEach((c) => {
      if (c.getColId().includes('description')) {
        return;
      }
      allColumnIds.push(c.getId());
    });
    setTimeout(() => param.gridColumnApi.autoSizeColumns(allColumnIds, false), 0);
    param.gridFit ? setTimeout(() => param.gridApi.sizeColumnsToFit(), 0) : null;
  }

  checkQueryParams(modalRef: any): void {
    setTimeout(() => {
      const modal = document.querySelector('.modal.d-block .modal-body').id;
      const key = this.router.url.indexOf('&') !== -1 ? '&modal=' : '?modal=';
      this.location.go(this.router.url + key + modal);
      const permissions = this.getPermissions().split(', ');
      if (permissions && permissions.length) {
        if (!permissions.find((item: string) => modal === item)) {
          this.toastError('NO_ACCESS_FOR_THIS_MODAL');
          modalRef.dismiss();
        }
      }
    }, 0);
    modalRef.dismissed.pipe(map(() => {
      const key = this.router.url.indexOf('&') !== -1 ? '&modal=' : '?modal=';
      const queryParams = window.location.search.split(key)[0];
      this.location.go(this.router.url.split('?')[0] + queryParams);
    })).subscribe();
  }

  getLastStatus(data: any): LastStatus {
    if (data && data.length) {
      return data[data.length - 1];
    }
    return null;
  }

  getLastStatusByStatus(data: any, status: number): LastStatus {
    if (data && data.logs && data.logs.length) {
      const temp = data.logs.filter((item: any) => item.status === status);
      return temp && temp.length ? temp[temp.length - 1] : null;
    }
    if (data && data.length) {
      const temp = data.filter((item: any) => item.status === status);
      return temp && temp.length ? temp[temp.length - 1] : null;
    }
    return null;
  }

  convertFaToEnDigits(value: string): string {
    const farsiNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    if (typeof value === 'string') {
      for (let i = 0; i <= 9; i++) {
        value = value.replaceAll(farsiNumbers[i], i.toString());
      }
    }
    return value;
  }

  checkNumberValidate(event: any): void {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  createGuid(): string {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3 | 0x8);
      return v.toString(16);
    });
  }

}
