export const environment = {
  production: false,
  isApp: false,
  api: 'https://alyatimapi.tasaheel.org',
  account: 'https://alyatimaccounts.tasaheel.org',
  accounting: 'https://accountingapi.tasaheel.org/acc/api',
  warehousing: 'https://accountingapi.tasaheel.org/acc/Warehousing/api',
  siteUrl: 'https://alyatimbackoffice.tasaheel.org', // isApp => ''
  accountingSiteUrl: 'https://alyatimacc.tasaheel.org',
  urlAuth: '',
  defaultAuth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
