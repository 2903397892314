import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  template: `
    <div style="width: 125px; height: 25px; background-color: #F9F9F9; border: 1px solid #AAA; border-radius: 0.25rem; position: relative">
      <div [ngStyle]="{ 'width': params.data[params.colDef.field] + '%', 'height': '100%', 'background-color': '#21D19F' }">
        <span style="height: 100%; font-weight: 500; align-items: center; position: absolute; left: 0; display: flex; margin: 0 0.25rem">
          {{ params.data[params.colDef.field] + '%' }}
        </span>
      </div>
    </div>
  `
})

export class ProgressRenderer implements ICellRendererAngularComp {

  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

}
